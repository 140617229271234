<template>
  <div identity="c-form-menu-options-wrap">
    <flex-align
      v-if="
        isGridBuilder &&
        selected.parent().name === 'GridItemWrapper' &&
        !hideFlexAlignment
      "
      :mode="mode"
      :disable-alignment="hideFlexAlignment"
      :disable-constrain="hideFlexAlignment"
    />

    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <cvt-collapse-item
        name="build"
        :title="$t(`element.property.form.manage_fields`)"
        :initial-active="true"
        :special-style="isSpecialStyle"
      >
        <form-editor v-if="!config.FORM_AUTO_SYNC" :mode="mode" />
        <div v-if="config.FORM_AUTO_SYNC" class="row no-gutters">
          <div class="col">
            <div class="card border-info mb-3" style="max-width: 18rem">
              <div class="card-body text-info">
                <h5 class="card-title" :class="textColorClass">
                  Form Auto Sync
                </h5>
                <p class="card-text" :class="textColorClass">
                  Your form fields are automatically synchronized.
                </p>
              </div>
            </div>

            <cvt-button
              shape="rounded"
              color="dark"
              :full-width="true"
              :mode="mode"
              @click="regenerateForms"
            >
              Manually Re-Sync Form
            </cvt-button>
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="canShowContactList || canAddCustomList"
        name="manage_list"
        :title="$t(`element.property.form.manage_list`)"
        :initial-active="false"
        :special-style="isSpecialStyle"
      >
        <div v-if="canShowContactList" class="row my-2">
          <div class="col">
            <remote-data-selector
              v-model="listId"
              :placeholder="$t('element.property.form.select.contact_list')"
              :endpoint="contactListEndpoint"
              :mode="mode"
              :last-updated="contactListLastUpdated"
            />
          </div>
        </div>

        <div v-if="canAddCustomList" class="row my-2">
          <div class="col">
            <add-custom-list @created="refreshContactLists" />
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="config.CONTACT_SOURCES_ENABLED"
        name="contactSources"
        :title="$t(`element.property.form.contact_sources`)"
        :special-style="isSpecialStyle"
      >
        <div class="row no-gutters">
          <div class="col-md-12 ml-auto">
            <remote-data-selector
              v-model="contactSource"
              :mode="mode"
              :endpoint="'/v1/crm/form/sources'"
              :placeholder="$t(`element.property.form.contact_sources`)"
              :mocking-enabled="contactSourcesMockingEnabled"
              :mocking-data="contactSourcesMockingData"
            />
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="config.LEAD_ROUTES_ENABLED"
        name="leadRoutes"
        :title="$t(`element.property.form.lead_routes`)"
        :special-style="isSpecialStyle"
      >
        <div class="row no-gutters">
          <div class="col-md-12 ml-auto">
            <remote-data-selector
              v-model="leadRoute"
              :mode="mode"
              :endpoint="'/v1/crm/form/lead-route'"
              :data-formatting-type="'liondesk-lead-routes'"
              :placeholder="$t(`element.property.form.lead_routes`)"
              :mocking-enabled="leadRoutesMockingEnabled"
              :mocking-data="leadRoutesMockingData"
            />
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="config.CRM_FORM_FIELD_TAGS_UI_ENABLED"
        title="Add Global Tags"
        name="tags"
        :special-style="isSpecialStyle"
      >
        <div class="row my-2">
          <div class="col">
            <p :class="textColorClass">
              These tags will apply to all visitors who complete this form
            </p>
            <crm-tags
              placeholder="Choose global tags"
              :tags="formTags"
              @update-tags="updateFormTags"
            />
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="config.SOCIAL_OPT_IN"
        :title="$t('element.property.form.option.social_opt_in')"
        name="socialOptIn"
        :special-style="isSpecialStyle"
      >
        <h6 :class="textColorClass">Facebook</h6>
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="facebook"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('facebook')"
        />
        <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
        <h6 :class="textColorClass">LinkedIn</h6>
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="linkedin"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('linkedin')"
        />
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="isRedirectURLEnabled"
        :title="$t('element.property.form.option.redirect')"
        name="redirectOptions"
        :special-style="isSpecialStyle"
      >
        <div class="row no-gutters">
          <div class="col-md-12 ml-auto">
            <cvt-select
              mode="dropdown"
              :value="redirectChoice"
              :label="$t(`element.property.cta.redirect.label`)"
              :background-mode="mode"
              @input="(value) => selectAction(value, 'redirectChoice')"
            >
              <option
                v-for="opt in redirectOptionsFilter"
                :key="opt.value"
                :value="opt.value"
                v-text="opt.label"
              />
            </cvt-select>
          </div>
        </div>

        <div
          v-if="pageIsActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />
        <div :class="{ 'subdiv-border': SubDivMargin }">
          <div :class="{ 'subdiv-spacing-left': SubDivMargin }">
            <div v-if="pageIsActive" class="row no-gutters">
              <div class="col-md-12 ml-auto">
                <page-list
                  :selected-page="selectedPage"
                  :mode="mode"
                  @change="updateLinkedPage"
                />
              </div>
            </div>

            <div
              v-if="linkIsActive"
              :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
            />

            <div v-if="linkIsActive" class="row no-gutters">
              <div class="col-md-12 d-flex align-items-center">
                <h6 :class="textColorClass">
                  {{ $t('element.property.form.option.external_url') }}
                </h6>
              </div>
              <div class="col-md-12 ml-auto">
                <url-input
                  v-model="redirectUrl"
                  type="text"
                  placeholder="https://external.site"
                />
                <el-alert title="" type="info" class="my-2">
                  <span class="help">{{
                    $t('element.property.cta.redirect.error')
                  }}</span>
                </el-alert>

                <!-- <input type="text" class="form-control" v-model="redirectUrl" > -->
              </div>
            </div>
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="config.SIB_DOUBLE_OPTIN_ENABLED"
        name="doubleOptInOptions"
        :title="$t('element.properties.doi.label')"
        :special-style="isSpecialStyle"
      >
        <div class="row no-gutters">
          <div class="col-md-12 d-flex align-items-center">
            <h6 :class="textColorClass">
              {{ $t('element.properties.doi.optinmethod.label') }}
            </h6>
          </div>
          <div class="col-md-12 ml-auto">
            <cvt-select
              mode="dropdown"
              :label="
                $t('element.property.form.option.choose_your_opt_in_method')
              "
              :value="optInMethod"
              :background-mode="mode"
              @input="(value) => selectAction(value, 'optInMethod')"
            >
              <option
                value="default"
                v-text="$t('element.properties.doi.optindefault.label')"
              />
              <option
                value="doubleOptIn"
                v-text="$t('element.properties.doi.optindoi.label')"
              />
            </cvt-select>
          </div>
        </div>

        <div
          v-if="doubleOptInActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div v-if="doubleOptInActive" class="row no-gutters">
          <div class="col-md-12 d-flex align-items-center">
            <h6 :class="textColorClass">
              {{ $t('element.properties.doi.optintemplate.label') }}
            </h6>
          </div>
          <div class="col-md-12 ml-auto">
            <remote-data-selector
              v-model="doubleOptInEmailTemplateID"
              endpoint="/v1/crm/email/templates"
              :placeholder="$t('element.properties.doi.optinselect.label')"
              :mode="mode"
            />
          </div>
        </div>

        <div
          v-if="doubleOptInActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div v-if="doubleOptInActive" class="row no-gutters">
          <div class="col-md-12 ml-auto">
            <cvt-select
              mode="dropdown"
              :value="doubleOptInRedirectChoice"
              :background-mode="mode"
              :label="$t(`element.property.cta.redirect.label`)"
              @input="
                (value) => selectAction(value, 'doubleOptInRedirectChoice')
              "
            >
              <option
                v-for="opt in redirectOptionsFilter"
                :key="opt.value"
                :value="opt.value"
                v-text="opt.label"
              />
            </cvt-select>
          </div>
        </div>

        <div
          v-if="doubleOptInPageIsActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div v-if="doubleOptInPageIsActive" class="row no-gutters">
          <div class="col-md-12 ml-auto">
            <page-list
              :selected-page="doubleOptInSelectedPage"
              :mode="mode"
              @change="updateDoubleOptInLinkedPage"
            />
          </div>
        </div>

        <div
          v-if="doubleOptInLinkIsActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div v-if="doubleOptInLinkIsActive" class="row no-gutters">
          <div class="col-md-12 d-flex align-items-center">
            <h6 :class="textColorClass">
              {{ $t('element.property.form.option.external_url') }}
            </h6>
          </div>
          <div class="col-md-12 ml-auto">
            <url-input
              v-model="doubleOptInRedirectUrl"
              type="text"
              placeholder="https://external.site"
            />
            <el-alert title="" type="info" class="my-2">
              <span class="help">{{
                $t('element.property.cta.redirect.error')
              }}</span>
            </el-alert>
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        name="design"
        :title="$t('element.property.form.design.label')"
        :special-style="isSpecialStyle"
      >
        <cvt-select
          mode="dropdown"
          :label="$t('element.property.form.option.select_form_a_design')"
          :value="design"
          :background-mode="mode"
          @input="(value) => selectAction(value, 'design')"
        >
          <option
            v-for="designOption in designs"
            :key="designOption.value"
            :value="designOption.value"
            v-text="designOption.label"
          />
        </cvt-select>

        <div
          class="mt-3"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div v-if="ShowFormHorizontal">
          <h6 :class="textColorClass">
            {{ $t('element.property.form.option.make_this_form_horizontal') }}
          </h6>
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="inline"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('inline')"
          />
        </div>

        <!-- <h6 :class="textColorClass">Full Width Button</h6>
        <el-switch v-model="btnFullWidth"></el-switch> -->
      </cvt-collapse-item>

      <cvt-collapse-item
        name="spacing"
        :title="$t('elements.properties.spacing.label')"
        :special-style="isSpecialStyle"
      >
        <spacing-ctrl />
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="formSubmissionControls.enabled"
        name="form_submission_controls"
        :title="$t('element.property.form.submission.control.label')"
        :special-style="isSpecialStyle"
      >
        <h6
          v-if="formSubmissionControls.showContactUpdateToggle"
          :class="textColorClass"
        >
          {{ $t('element.property.form.update.contacts.label') }}
        </h6>
        <cvt-switch
          v-if="formSubmissionControls.showContactUpdateToggle"
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="updateContact"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('updateContact')"
        />

        <div
          v-if="changeToggleToCheckBox"
          class="col-12 d-flex align-items-start"
        >
          <input
            id="recaptchaBox"
            v-model="recaptcha"
            type="checkbox"
            class="mt-1 mr-3"
          />
          <label for="recaptchaBox">{{
            $t('element.property.form.recaptcha.disable.label')
          }}</label>
        </div>
        <div v-else>
          <h6
            v-if="formSubmissionControls.showRecaptchaToggle"
            :class="textColorClass"
          >
            {{ $t('element.property.form.recaptcha.disable.label') }}
          </h6>
          <cvt-switch
            v-if="formSubmissionControls.showRecaptchaToggle"
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="recaptcha"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('recaptcha')"
          />
        </div>

        <div class="divider mt-2" />

        <div
          v-if="changeToggleToCheckBox"
          class="col-12 d-flex align-items-start"
        >
          <input
            id="disableUrlParams"
            v-model="disableUrlParams"
            type="checkbox"
            class="mt-1 mr-3"
          />
          <label for="disableUrlParams">{{
            $t('element.property.form.submission.disable_params.label')
          }}</label>
        </div>
        <div v-else>
          <h6 :class="textColorClass">
            {{ $t('element.property.form.submission.disable_params.label') }}
          </h6>
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="disableUrlParams"
            :mode="mode"
            @switchToggle="
              (state) => onSwitchToggleV2(state, 'disableUrlParams')
            "
          />
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        v-if="isGridBuilder"
        :title="$t('elements.properties.style.label')"
        name="style"
        :special-style="isSpecialStyle"
      >
        <div class="nestedInnerItem">
          <cvt-select
            mode="dropdown"
            :value="borderStyle"
            :background-mode="mode"
            :label="$t('elements.properties.border.style')"
            @input="(value) => updateBorderStyle(value)"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px" />
          <cvt-input
            v-model="borderColor"
            style="width: max-content"
            type="text"
            :clearable="false"
            :label="$t('elements.properties.border.color')"
          />
        </div>

        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.width') }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              class="slider"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              :min="1"
              :max="10"
              :value="borderWidth"
              :step="1"
              @change="updateBorderWidth"
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.radius') }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              class="slider"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              :min="0"
              :max="100"
              :value="borderRadius"
              :step="5"
              @change="updateBorderRadius"
            />
          </div>
        </div>
      </cvt-collapse-item>
    </cvt-collapse>
  </div>
</template>

<script>
import AddCustomList from '@/js/components/editor/forms/AddCustomList.vue'
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import PageList from '../../common/PageList.vue'
import CrmTags from '../forms/CRMTags.vue'
import FormEditor from '../forms/FormEditor.vue'
import RemoteDataSelector from '../forms/RemoteDataSelector.vue'
import FlexAlign from './FlexAlign.vue'
import SpacingCtrl from './SpacingCtrl.vue'

const contactListEndpoints = {
  standard: () => '/v1/crm/lists',
  custom: (folderId) => `/v1/crm/folders/${folderId}/lists`,
}

export default {
  components: {
    AddCustomList,
    PageList,
    SpacingCtrl,
    RemoteDataSelector,
    FormEditor,
    CrmTags,
    FlexAlign,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultTabs: ['build'],
      designs: [
        { label: this.$t('element.property.form.design.none'), value: 'none' },
        {
          label: this.$t('element.property.form.design.outlined'),
          value: 'form-minimalist',
        },
        {
          label: this.$t('element.property.form.design.curved_outline'),
          value: 'form-creative',
        },
        {
          label: this.$t('element.property.form.design.underline_bg'),
          value: 'form-simple',
        },
        {
          label: this.$t('element.property.form.design.underline_no_bg'),
          value: 'form-material',
        },
      ],
      redirectOptions: [
        { label: 'Default', value: 'default' },
        {
          label: this.$t('element.property.form.option.funnel.label'),
          value: 'page',
        },
        { label: 'Link to a url', value: 'url' },
      ],
      borderStyles: ['none', 'solid', 'dashed', 'dotted', 'double', 'groove'],
      borderWidth: 0,
      borderRadius: 0,
      contactListLastUpdated: 0,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      user: getters.AUTH_GET_USER,
      site: getters.SITE_GET_DATA,
      advancedMenuTabs: getters.ADVANCED_MENU_TABS,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
      isStandalonePopupEnabled: getters.STANDALONE_POPUP_ACTIVE,
    }),
    ...mapState('sitePreferences', {
      recaptchaDisabled: ({ preferences }) =>
        _.get(preferences, 'recaptchaDisabled', false),
    }),
    ...mapState('globalTheme', {
      SubDivMargin: ({ globalTheme }) =>
        globalTheme.SubDivMargin !== undefined
          ? globalTheme.SubDivMargin
          : false,
      ShowFormHorizontal: ({ globalTheme }) =>
        globalTheme.ShowFormHorizontal !== undefined
          ? globalTheme.ShowFormHorizontal
          : true,
      changeToggleToCheckBox: ({ globalTheme }) =>
        globalTheme.changeToggleToCheckBox !== undefined
          ? globalTheme.changeToggleToCheckBox
          : false,
      DividerWithBorder: ({ globalTheme }) =>
        globalTheme.DividerWithBorder !== undefined
          ? globalTheme.DividerWithBorder
          : true,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    formSubmissionControls() {
      return this.config.FORM_SUBMISSION_CONTROLS || {}
    },
    contactSourcesMockingEnabled() {
      return this.config.CONTACT_SOURCES_MOCKING_ENABLED || false
    },
    contactSourcesMockingData() {
      return this.config.CONTACT_SOURCES_MOCKING_DATA || []
    },
    leadRoutesMockingEnabled() {
      return this.config.LEAD_ROUTES_MOCKING_ENABLED || false
    },
    leadRoutesMockingData() {
      return this.config.LEAD_ROUTES_MOCKING_DATA || []
    },
    isRedirectURLEnabled() {
      if (this.isStandalonePopupEnabled) {
        if (this.config.POPUP_FORM_REDIRECT_URL_ENABLED !== undefined) {
          return this.config.POPUP_FORM_REDIRECT_URL_ENABLED
        }
        return false
      }
      if (this.config.FORM_REDIRECT_URL_ENABLED !== undefined) {
        return this.config.FORM_REDIRECT_URL_ENABLED
      }
      return true
    },
    activeTabs() {
      return _.merge(this.defaultTabs, this.advancedMenuTabs)
    },
    formTags() {
      return _.get(this, 'selected.tags', [])
    },
    folderId: {
      get() {
        return _.get(this, 'selected.folderId')
      },
      set(value) {
        this.updateProp({ key: 'folderId', value })
        this.updateProp({ key: 'listId', value: null })
      },
    },
    listId: {
      get() {
        return _.get(this, 'selected.listId')
      },
      set(value) {
        // @todo remove logic from here
        if (
          window.dopplerSessionState?.jwtToken &&
          window.dopplerSessionState?.dopplerAccountName
        ) {
          this.updateProp({
            key: 'extraMetaPayload',
            value: window.dopplerSessionState.dopplerAccountName,
          })
        }
        this.updateProp({ key: 'listId', value })
      },
    },
    canAddCustomList() {
      return this.config.CRM_FORM_CAN_ADD_CUSTOM_LIST
    },
    canShowContactList() {
      return this.config.CRM_FORM_CONTACT_LIST_ENABLED
    },
    contactListEndpoint() {
      return this.config.CRM_FORM_STANDARD_CONTACT_LIST
        ? contactListEndpoints.standard()
        : contactListEndpoints.custom(this.folderId)
    },
    optInMethod: {
      get() {
        return _.get(this, 'selected.optInMethod', 'default')
      },
      set(value) {
        return this.updateProp({ key: 'optInMethod', value })
      },
    },
    redirectOptionsFilter() {
      if (this.isStandalonePopupEnabled) {
        return this.redirectOptions.filter((x) => x.value !== 'page')
      }
      return this.redirectOptions.filter((x) => x.value !== 'default')
    },
    updateContact: {
      get() {
        return _.get(this, 'selected.updateContact')
      },
      set(value) {
        return this.updateProp({ key: 'updateContact', value })
      },
    },
    contactSource: {
      get() {
        return _.get(this, 'selected.contactSource', 0)
      },
      set(value) {
        return this.updateProp({ key: 'contactSource', value })
      },
    },
    leadRoute: {
      get() {
        return _.get(this, 'selected.leadRoute', 0)
      },
      set(value) {
        return this.updateProp({ key: 'leadRoute', value })
      },
    },
    doubleOptInEmailTemplateID: {
      get() {
        return _.get(this, 'selected.doubleOptInEmailTemplateID')
      },
      set(value) {
        this.updateProp({ key: 'doubleOptInEmailTemplateID', value })
      },
    },
    redirectChoice: {
      get() {
        return _.get(this, 'selected.redirectChoice')
      },
      set(value) {
        return this.updateProp({ key: 'redirectChoice', value })
      },
    },
    doubleOptInRedirectChoice: {
      get() {
        return _.get(this, 'selected.doubleOptInRedirectChoice')
      },
      set(value) {
        return this.updateProp({ key: 'doubleOptInRedirectChoice', value })
      },
    },
    facebook: {
      get() {
        return _.get(this, 'selected.facebook')
      },
      set(value) {
        return this.updateProp({ key: 'facebook', value })
      },
    },
    linkedin: {
      get() {
        return _.get(this, 'selected.linkedin')
      },
      set(value) {
        return this.updateProp({ key: 'linkedin', value })
      },
    },
    recaptcha: {
      get() {
        return this.recaptchaDisabled
      },
      set(value) {
        return this.updateSitePreferences({
          key: 'recaptchaDisabled',
          value,
        })
      },
    },
    disableUrlParams: {
      get() {
        return _.get(this, 'selected.disableUrlParams', false)
      },
      set(value) {
        return this.updateProp({
          key: 'disableUrlParams',
          value,
        })
      },
    },
    redirectUrl: {
      get() {
        return _.get(this, 'selected.redirectUrl')
      },
      set: _.debounce(function (value) {
        this.updateProp({ key: 'redirectUrl', value })
      }, 1000),
    },
    doubleOptInRedirectUrl: {
      get() {
        return _.get(this, 'selected.doubleOptInRedirectUrl')
      },
      set: _.debounce(function (value) {
        this.updateProp({ key: 'doubleOptInRedirectUrl', value })
      }, 1000),
    },
    design: {
      get() {
        return _.get(this, 'selected.design')
      },
      set(value) {
        return this.updateProp({ key: 'design', value })
      },
    },
    inline: {
      get() {
        return this.selected.inline
      },
      set(value) {
        this.btnFullWidth = !value
        return this.updateProp({ key: 'inline', value })
      },
    },
    btnFullWidth: {
      get() {
        return this.formButton().isBlock || false
      },
      set(value) {
        return this.formButton().updateProp('isBlock', value)
      },
    },
    selectedPage() {
      return _.get(this, 'selected.pageId')
    },
    doubleOptInSelectedPage() {
      return _.get(this, 'selected.doubleOptInPageId')
    },
    pageIsActive() {
      return this.redirectChoice === 'page'
    },
    doubleOptInActive() {
      return this.optInMethod === 'doubleOptIn'
    },
    doubleOptInPageIsActive() {
      return this.doubleOptInRedirectChoice === 'page' && this.doubleOptInActive
    },
    linkIsActive() {
      return this.redirectChoice === 'url'
    },
    doubleOptInLinkIsActive() {
      return this.doubleOptInRedirectChoice === 'url' && this.doubleOptInActive
    },
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    borderStyle: {
      get() {
        return this.selected.parent().name === 'GridItemWrapper'
          ? this.selected.parent().borderStyle || 'none'
          : 'none'
      },
      set: _.throttle(function (value) {
        return this.selected.parent().updateProp('borderStyle', value)
      }, 300),
    },
    borderColor: {
      get() {
        return this.selected.parent().name === 'GridItemWrapper'
          ? this.selected.parent().borderColor
          : null
      },
      set: _.throttle(function ({ target }) {
        if (this.selected.parent().name === 'GridItemWrapper') {
          return this.selected.parent().updateProp('borderColor', target.value)
        }

        return
      }, 300),
    },
    hideFlexAlignment() {
      return this.config.HIDE_FLEX_ALIGNMENT_OPTIONS?.form === true
    },
  },
  created() {
    if (this.selected.parent().name === 'GridItemWrapper') {
      this.borderWidth = this.selected.parent().borderWidth || 0
      this.borderRadius = this.selected.parent().borderRadius || 0
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
      regenerateSiteForms: actions.SITE_REGENERATE_FORMS,
    }),
    ...mapActions('sitePreferences', {
      updateSitePreferences: 'save',
    }),
    ...mapMutations({
      advancedMenuSetActiveTabs: mutations.ADVANCED_MENU_SET_ACTIVE_TABS,
    }),
    refreshContactLists(data) {
      this.contactListLastUpdated = Date.now()
      this.listId = data.listId || ''
    },
    regenerateForms() {
      return this.regenerateSiteForms(this.site)
    },
    updateFormTags(tags) {
      return this.updateProp({
        key: 'tags',
        value: tags,
      })
    },
    formButton() {
      return this.selected.findChild((c) => c.name === 'CButton')
    },
    updateLinkedPage(value) {
      this.updateProp({ key: 'pageId', value })
    },
    updateDoubleOptInLinkedPage(value) {
      this.updateProp({ key: 'doubleOptInPageId', value })
    },
    // DEPRECATED: this is silly use onSwitchToggleV2
    onSwitchToggle(property) {
      if (property === 'facebook') this.facebook = !this.facebook
      else if (property === 'linkedin') this.linkedin = !this.linkedin
      else if (property === 'inline') this.inline = !this.inline
      else if (property === 'updateContact')
        this.updateContact = !this.updateContact
      else if (property === 'recaptcha') this.recaptcha = !this.recaptcha
    },
    // intended to be used with cvt-switch component that emits its current state
    onSwitchToggleV2(state, property) {
      this[property] = state !== 'Inactive'
    },
    selectAction(value, computedProperty) {
      switch (computedProperty) {
        case 'redirectChoice':
          this.redirectChoice = value
          break
        case 'doubleOptInRedirectChoice':
          this.doubleOptInRedirectChoice = value
          break
        case 'optInMethod':
          this.optInMethod = value
          break
        case 'design':
          this.design = value
          break
      }
    },
    updateBorderStyle(value) {
      if (this.selected.parent().name === 'GridItemWrapper') {
        this.borderStyle = value
      }
    },
    updateBorderWidth: _.throttle(function (value) {
      if (this.selected.parent().name !== 'GridItemWrapper') return

      this.borderWidth = value
      return this.selected.parent().updateProp('borderWidth', value)
    }, 300),
    updateBorderRadius: _.throttle(function (value) {
      if (this.selected.parent().name !== 'GridItemWrapper') return

      this.borderRadius = value
      return this.selected.parent().updateProp('borderRadius', value)
    }, 300),
  },
}
</script>

<style lang="scss"></style>

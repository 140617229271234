<template>
  <div
    :id="vnodeId || formVNodeId"
    :data-vnode-id="vnodeId || formVNodeId"
    class="cvt-editor-button"
    :class="containerClasses"
    :draggable="!isGridBuilder ? 'true' : 'false'"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
    @dblclick.stop.prevent
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <template v-if="!form">
      <drag-and-drop-zone
        :z-index="dropzoneZIndex"
        @dropped="addElementToSide"
      />

      <a
        :href="btnURL"
        class="btn"
        :class="btnClasses"
        :target="gumroadEnabled ? '_blank' : anchorTarget"
        role="button"
        :data-popup="'grid-stack-' + popupId"
        :style="btnStyle"
      >
        <animated-placeholder :loading="isLoading">
          <span :style="innerBtnStyle">
            <span v-if="iconEnabled && iconPosition === 'left'"><icon-template :set="set" :selector="selector" /></span>
            <span>{{ cta }}</span>
            <span v-if="iconEnabled && iconPosition === 'right'"><icon-template :set="set" :selector="selector" /></span>
          </span>
          <span :style="subtextStyle">{{ subtext }}</span>
        </animated-placeholder>
      </a>
    </template>

    <template v-if="form">
      <button class="btn" :class="btnClasses">
        <span :style="innerBtnStyle">
          <span v-if="iconEnabled && iconPosition === 'left'"><icon-template :set="set" :selector="selector" /></span>
          <span>{{ cta || 'Call To Action' }}</span>
          <span v-if="iconEnabled && iconPosition === 'right'"><icon-template :set="set" :selector="selector" /></span>
        </span>
      </button>
    </template>

    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
    <span :style="subtextStyle">{{ helper_text }}</span>
  </div>
</template>

<script>
import color from 'color'
import * as url from 'url'
import FbVNode from '../../base/FbVNode.vue'
import IconTemplate from '../nodes/icon/IconTemplate.vue'
import AnimatedPlaceholder from '../../common/AnimatedPlaceholder.vue'

export default FbVNode.extend({
  components: {
    IconTemplate,
    AnimatedPlaceholder
  },
  props: {
    href: String,
    shape: String,
    size: String,
    shadow: String,
    isBlock: Boolean,
    form: Boolean,
    anchor: String,
    pageId: String,
    anchorTarget: {
      type: String,
      default: '_self',
    },
    popupId: {
      type: String,
      default: '',
    },
    popup: {
      type: Object,
      default() {
        return {}
      },
    },
    action: {
      type: String,
      default: 'link',
      validate(value) {
        return [
          'link',
          'anchor',
          'page',
          'checkout',
          'instamojo',
          'gumroad',
        ].includes(value)
      },
    },
    cta: {
      type: String,
      default: 'Call to Action',
    },
    fill: {
      type: String,
      default: 'btn-primary',
    },
    products: {
      type: Array,
      default: () => [],
    },
    instamojoPaymentLink: {
      type: String,
      default: 'default',
    },
    subtext: {
      type: String,
      default: '',
    },
    helper_text: {
      type: String,
      default: '',
    },
    iconEnabled: Boolean,
    iconPosition: {
      type: String,
      default: 'left',
    },
    selector: {
      type: String,
      default:'fa-arrow-left'
    },
    set: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ['font-awesome-icon', 'material-icon', 'themify-icon'].includes(
          value,
        )
      },
      default: 'font-awesome-icon'
    },
    dc: {
      type: Object,
      default: () => {},
    },
    isCardButton: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      name: 'CButton',
      formVNodeId: undefined,
    }
  },
  computed: {
    isBtnPrimary() {
      return this.fill === 'btn-primary'
    },
    isBtnOutline() {
      return this.fill === 'btn-outline-primary'
    },
    containerClasses() {
      return {
        ...this.classes,
        'w-100': this.isGridBuilder,
        'h-100': this.isGridBuilder,
        'd-flex': true,
        'flex-column': true,
        'align-items-center': true
      }
    },
    localClasses() {
      return {
        'd-flex': true,
        'btn-block': this.isBlock,
      }
    },
    gumroadEnabled() {
      return this.action === 'gumroad'
    },
    gumroadLink() {
      return url.format({
        protocol: 'https',
        slashed: true,
        hostname: 'gum.co',
        pathname: 'demo',
        query: {
          wanted: true,
        },
      })
    },
    btnClasses() {
      return {
        [this.shape]: true,
        [this.size]: true,
        [this.shadow]: true,
        [this.fill]: !this.themeColor,
        [this.stylesheet.classes.themeOutline]: this.isBtnOutline,
        [this.stylesheet.classes.theme]: this.isBtnPrimary,
        [this.stylesheet.classes.spacing]: true,
        [this.bootstrapAlignment]: true,
        'gumroad-button': this.gumroadEnabled,
        'btn-block': this.isBlock,
        'd-flex': this.isGridBuilder,
        'w-100': this.isGridBuilder,
        'h-100': this.isGridBuilder,
        'justify-content-center': this.isGridBuilder,
        'align-items-center': this.isGridBuilder,
        'mb-1': true
      }
    },
    anchoredElm() {
      return this.fmtAnchorId(this.anchors[this.anchor]) || this.anchor
    },
    btnURL() {
      switch (this.action) {
        case 'anchor':
          return `#${this.anchoredElm}`
        case 'link':
          return this.href || '#'
        case 'gumroad':
          return this.gumroadLink
        default:
          return '#'
      }
    },
    styles() {
      return {
        theme: {
          ...this.baseThemeStyles,
          '&:hover': this.hoverState,
          '&:active': this.activeState,
          '&:focus': this.focusState,
        },
        themeOutline: {
          ...this.baseOutlineThemeStyles,
          '&:hover': this.baseThemeStyles,
          '&:active': this.baseThemeStyles,
          '&:focus': this.baseOutlineThemeStyles,
        },
        spacing: {
          ...this.spacingStyle,
        },
      }
    },
    baseOutlineThemeStyles() {
      return {
        color: this.getButtonBackgroundColor,
        borderColor: this.getButtonBackgroundColor,
        backgroundColor: 'transparent',
        ...this.outlineStyles,
      }
    },
    baseThemeStyles() {
      return {
        color: this.getButtonTextColor,
        backgroundColor: this.getButtonBackgroundColor,
        borderColor: this.getButtonBorderColor,
        ...this.buttonFontStyles,
        ...this.outlineStyles,
      }
    },
    getButtonBackgroundColor() {
      if (this.themeColorHSL) {
        return this.themeColorHSL
      } else if (
        this.theme.buttonTypography &&
        this.theme.buttonTypography.button &&
        this.theme.buttonTypography.button.defaultBackgroundColor
      ) {
        return this.theme.buttonTypography.button.defaultBackgroundColor
      }

      return null
    },
    getButtonHoverBackgroundColor() {
      let buttonBackgroundColorDarkened = null

      if (this.getButtonBackgroundColor) {
        buttonBackgroundColorDarkened = color(this.getButtonBackgroundColor)
          .darken(0.1)
          .hex()
      }

      return this.themeColorHoverHSL
        ? this.themeColorHoverHSL
        : buttonBackgroundColorDarkened
    },
    getButtonTextColor() {
      if (this.fontColorHEX) {
        return this.fontColorHEX
      } else if (this.getButtonBackgroundColor) {
        let buttonBackgroundColor = color(this.getButtonBackgroundColor)

        if (buttonBackgroundColor.lightness() >= 60) {
          return color('black').hex()
        }

        if (buttonBackgroundColor.lightness() < 60) {
          return color('white').hex()
        }
      }

      return null
    },
    getButtonHoverTextColor() {
      let buttonHoverTextColorLightened = null

      if (this.getButtonTextColor) {
        buttonHoverTextColorLightened = color(this.getButtonTextColor)
          .lighten(0.1)
          .hex()
      }

      return this.fontColorHoverHEX
        ? this.fontColorHoverHEX
        : buttonHoverTextColorLightened
    },
    getButtonBorderColor() {
      // Border has same color as background-color so instead of repeating the logic, we referenced the same background property
      return this.getButtonBackgroundColor
    },
    getButtonHoverBorderColor() {
      // Border hover has same color as background-color so instead of repeating the logic, we referenced the same background property
      return this.getButtonBackgroundColor
    },
    focusState() {
      return {
        ...this.hoverState,
        boxShadow: `0 0 0 2px ${this.getButtonBackgroundColor}`,
      }
    },
    hoverState() {
      return {
        color: this.getButtonHoverTextColor,
        backgroundColor: this.getButtonHoverBackgroundColor,
        borderColor: this.getButtonHoverBorderColor,
        ...this.theme.buttonTypography.button.computedButtonFontStyle,
        ...this.outlineStyles,
      }
    },
    activeState() {
      return {
        color: `${this.getButtonHoverTextColor} !important`,
        backgroundColor: `${this.getButtonHoverBackgroundColor} !important`,
        borderColor: `${this.getButtonHoverBorderColor} !important`,
        ...this.outlineStyles,
      }
    },
    outlineStyles() {
      return {
        outlineWidth: `${this.borderWidth || 0}px`,
        outlineStyle: this.borderStyle,
        outlineColor: this.borderColor,
      }
    },
    buttonFontStyles() {
      if (
        this.theme.buttonTypography &&
        this.theme.buttonTypography.button &&
        this.theme.buttonTypography.button.computedButtonFontStyle
      ) {
        return this.theme.buttonTypography.button.computedButtonFontStyle
      }

      return []
    },
    subtextStyle() {
      return this.theme.buttonTypography.button.computedSubtextStyle
    },
    btnStyle() {
      return {
        display: 'flex !important',
        flexDirection: 'column',
        alignItems: 'center',
      }
    },
    innerBtnStyle() {
      return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: "8px",
      }
    },
  },
  methods: {
    canBeDeleted() {
      return !this.form
    },

    cantDeleteMsg() {
      return 'Form buttons cannot be deleted.'
    },

    canBeDuplicated() {
      return !this.form
    },

    cantBeDuplicatedMsg() {
      return 'Form buttons cannot be duplicated'
    },

    addBelowForm(vnode) {
      return this.parent().addElementBelow(vnode)
    },
  },
})
</script>

<style lang="scss">
// @import "bootstrap";
.cvt-editor-button {
  // display: block  ;
  position: relative;

  // this overrides the cursor: pointer from vnode--selected and vnode--targeted
  & a {
    // if div#page-engine has grid-builder class only then apply this drag-and-drop-handle style
    .grid-builder & {
      cursor: grab !important;
      &:active {
        cursor: grabbing !important;
      }
    }
  }
}

.btn:last-child,
.btn:last-of-type {
  margin-right: 0;
}

.btn-shadow {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.btn-shadow-deep {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.btn-rounded {
  border-radius: 0.25rem;
}

.btn-xl {
  padding: 1.5rem 2rem;
  font-size: 1.75rem;
  border-radius: 0.3rem;
}

.btn-pill {
  border-radius: 50em;
}

.btn-squared {
  border-radius: 0;
}
</style>

<template>
  <div class="wrapper-class">
    <cvt-layouts
      :parent="parent"
      :parent-name="fmtName(parent)"
      :has-parent="hasParent"
      :button-text="parentIsColumn ? `Add Row`: $t(snakeCase(addBtnTxt) + '.cta')"
      :total-nodes="editorNodes.length"
      :type="$attrs.type"
      @parent-layout-setting.stop="select(parent)"
      @parent-layout-copy.stop="duplicateParent(parent)"
      @parent-layout-delete.stop="removeParent(parent)"
      @parent-layout-mouse-over.stop
      @parent-layout-mouse-enter.stop="highlight(parent)"
      @parent-layout-click.stop="goBack(parent)"
      @add-node.stop="parentIsColumn ? addRow() : addObject()"
      @move-node="(from, to) => moveNode(from, to)"
    >
      <cvt-layout-section
        v-for="(node, idx) in editorNodes"
        :key="`${fmtName(node, idx)}-id`"
        :data-id="idx"
        :name="`${fmtName(node, idx).toLowerCase().replace(' ', '-')}-id`"
        :label="fmtName(node, idx)"
        :type="$attrs.type"
        @layout-mouse-enter="highlight(node)"
        @layout-click="enterNode(node)"
        @layout-setting.stop="targetAndSelect(node)"
        @layout-copy.stop="duplicate(node)"
        @layout-delete.stop="remove(node)"
      />
    </cvt-layouts>
  </div>
</template>

<script>
  import * as _ from 'lodash'
  import anime from 'animejs'
  import { mapGetters, mapMutations, mapActions } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as mutations from '../../../store/modules/mutations'
  import * as actions from '../../../store/modules/actions'
  import {
    Block,
    Row,
    Column,
    assignIdsRecursive,
    ELEMENT_NAME_MAP
  } from '../../../lib/helper-classes'
  import {RemoveSectionCommand} from "../../../commands/RemoveSectionCommand";
  import {DuplicateSectionCommand} from "../../../commands/DuplicateSectionCommand";

  function synthMouseEvent (node) {
    return {
      preventDefault () {},
      stopPropagation () {},
      shiftKey: node.layoutElm
    }
  }

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Layout',
    data () {
      return {
        highlightedNode: null,
        nodes: []
      }
    },
    computed: {
      ...mapGetters({
        pageEngine: getters.LAYOUT_PAGE_ENGINE,
        children: getters.LAYOUT_CHILDREN,
        parent: getters.LAYOUT_PARENT,
        path: getters.LAYOUT_PATH,
        config: getters.AUTH_GET_USER_CONFIG,
        isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
        version: getters.BUILDER_VERSION
      }),
      hasParent () {
        return this.path.length > 1
      },
      editorNodes () {
        return this.nodes.filter(n => n.isEditorNode)
      },
      parentName () {
        return _.get(this.parent, 'name')
      },
      inRoot () {
        return this.path.length === 1
      },
      inBlock () {
        return this.parent && this.parent.name === 'Block'
      },
      inRow () {
        return this.parent && this.parent.name === 'Row'
      },
      inColumn () {
        return this.parent && this.parent.name === 'Column'
      },
      addBtnTxt () {
        switch (this.parentName) {
          case 'Block':
            return 'Add Row'
          case 'Row':
            return 'Add Column'
          case 'Column':
            return 'Add Element'
          default:
            return 'Add Section'
        }
      },
      parentIsColumn () {
        return this.parentName === 'Column'
      }
    },
    watch: {
      children(newValue){
        this.nodes = newValue
      }
    },
    created() {
      this.nodes = this.children
    },
    methods: {
      ...mapMutations({
        updatePath: mutations.LAYOUT_UPDATE_PATH
      }),
      ...mapActions({
        toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
        refreshLayout: actions.LAYOUT_REFRESH,
        pushCommand: actions.HISTORY_COMMAND_PUSH,
      }),
      highlight (node) {
        if (node === undefined || !node.vnodeId) return
        this.highlightedNode = node.vnodeId
        node.target(synthMouseEvent(node))
        this.scrollTo(node?.$el)
        this.shakeNode(node?.$el)
      },
      ignore (node) {
        this.highlightedNode = null
        // node.ignore()
      },
      moveNode (from, to) {
        if (to - from < 0) {
          this.moveUp(this.editorNodes[from], to - from)
        } else {
          this.moveDown(this.editorNodes[from], to - from)
        }
      },
      nodeIsActive (node) {
        return this.highlightedNode === node.vnodeId
      },
      fmtName (node, idx) {
        if (!node) return
        return node.anchorName ? node.anchorName : `${ELEMENT_NAME_MAP[node.name]} ${idx !== undefined ? idx + 1 : ''}`.trim()
      },
      hasAnotherLayer (node) {
        return _.chain(node).get('$children').some(c => c.isEditorNode).value()
      },
      enterNode (node) {
        if (this.isGridBuilder) return

        if (!this.hasAnotherLayer(node)) {
          // Notification.warning({
          //   message: 'You have reached the last layer.'
          // })

          return this.target(node, true, true)
        }

        this.target(node, true).then(() => {
          this.updatePath({
            path: [
              ...this.path,
              _.indexOf(this.children, node),
              '$children'
            ]
          })

          this.targetFirstChild()
        })
      },
      targetFirstChild () {
        this.$nextTick(() => {
          let node = _.head(this.editorNodes)
          if (node) {
            node.target(synthMouseEvent(node))
            this.highlightedNode = node.vnodeId
          }
        })
      },
      goBack (node) {
        if (this && this.target) {
          this.target(node, true).then(() => {
            this.updatePath({
              path: this.path.slice(0, -2)
            })

            this.$nextTick(_ => this.parent && this.parent.target && this.parent.target(synthMouseEvent(this.parent)))
          })
        }
      },
      scrollTo (e) {
        e.scrollIntoView({ block: 'nearest' })
      },
      shakeNode (targets, delay = 0) {
        let animation = anime({
          targets,
          duration: 500,
          delay,
          translateY: [0, 50, -50, 0],
          easing: 'easeInOutQuart'
        })

        return animation.finished.then(_ => {
          if(targets && targets.style) {
            targets.style.transform = null;
          }
          return
        })
      },
      shakeNodeLeftToRight (targets, delay = 0) {
        let animation = anime({
          targets,
          delay,
          duration: 500,
          translateX: [0, 50, -50, 0],
          outlineColor: '#ff0000',
          easing: 'easeInOutQuart'
        })

        return animation.finished.then(_ => {
          setTimeout(_ => {
            if(targets && targets.style) {
              targets.style.outlineColor = null;
              targets.style.transform = null;
            }
          }, 500)
          return
        })
      },
      targetAndSelect (node) {
        this.target(node)
        this.select(node)
      },
      select (node) {
        if (!node) return
        node.select(synthMouseEvent(node))
      },
      target (node, scroll = false, leftToRight = false) {

        node?.target(synthMouseEvent(node))

        if (scroll) {
          this.scrollTo(node?.$el)
        }

        return leftToRight ? this.shakeNodeLeftToRight(node.$el) : this.shakeNode(node.$el)
      },
      updateChildren () {
        this.refreshLayout()
      },
      remove (node) {
        if (this.version === 'v3' && node.canBeDeleted()) {
          this.pushCommand(new RemoveSectionCommand(node.idx(), node.fbNode))
          this.updateChildren()
          return
        }
        return node.removeAndValidate(_ => this.updateChildren())
      },
      removeParent (node) {
        node.removeAndValidate(_ => this.updateChildren())
        return this.refreshLayout(this.path.slice(0, -2))
      },
      duplicate (node) {
        if (this.version === 'v3') {
          this.pushCommand(new DuplicateSectionCommand(node.idx(), node.fbNode))
          this.updateChildren()
          return
        }
        return node.duplicateAndValidate(_ => this.updateChildren())
      },
      duplicateParent (node) {
        node.duplicateAndValidate(_ => this.updateChildren())
        return this.refreshLayout(this.path.slice(0, -2))
      },
      addRow () {
        if (this.parentIsColumn) {
          this.addToParent(new Row())
        }
      },
      addObject () {
        let vnode
        switch (this.parentName) {
          case 'PageEngine':
            this.toggleLeftSideMenu({
              activate: 'sections'
            })
            return
          case 'Block':
            vnode = new Row()
            break
          case 'Row':
            vnode = new Column()
            break
          case 'Column':
            this.toggleLeftSideMenu({
              activate: 'elements'
            })
            return
        }

        this.addToParent(vnode)
      },

      addToParent (vnode) {
        this.parent.push(vnode).then(_ => {
          this.updateChildren()
          // this.$nextTick(_ => {
          //   let node = document.getElementById(vnode.data.props.vnodeId)
          //   this.scrollTo(node)
          //   this.shakeNode(node)
          // })
        })
      },
      moveUp (node, steps) {
        if (node?.isFirst()) {
          this.$message.info(this.$t('editor.section.arrange.top_end'));
          return;
        }
        return node?.moveUp(steps)
      },
      moveDown (node, steps) {
        if (node?.isLast()) {
          this.$message.info(this.$t('editor.section.arrange.bottom_end'));
          return;
        }
        return node?.moveDown(steps)
      },
      allowMoveUp (node) {
        return !node.isFirst()
      },
      allowMoveDown (node) {
        return !node.isLast()
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  $layout-font-color: lighten($off-gray, 20%);

  .layout-navigator {
    width: 18rem;
    display: inline-block;
    overflow-y: auto;
    padding-bottom: 11rem;
    padding-right: 1rem;
    color: $layout-font-color;

    &__back {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
        background: lighten($inf-dark-blue, 5%);
      }
    }

    .layer {
      display: flex;
      align-items: stretch;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      border-bottom: 1px solid rgba(175, 175, 173, 0.4);
      transition: all 0.25s ease;

      font-size: 1.3rem;

      &:hover {
        cursor: pointer;
        background: lighten($inf-dark-blue, 5%);
      }

      &__move {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-right: 0.5rem;
        height: 100%;

        i {
          transition: all 250ms ease;
          transform: scale(1);
          &:hover {
            background-color: $slate;
            transform: scale(1.3);
          }
        }
      }

      &__name {
        // flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__ctrls {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .material-icons {
          font-size: 18px;
          line-height: 1;
          cursor: pointer;
        }
      }
    }
  }
  .wrapper-class{
    overflow-y: auto;
  }
</style>

<template>
  <cvt-left-sidebar
    v-if="showSidebar"
    :type="OrgName"
    :panels="computedPanels"
    :active-panel="active"
    :side-bar-left="SideBarLeft"
    :is-template="isTemplate"
    :is-site-preview="isSitePreview"
    @settings="() => (siteSettings ? hideSettings() : showSettings())"
    @panel-toggle="(panel) => toggle(panel)"
  >
    <template v-if="!isGridBuilder" #elements>
      <element-launcher :type="OrgName" />
    </template>
    <template #sections>
      <section-launcher :type="OrgName" />
    </template>
    <template #layouts>
      <layout :type="OrgName" />
    </template>
    <template v-if="(isTemplate || isSitePreview)" #pages>
      <preview-page-manager 
        :is-template="isTemplate" 
        :is-site-preview="isSitePreview"
      />
    </template>
    <template v-else #pages>
      <page-manager />
    </template>
    <template #ssd>
      <ssd-notes />
    </template>
    <template v-if="(isGridBuilder && isAdmin && genAiEnabled)" #genAi>
      <gen-AI-schema />
    </template>
    <template #imageGallery>
      <gallery />
    </template>
    <template #popups>
      <popup-launcher />
    </template>
  </cvt-left-sidebar>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import * as actions from '../../../store/modules/actions'
import ElementLauncher from './ElementLauncher.vue'
import SectionLauncher from './SectionLauncher/SectionLauncher.vue'
import PopupLauncher from './PopupLauncher.vue'
import PageManager from './PageManager.vue'
import PreviewPageManager from '../../marketplace/PreviewPageManager.vue'
import Layout from './Layout.vue'
import Gallery from './ImageGallery.vue'
import SsdNotes from './SsdNotes.vue'
import GenAISchema from './GenAISchema.vue'
import {cvtRoutes} from "@/js/utils/routes";

export default {
  components: {
    ElementLauncher,
    SectionLauncher,
    PopupLauncher,
    PageManager,
    Layout,
    Gallery,
    PreviewPageManager,
    SsdNotes,
    GenAISchema,
  },
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    isSitePreview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const panels = [
      {
        page: 'all',
        label: this.$t('editor.left_nav.add_elements'),
        name: 'elements',
        icon: 'th-large'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.add_sections'),
        name: 'sections',
        icon: 'th-large'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.manage_layout'),
        name: 'layout',
        icon: 'th-large'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.manage_pages'),
        name: 'pages',
        icon: 'file'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.manage_ssd_notes'),
        name: 'ssd',
        icon: 'book'
      },
      {
        page: 'landing-page',
        label: this.$t('element.property.gen.ai.label'),
        name: 'genai',
        icon: 'robot'
      },
      {
        page: 'form-builder',
        label: this.$t('editor.left_nav.manage_steps'),
        name: 'steps',
        icon: 'th-large'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.manage_popups'),
        name: 'popups',
        icon: 'th-large'
      },
      {
        page: 'all',
        label: this.$t('elements.properties.settings'),
        name: 'settings',
        icon: 'cog'
      }
    ]
    const freshworksPanel = [
      {
        page: 'all',
        label: this.$t('editor.left_nav.add_elements'),
        name: 'elements',
        icon: 'th-large'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.add_sections'),
        name: 'sections',
        icon: 'th-large'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.manage_layout'),
        name: 'layout',
        icon: 'th-large'
      },
      {
        page: 'landing-page',
        label: this.$t('editor.left_nav.manage_popups'),
        name: 'popups',
        icon: 'th-large'
      }
    ]
    return {
      panels: panels,
      freshworksPanel: freshworksPanel
    }
  },
  computed: {
    ...mapGetters({
      active: getters.LEFT_SIDE_MENU_ACTIVE_OPTION,
      config: getters.AUTH_GET_USER_CONFIG,
      siteSettings: getters.SITE_SETTINGS,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
    }),
    ...mapState('globalTheme', {
      SideBarLeft: ({ globalTheme }) => globalTheme.SideBarLeft !== undefined ? globalTheme.SideBarLeft : true,
      OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard'
    }),
    showSidebar () {
      return this.$route.matched.some(m => m.meta.pageEngine) || this.isTemplate || this.isSitePreview
    },
    menuItems () {
      return _.omitBy([
        { label: this.$t('editor.left_nav.add_elements'), icon: 'widgets', menu: 'element-launcher' },
        { label: this.$t('editor.left_nav.add_sections'), icon: 'dashboard', menu: 'section-launcher' },
        // { label: 'Image Gallery', icon: 'photo_library', menu: 'image-gallery' },
        // { label: 'Your Uploads', icon: 'file_upload', menu: 'your-uploads' },
        { label: this.$t('editor.left_nav.manage_layout'), icon: 'layers', menu: 'layout' },

        (this.config.PAGE_MANAGER ? { label: this.$t('editor.left_nav.manage_pages'), icon: 'description', menu: 'page-manager' } : null),
        (this.config.ECOMMERCE ? { label: 'Manage Product', icon: 'add_shopping_cart', menu: 'products' } : null)
      ], _.isNull)
    },
    computedPanels () {
      let tempPanel = this.OrgName === 'freshworks' ? this.freshworksPanel.slice() : this.panels.slice()
      if (this.config.ECOMMERCE && this.config.ECOMMERCE_FEATURE_DEVELOPMENT) {
          tempPanel.splice(
              tempPanel.length - 1,
              0,
              {
                  page: 'landing-page',
                  label: 'checkout',
                  name: 'checkout',
                  icon: 'cog'
              }
          )
      }
      if (this.isGridBuilder) {
        tempPanel = tempPanel.filter((panel) => panel.name !== 'elements')
      }

      if (!this.PopupEnabled) {
        tempPanel = tempPanel.filter((panel) => panel.name !== 'popups')
      }

      if (this.popupBuilderOpen) {
        tempPanel = tempPanel.filter((panel) => panel.name === 'popups' || panel.name === 'settings')
      } else {
        tempPanel = tempPanel.filter((panel) => panel.name !== 'popups')
      }

      if(this.isTemplate || this.isSitePreview) {
        tempPanel = tempPanel.filter((panel) => panel.name === 'pages')
      }

      if(!this.ssdNotesEnabled) {
        tempPanel = tempPanel.filter((panel) => panel.name !== 'ssd')
      }

      if (!this.genAiEnabled) {
        tempPanel = tempPanel.filter((panel) => panel.name !== 'genai')
      }

      return tempPanel
    },
    PopupEnabled () {
      return this.config.POPUP_ENABLED || false
    },
    ssdNotesEnabled () {
      return this.config.SSD_NOTES_ENABLED || false
    },
    genAiEnabled () {
      return this.config.IS_GEN_AI_ENABLED || false
    }
  },

  methods: {
    ...mapMutations({
      blockNextStep: mutations.STEPS_BLOCK,
      showSettings: mutations.SHOW_SITE_SETTINGS,
      hideSettings: mutations.HIDE_SITE_SETTINGS
    }),

    ...mapMutations('eCommerce', {
      openProductsDialog: 'openProductsDialog'
    }),

    ...mapActions({
      leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE
    }),

    isActive (menu) {
      return {
        'left-side-bar__item--active': menu === this.active
      }
    },

    toggle (menu) {
      if (!menu || menu === 'settings') {
        return this.leftSideMenuToggle({
          open: false
        })
      }
      if (menu === 'products') {
        return this.openProductsDialog()
      }

      if (menu === 'checkout') {
        const { siteId } = this.$route.params
        this.$router.push({
            name: cvtRoutes.checkoutPageDesign.name,
            params: {
                siteId: siteId,
            },
        })
        return
      }

      this.leftSideMenuToggle({
        activate: menu
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/utils";

.left-side-bar {
  top: 48px;
  left: 0;
  position: fixed;
  z-index: 6;
  width: 70px;
  background: $inf-dark-blue;
  transition: all 0.2s ease;

  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  color: $dark-gray;

  .settings {
    margin-bottom: 100px;
  }
}

.left-side-bar__item {
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: $inf-light-blue;
  }

  &--active {
    background: $slate;
    color: $off-white;

    &:hover {
      color: $inf-light-blue;
    }
  }
}
</style>

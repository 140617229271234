
<anime
  :enter="enter"
  :leave="leave"
  :before-enter="beforeEnter"
>
  <div
    v-if="open"
    class="page-preview-dialog"
  >
    <div :class="[{'dot-grid': true}, {'page-preview__window': templatePreviewAlert}]" />
    
    <cvt-top-nav-bar
      mode="LIGHT"
      variant="embedded"
      route="preview"
      :fixed="true"
      :template-name="title"
      :type="orgName"
      @home="cancelPreview"
      @exit-preview="cancelPreview"
      @switch-preview="switchPreview"
      @use-template="clickedUseTemplate"
    />

    <left-side-bar v-if="showLeftSideBar" :is-template="isTemplate" />

    <div 
      :class="{'page-preview-dialog__window': true, 'preview-info-active': isPagePreviewInfoOpen}">
      <div
        v-if="!isGridBuilder"
        v-show="loading"
        class="page-preview-dialog__window__loader"
      >
        <h1>{{ $t(`editor.message.redirect_msg.${showRedirectMsg}`) }}</h1>
        <loader-square />
      </div>

      <cvt-alert-with-button
        v-if="showWhyUseTemplateAlert"
        :alert-btn-text='$t("marketplace.templates.preview.why_use_template_alert_btn_text")'
        :alert-message='$t("marketplace.templates.preview.why_use_template_alert_message")'
        @exitPreviewMode="showPreviewDetails"
      />
      <cvt-alert-with-button
        v-if="showUseTemplateAlert"
        :alert-btn-text='$t("marketplace.templates.preview.use_template_alert_btn_text")'
        :alert-message='$t("marketplace.templates.preview.use_template_alert_message")'
        @exitPreviewMode="clickedUseTemplate"
      />

      <embed
        v-if="showEmbededTag"
        :src="previewUrl"
        :class="[mode, {'preview-info-active': isPagePreviewInfoOpen}]"
        width="95%"
        height="95%"
        type="text/html"
      >

      <img 
        v-if="isPagePreviewInfoOpen"
        class="desktop preview-info-active"
        :src="templateImageUrl"
      />

      <iframe
        v-if="showIframeTag"
        v-show="!loading"
        :class="mode"
        :src="previewUrl"
        frameborder="0"
        @load="iframeLoaded"
      />
    </div>

    <cvt-preview-details 
      v-if="isPagePreviewInfoOpen"
      :video-path="templateVideoUrlFmt"
      :description="templateDescription"
    />
  </div>
</anime>

<script lang="jsx">
//@ts-nocheck
import { customClientConfig } from '@/js/utils/helpers'
import { cvtRouteNodes } from '@/js/utils/routes'
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import ConvrrtLayout from '../../Layout/ConvrrtEditorLayout/ConvrrtEditorLayout.vue'
import MouseFollower from '../../MouseFollower.vue'
import AdvancedPopoverMenu from '../../editor/advanced-popover-menu/AdvancedPopoverMenu.vue'
import RenderPopupBuilder from '../../PopUpRenderer.vue'
import WizardNav from './WizardNav.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'WizardEditor',
  components: {
    MouseFollower,
    ConvrrtLayout,
    AdvancedPopoverMenu,
    RenderPopupBuilder,
    WizardNav,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      routeEnter: {
        opacity: [0, 1],
      },
      routeLeave: {
        opacity: [1, 0],
      },
      isOverflown: true,
      prevRoute: null,
      grid: undefined,
      counter: 0,
    }
  },
  computed: {
    ...mapGetters({
      site: getters.SITE_GET_DATA,
      indexPage: getters.SITE_GET_INDEX,
      thankyouPage: getters.SITE_GET_THANKYOU,
      siteGetActivePage: getters.SITE_GET_ACTIVE_PAGE,
      advancedMenuOpen: getters.ADVANCED_MENU_OPEN,
      leftSideMenuOpen: getters.LEFT_SIDE_MENU_OPEN,
      viewmodeClass: getters.VIEW_MODE_CLASS,
      pagesOpen: getters.LEFT_SIDE_PAGE_OPEN,
      rightSectionsOpen: getters.LEFT_SIDE_SECTION_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      surveyOpen: getters.SURVEY_OPEN,
      // isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      version: getters.BUILDER_VERSION,
      // showScheduleDemoModal: getters.GET_MODAL,
      viewMode: getters.VIEW_MODE,
    }),
    ...mapState('globalTheme', {
      SideBarLeft: ({ globalTheme }) =>
        globalTheme.SideBarLeft !== undefined ? globalTheme.SideBarLeft : true,
      PagesSidePanelLeft: ({ globalTheme }) =>
        globalTheme.PagesSidePanelLeft !== undefined
          ? globalTheme.PagesSidePanelLeft
          : false,
      AddSectionsBackDrop: ({ globalTheme }) =>
        globalTheme.AddSectionsBackDrop !== undefined
          ? globalTheme.AddSectionsBackDrop
          : false,
    }),
    isLaunchPageRoute() {
      return this.$route.name === cvtRouteNodes.editorLaunch
    },
    pageEngineWrapperClasses() {
      const sideBarOpenPosition = this.SideBarLeft
        ? 'page-engine-wrapper--left-side-menu-open'
        : 'page-engine-wrapper--right-side-menu-open'
      const advancedMenuSize = this.SideBarLeft
        ? 'page-engine-wrapper--advanced-menu-open'
        : 'page-engine-wrapper--advanced-menu-open-small-shift'
      let doNotapplyExtraEffect = true
      if (this.PagesSidePanelLeft && this.pagesOpen) {
        doNotapplyExtraEffect = false
      }
      return {
        [this.viewmodeClass]: true,
        // [sideBarOpenPosition]: this.leftSideMenuOpen && doNotapplyExtraEffect,
        [advancedMenuSize]: true,
        'page-engine-wrapper--editor-active': true,
        'page-engine-wrapper--editor-active-small-scale': true,
        'page-engine-wrapper--editor-active-grid-small-scale': true,
        'page-engine-wrapper--advanced-menu-open-grid': true,
      }
    },
    pageEngineWrapperNew() {
      return {
        [this.viewmodeClass]: !this.isLaunchPageRoute,
        'layout-wrapper wizard-page-engine-wrapper': true,
      }
    },
    layoutTopMargin() {
      return {
        'container-pd-64': this.SideBarLeft,
        'container-pd-50': !this.SideBarLeft,
      }
    },
  },
  mounted() {
    // this.bindShortcutKeys()
    customClientConfig(this.site.orgID)
  },
  async created() {
    // Set configurations for tenants if they exist
    this.setTheme(this.config.GLOBAL_THEME)
    this.setTopNavConfig(this.config.TOP_NAV_CONFIG)
    this.setLeftNavConfig(this.config.LEFT_NAV_CONFIG)
    this.setMarketplaceConfig(this.config.MARKETPLACE_CONFIG)
    this.setIconConfig(this.config.ICON_CONFIG)
    this.setGlobalButtonConfig(this.config.GLOBAL_BUTTON_CONFIG)
    this.setGlobalCheckBoxConfig(this.config.GLOBAL_CHECKBOX_CONFIG)
    this.setChatWidgetConfig(this.config?.TENANT_CHAT_WIDGET)
    // Set configurations for tenants End
  },
  methods: {
    ...mapActions('globalTheme', {
      setTheme: 'setTheme',
      setTopNavConfig: 'setTopNavConfig',
      setLeftNavConfig: 'setLeftNavConfig',
      setIconConfig: 'setIconConfig',
      setMarketplaceConfig: 'setMarketplaceConfig',
      setGlobalButtonConfig: 'setGlobalButtonConfig',
      setGlobalCheckBoxConfig: 'setGlobalCheckBoxConfig',
      applyHistory: actions.HISTORY_APPLY,
    }),
    ...mapActions({
      deselect: actions.SELECTOR_DESELECT,
      removeSelected: actions.SELECTOR_REMOVE_SELECTED,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
    }),
    ...mapMutations({
      ignore: mutations.SELECTOR_IGNORE,
      openQuickLaunchBlock: mutations.QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER,
      hideEditorNameDialog: mutations.HIDE_EDITOR_NAME_DIALOG,
      toggleScheduleDemoModal: mutations.SET_MODAL,
      setChatWidgetConfig: mutations.SET_CHAT_WIDGET_CONFIG,
    }),
    beforeEnter(e) {
      e.style.opacity = 0
    },
    ignoreDeselectAndToggleLeftSideMenu() {
      this.deselect()
      this.ignore()
      this.toggleLeftSideMenu({ open: false })
    },
  },
  render(h) {
    const { pageId } = this.$route.params
    let page = null
    if (pageId) {
      page = _.chain(this.$store)
        .get('state.site.site.pages')
        .find({ id: pageId })
        .value()
    }
    const pageEngineWrapperData = {
      class: [this.pageEngineWrapperClasses, 'page-engine-wrapper'],
    }
    const pageEngineWrapperNewClass = {
      class: [this.pageEngineWrapperNew],
    }

    const topMarginLayoutClass = {
      class: [this.layoutTopMargin],
    }
    return (
      <div onMouseOver={this.ignore}>
        <wizard-nav title={'Generative Content Wizard Editor'} />
        <advanced-side-menu />
        {/* Advance Popover Menu*/}
        <advanced-popover-menu />
        <mouse-follower />
        <convrrt-layout
          showLeftSideBar={true}
          showEditorControls={true}
          isGridBuilder={true}
          viewMode={this.viewMode}
          {...topMarginLayoutClass}
        >
          {/* Page Engine Wrapper*/}
          <anime
            enter={this.routeEnter}
            leave={this.routeLeave}
            before-enter={this.beforeEnter}
          >
            <div {...pageEngineWrapperNewClass}>
              {!this.surveyOpen && !this.popupBuilderOpen && <router-view />}
              {this.surveyOpen && !this.popupBuilderOpen && (
                <div>
                  <iframe
                    src="https://app.staging.contactsmarter.com/campaigns/aszKPmtLyNnLCAkeyvf5u4/funnel/embed"
                    frameborder="0"
                    style="height:100vh;width:100vw"
                  />
                </div>
              )}
              {/* CPopUp Builder Renderer*/}
              {pageId && page && (
                <RenderPopupBuilder
                  popupPath={`${page.firebaseRef}/popup`}
                  isPopupOpen={this.popupBuilderOpen}
                  pageEngineWrapperClass={[]}
                />
              )}
            </div>
          </anime>
        </convrrt-layout>

        <div class={this.showSectionsBackdrop} />
      </div>
    )
  },
}
</script>
<style scoped>
.wizard-page-engine-wrapper {
  background: #fff;
  margin: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  box-shadow: 0 0 0 0.75pt #d1d1d1, 0 0 3pt 0.75pt #ccc;
  transition: all 0.3s cubic-bezier(0.73, 0, 0.23, 0.99);
}
.layout-style-1-with-controls .layout-wrapper.wizard-page-engine-wrapper {
  margin-top: 10px;
}
</style>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import AdvancedPopoverMenu from '../../../../js/components/editor/advanced-popover-menu/AdvancedPopoverMenu.vue'
import LayoutBreadcrumb from '../../../../js/components/editor/LayoutBreadcrumb.vue'
import RightClickMenu from '../../../../js/components/editor/RightClickMenu.vue'
import MouseFollower from '../../../../js/components/MouseFollower.vue'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import LeftSideBar from '../layouts/LeftSidebar.vue'
import NameLandingPage from './left-side-menu/NameLandingPage.vue'
import MainNav from './MainNav.vue'
import SubNavBar from '../layouts/SubNavBar.vue'
import RevisionManager from "../../../components/RevisionManager.vue";
import RevisionSaveManager from "../../../components/RevisionSaveManager.vue";
import { cvtRouteNodes } from "@/js/utils/routes"

export default {
  components: {
    RightClickMenu,
    LayoutBreadcrumb,
    LeftSideBar,
    AdvancedPopoverMenu,
    MouseFollower,
    NameLandingPage,
    'popup-main-nav': MainNav,
    'sub-nav-bar': SubNavBar,
    RevisionManager,
    RevisionSaveManager,
  },
  data() {
    return {
      routeEnter: {
        opacity: [0, 1],
      },
      routeLeave: {
        opacity: [1, 0],
      },
      isOverflown: true,
      prevRoute: null,
      grid: undefined
    }
  },
  computed: {
    pageEngineWrapperData() {
      return [this.pageEngineWrapperClasses, 'page-engine-wrapper', 'page-engine-wrapper-popup']
    },
    ...mapGetters({
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
      showNameDialog: getters.EDITOR_NAME_DIALOG,
      rightSectionsOpen: getters.LEFT_SIDE_SECTION_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      selected: getters.SELECTOR_SELECTED,
      version: getters.BUILDER_VERSION,
    }),
    ...mapState('globalTheme', {
      SideBarLeft: ({ globalTheme }) =>
        globalTheme.SideBarLeft !== undefined ? globalTheme.SideBarLeft : true,
      PagesSidePanelLeft: ({ globalTheme }) =>
        globalTheme.PagesSidePanelLeft !== undefined
          ? globalTheme.PagesSidePanelLeft
          : false,
      AddSectionsBackDrop: ({ globalTheme }) =>
        globalTheme.AddSectionsBackDrop !== undefined
          ? globalTheme.AddSectionsBackDrop
          : false,
    }),

    pageEngineWrapperClasses() {
      const sideBarOpenPosition = this.SideBarLeft
        ? 'page-engine-wrapper--left-side-menu-open'
        : 'page-engine-wrapper--right-side-menu-open'
      const advancedMenuSize = this.SideBarLeft
        ? 'page-engine-wrapper--advanced-menu-open'
        : 'page-engine-wrapper--advanced-menu-open-small-shift'
      let doNotapplyExtraEffect = true
      if (this.PagesSidePanelLeft) {
        doNotapplyExtraEffect = false
      }
      return {
        [this.viewmodeClass]: true,
        [sideBarOpenPosition]: this.leftSideMenuOpen && this.$route.name !== cvtRouteNodes.popupEditorPublish,
        [advancedMenuSize]:
          this.$route.name !== cvtRouteNodes.popupEditorPublish
            ? !this.isGridBuilder
              ? this.selected && this.advancedMenuOpen
              : true
            : false,
        'page-engine-wrapper--editor-active':
          this.SideBarLeft && this.showEditorControls,
        'page-engine-wrapper--editor-active-small-scale':
          !this.SideBarLeft && this.showSideControls,
        'page-engine-wrapper--editor-active-grid-small-scale':
          this.isGridBuilder && this.showSideControls,
        'page-engine-wrapper--advanced-menu-open-grid':
          this.isGridBuilder && this.showSideControls,
        // Scale down the page engine for Gridbuilder/Freshwork
        'page-engine-wrapper--editor-active-grid-tiny-scale':
          !this.SideBarLeft && this.isGridBuilder,
        'page-engine-wrapper--right-side-menu-open':
          !this.SideBarLeft && this.isGridBuilder,
      }
    },
    showSectionsBackdrop() {
      return this.AddSectionsBackDrop
        ? {
            backdrop: this.rightSectionsOpen,
          }
        : {}
    },
    changeZIndex() {
      return {
        'change-z-index': this.rightSectionsOpen,
      }
    },
    showEditorControls() {
      const  { popupEditorPublish, popupEditorSettings, popupEditorDesign  } = cvtRouteNodes
      return [popupEditorDesign, popupEditorPublish, popupEditorSettings].includes(this.$route.name)
    },
    showSideControls() {
      const  { popupEditorSettings, popupEditorDesign  } = cvtRouteNodes
      return [popupEditorDesign, popupEditorSettings].includes(this.$route.name)
    },
    editingLandingPage() {
      return this.$route.name === cvtRouteNodes.popupEditorDesign
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  mounted() {
    this.bindShortcutKeys()
  },
  async created() {
    // Set configurations for tenants if they exist
    this.setTheme(this.config.GLOBAL_THEME)
    this.setTopNavConfig(this.config.TOP_NAV_CONFIG)
    this.setLeftNavConfig(this.config.LEFT_NAV_CONFIG)
    this.setMarketplaceConfig(this.config.MARKETPLACE_CONFIG)
    this.setIconConfig(this.config.ICON_CONFIG)
    this.setGlobalButtonConfig(this.config.GLOBAL_BUTTON_CONFIG)
    this.setGlobalCheckBoxConfig(this.config.GLOBAL_CHECKBOX_CONFIG)
    // Set configurations for tenants End
  },
  methods: {
    ...mapActions('globalTheme', {
      setTheme: 'setTheme',
      setTopNavConfig: 'setTopNavConfig',
      setLeftNavConfig: 'setLeftNavConfig',
      setMarketplaceConfig: 'setMarketplaceConfig',
      setIconConfig: 'setIconConfig',
      setGlobalButtonConfig: 'setGlobalButtonConfig',
      setGlobalCheckBoxConfig: 'setGlobalCheckBoxConfig',
    }),
    ...mapActions({
      deselect: actions.SELECTOR_DESELECT,
      removeSelected: actions.SELECTOR_REMOVE_SELECTED,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
    }),
    ...mapMutations({
      ignore: mutations.SELECTOR_IGNORE,
      openQuickLaunchBlock: mutations.QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER,
      hideEditorNameDialog: mutations.HIDE_EDITOR_NAME_DIALOG,
    }),
    bindShortcutKeys() {
      window.addEventListener('keyup', (e) => {
        // Escape Key (When Shift, Alt, Control and Commands keys are not pressed and then Escaped key is pressed)
        if (
          (!e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) &&
          (e.key === 'Escape')
        )
        {
          this.ignoreDeselectAndToggleLeftSideMenu()
        }
        // Delete|Backspace Key (When Shift, Alt, Control and Commands keys are not pressed and then Delete|Backspace key is pressed)
        else if (
          (!e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) &&
          (e.key === 'Delete' || e.key === 'Backspace')
        )
        {
          if(e.target.tagName === 'BODY') {
            if(this.version === 'v3') {
              if(this.selected?.name === 'Block') {
                this.selected?.deleteSection()
              }else {
                this.selected?.parent()?.removeElement()
              }
            } else {
              // code for old-builder and v2
              this.selected?.removeAndValidate()
            }
            this.ignoreDeselectAndToggleLeftSideMenu()
          }
        }
      }, { passive: true }
      )
    },
    ignoreDeselectAndToggleLeftSideMenu() {
      this.deselect()
      this.ignore()
      this.toggleLeftSideMenu({ open: false })
    },
    beforeEnter(e) {
      e.style.opacity = 0
    },
    blur() {
      console.log('blur editor')
    },
    focus() {
      console.log('focus editor')
    },
    savePopupCampaignName(name) {},
  },
}

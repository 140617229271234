
<cvt-settings
  :active-tab="settingsActiveTab"
  :usage="'PopupSiteSettings'"
  :left-side-width="'15%'"
  @toggle="(id) => setSettingsActiveTab(id)"
>
  <template #general>
    <div class="nav-tab-wrap" data-settings-tab="general">

      <!-- Popup Name Input -->
      <div class="row pb-3 mb-4">
        <div class="col-md-10">
          <cvt-input
              v-model="popupName"
              v-validate="validatePopupName"
              type="text"
              name="name"
              :label="'Popup Name'"
              :clearable="false"
              :validation-status="nameInvalid ? 'invalid' : 'valid'"
              :valid-message="'Popup name is valid'"
              :invalid-message="'Popup name is invalid'"
          />
        </div>
        <div v-show="nameInvalid" class="invalid-feedback">
          Popup name is invalid
        </div>
      </div>

      <!-- Brand Colors -->
      <div class="row mb-4">
        <p class="col-md-10">Select Brand Colors</p>
        <div class="col-md-10">
          <colors
              :alpha="false"
              :mode="mode"
              usage="SiteSettings"
          ></colors>
        </div>
      </div>

      <!-- Font Family, Font Size & Font Default Text Color -->
      <cvt-collapse class="row" :accordion="true" :prepend-chevron="true">
        <cvt-collapse-item
          :title="'Typography'"
          name="properties"
          :initial-active="true"
        >
          <div
            v-for="style in fontStyles"
            :key="style.label"
            class="row flex-grow-1 mb-3"
          >
            <div class="col-md-3 d-flex align-items-center mt-3 mb-1">
              <p
                class="my-auto"
                :style="style.format.toStyle()"
                v-text="$t(`setup.font.${snakeCase(style.label)}`)"
              />
            </div>
            <div
                class="col-md-8 d-flex justify-content-center align-items-center"
            >
              <div class="row flex-grow-1">
                <div class="col-5">
                  <font-picker
                    :value="style.format?.font"
                    @input="updateFontFamily(style, $event)"
                  />
                </div>
                <div class="row col-7">
                  <div class="col-6">
                    <font-size-selector
                      :value="style.format?.size"
                      @input="updateFontSize(style, $event)"
                    />
                  </div>
                  <div class="col-6">
                    <font-weight-selector
                      :value="style.format?.weight"
                      @input="updateFontWeight(style, $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 d-flex justify-content-center align-items-center mt-3 mb-1">
              <div class="row">
                <div class="col-12">
                  <default-color-selector
                      :value="style.format.defaultTextColor"
                      @input="updateDefaultTextColor(style, $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </cvt-collapse-item>
      </cvt-collapse>

      <!-- Button Default Styles -->
      <cvt-collapse class="row" :accordion="true" :prepend-chevron="true">
          <cvt-collapse-item
            :title="$t('settings.buttons.label')"
            name="properties"
            :initial-active="false"
          >
            <div
              v-for="style in buttonDefaultStyles"
              :key="style.label"
              class="row flex-grow-1 mb-3"
            >
              <div class="col-md-3 d-flex align-items-center mb-3">
                <div class="c-btn-wrapper">
                  <c-button
                    class="my-auto"
                    :cta="style.label"
                    :subtext="$t('editor.element.button_subtext')"
                  />
                </div>
              </div>
              <div
                class="col-md-8 d-flex justify-content-center align-items-center"
              >
                <div class="row flex-grow-1">
                  <div class="col-5">
                    <font-picker
                      :value="style.format.fontFamily"
                      @input="updateButtonDefaultFontFamily(style, $event)"
                    />
                  </div>
                  <div class="row col-7">
                    <div class="col-6">
                      <font-size-selector
                        :value="style.format.fontSize"
                        @input="updateButtonDefaultFontSize(style, $event)"
                      />
                    </div>
                    <div class="col-6">
                      <font-weight-selector
                        :value="style.format.fontWeight"
                        @input="updateButtonDefaultFontWeight(style, $event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1 d-flex justify-content-center align-items-center mt-3">
                <div class="row">
                  <div class="col-12">
                    <default-color-selector
                      :value="style.format.defaultBackgroundColor"
                      @input="updateButtonDefaultBackgroundColor(style, $event)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Subtext -->
            <div
              v-for="style in buttonDefaultStyles"
              :key="style.label + $t('editor.element.button_subtext')"
              class="row flex-grow-1"
            >
              <div class="col-md-3 d-flex align-items-center mb-3">
                <div class="c-btn-wrapper mt-4 h2">
                  {{ $t('editor.element.button_subtext') }}
                </div>
              </div>
              <div class="col-md-8 d-flex justify-content-center align-items-center">
                <div class="row flex-grow-1">
                  <div class="col-5">
                    <font-picker
                      :value="style.format.subtextStyle.fontFamily"
                      @input="updateButtonSubtextFontFamily(style, $event)"
                    />
                  </div>
                  <div class="row col-7">
                    <div class="col-6">
                      <font-size-selector
                        :value="style.format.subtextStyle.fontSize"
                        sub
                        @input="updateButtonSubtextFontSize(style, $event)"
                      />
                    </div>
                    <div class="col-6">
                      <font-weight-selector
                        :value="style.format.subtextStyle.fontWeight"
                        @input="updateButtonSubtextFontWeight(style, $event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </cvt-collapse-item>
        </cvt-collapse>

      <!-- Link Default Styles -->
      <cvt-collapse class="row" :accordion="true" :prepend-chevron="true">
        <cvt-collapse-item
          :title="'Links'"
          name="properties"
          :initial-active="false"
        >
          <div
            v-for="style in linkDefaultStyles"
            :key="style.label"
            class="row flex-grow-1"
          >
            <div class="col-md-3 d-flex align-items-center mt-1 mb-2">
              <div class="c-btn-wrapper">
                <a class="my-auto underline-link" :style="style.format.getLinkFontStyle()">
                  {{ style.label }}
                </a>
              </div>
            </div>
            <div class="col-md-2 d-flex align-items-center m-0">
              <div class="row">
                <div class="col-12">
                  <default-color-selector
                    :value="style.format.defaultTextColor"
                    @input="updateFroalaLinkDefaultTextColor(style, $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </cvt-collapse-item>
      </cvt-collapse>
    </div>
  </template>

  <template #audience>
    <div data-settings-tab="audience">
      <cvt-sap-audience
        :show-visitor-types="showVisitorTypes"
        :show-traffic-sources="showTrafficSources"
        :show-event-conditions="showEventConditions"
        :show-url-conditions="showUrlConditions"

        :visitor-types="visitorTypes"
        :specific-visitor-types="specificVisitorTypes"
        :traffic-sources="trafficSources"
        :specific-traffic-sources="specificTrafficSources"
        :event-conditions="eventConditions"
        :url-conditions="urlConditions"

        @visitorTypesChanged="(value) => visitorTypesChanged(value)"
        @specificVisitorAdded="(value) => specificVisitorAdded(value)"
        @trafficSourcesChanged="(value) => trafficSourcesChanged(value)"
        @specificTrafficSourcesAdded="(value) => specificTrafficSourcesAdded(value)"
        @eventConditionsChanged="(value) => eventConditionsChanged(value)"
        @urlConditionsChanged="(value) => urlConditionsChanged(value)"
      />
    </div>
  </template>

  <template #rules>
    <div class="nav-tab-wrap" data-settings-tab="rules">
      <cvt-sap-rules
        :show-trigger-options="showTriggerOptions"
        :show-frequency-option="showFrequencyOption"
        :show-placement-options="showPlacementOptions"
        :show-opening-time-trigger="showOpeningTimeTrigger"
        :show-page-scroll-trigger="showPageScrollTrigger"
        :show-exit-intent-trigger="showExitIntentTrigger"
        :show-event-trigger="showEventTrigger"
        :show-css-selector-trigger="showCssSelectorTrigger"

        :trigger="trigger"
        :time-frame="timeFrame"
        :exit-intent="exitIntent"
        :mouse="mouse"
        :page-scroll="pageScroll"

        @triggerChanged="(value) => triggerChanged(value)"
        @timeFramePropsChanged="(value) => timeFramePropsChanged(value)"
        @exitIntentPropsChanged="(value) => exitIntentPropsChanged(value)"
        @mousePropsChanged="(value) => mousePropsChanged(value)"
        @pageScrollPropsChanged="(value) => pageScrollPropsChanged(value)"
      />
    </div>
  </template>

</cvt-settings>

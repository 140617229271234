import '@/js/lib/stripe-connect'
import '@/scss/element-ui.scss'
import '@/scss/grid-stack.scss'
import { VueEmotion } from '@egoist/vue-emotion'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import axios from 'axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/save.min.js'
import 'gridstack/dist/gridstack.min.css'
import * as jQuery from 'jquery'
import * as smoothScroll from 'smoothscroll-polyfill'
import VTooltip from 'v-tooltip'
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { sync } from 'vuex-router-sync'
import ConvrrtUI from '../../../storybook/components/convrrt-ui'
import { worker } from '../mocks/browser'
import App from './App.vue'
import { setupFroala } from './FroalaConfigurations'
import languagePacks from './i18n'
import './lib/convrrtGridstack'
import './lib/findRecurs'
import EventHub from './plugins/EventHub'
import './plugins/filters'
import Router from './router'
import Store from './store'
import './store/services/firebase.service'

// @ts-ignore
// eslint-disable-next-line no-undef
if (__MOCK_ENABLED__) {
  worker.start().catch((err) => {
    console.error('failed to start mock service worker', err)
  })
} else {
  console.debug('mock service worker disabled')
}

smoothScroll.polyfill()

Vue.config.performance = false
Vue.config.productionTip = false
// enable vue-devtools
// causes redirect loop
// Vue.config.devtools = true

// @ts-ignore
// eslint-disable-next-line no-undef
if (__SENTRY_ENABLED__) {
  Sentry.init({
    enabled: true,
    dsn: 'https://4fa60071135340618d650aea1875d4a6@sentry.io/1505214',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: window.location.hostname,
  })
}
window.$ = jQuery
Vue.use(VueI18n)
Vue.use(EventHub)
Vue.use(VTooltip)
Vue.use(ElementUI, { locale })
Vue.use(ConvrrtUI, { locale })
Vue.use(VeeValidate, {
  classes: true,
  delay: 1000,
  fieldsBagName: 'vFields',
  classNames: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'is-valid', // model is valid
    invalid: 'is-invalid', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty', // control has been interacted with
  },
})
Vue.use(VueEmotion)

// create a global vue method for snake_casing
Vue.prototype.snakeCase = function (str = '') {
  return str.toLowerCase().replace(/\s+/gi, '_')
}

const i18n = new VueI18n({
  locale: 'en-US',
  messages: languagePacks,
})

const store = new Store({
  i18n,
})

const router = new Router(store)

sync(store, router)

// @ts-ignore
Vue.directive('popover', ElementUI.Popover.directive)
Vue.directive('clickoutside', Clickoutside)

Vue.directive('mac-scroll-fix', {
  inserted(el) {
    if (
      navigator &&
      navigator.userAgent &&
      navigator.userAgent.includes('Mac OS X 10_12')
    ) {
      el.style.width = '100%'
    }
  },
})

async function injectCustomScripts() {
  const scripts = await axios
    .get<{
      [key: string]: {
        name: string
        content: string
        location: string
      }
    }>('/api/custom-scripts')
    .then(({ data }) => data || {})

  for (const key in scripts) {
    const script = scripts[key]
    const el = document.querySelector(script.location)
    if (!el) {
      console.warn(`Could not find element for custom script ${script.name}`)
      continue
    }

    try {
      el.insertAdjacentHTML('beforeend', script.content)
    } catch (err) {
      console.error(`Error inserting custom script ${script.name}`, err)
    }
  }
}

async function main() {
  await injectCustomScripts()
  setupFroala(store)

  new Vue({
    // eslint-disable-line no-new
    el: '#app',
    i18n,
    store,
    router,
    render: (h) => h(App),
  })
}

main().catch((err) => {
  console.error('failed to load app', err)
})

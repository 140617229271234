
<div class="d-flex h-100" style="overflow: auto">

  <!-- Drag and Drop List of Pages -->
  <cvt-pages
    v-show="isPagesList"
    :pages="pages"
    :active-id="activeId"
    :allow-delete="allowDelete"
    :allow-duplicate="allowDuplicate"
    :allow-swap="allowSwap"
    :allow-create="allowCreate"
    :allow-bookmark="true"
    :is-admin="isAdmin"
    :is-template="false"
    @edit="(e) => edit(e)"
    @settings="(e) => openSettings(e)"
    @copy="(e) => duplicate(e)"
    @delete="(e, page) => showDeleteDialog(e)"
    @createNew="(e) => createNew(site.id)"
    @save="(e) => save(e)"
    @layout="(page) => showReplacePageDialog(page)"
    @bookmark="(e) => showPageBookmarkDialog(e)"
  />

  <!-- Delete Page Dialog -->
  <cvt-dialog
    :show="showRemovePageDialog"
    :height-auto="true"
    size="sm"
    @close="showRemovePageDialog = false"
  >
    <template #title>
      <h5>{{ $t("editor.left_nav.page_manager.remove_dialog.template.h5") }}</h5>
    </template>
    <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
      <p>{{ $t("editor.left_nav.page_manager.remove_dialog.cvt_alert.p") }}</p>
    </cvt-alert>
    <template #modalFooter>
      <div class="d-flex">
        <cvt-button
          :loading="removing"
          class="mr-1"
          color="light"
          :text="$t(`pagemanager.cvt_dialog.submit.btn`)"
          @click="proceedPageRemoval"
        />
        <cvt-button
          :loading="removing"
          color="warning"
          :text="$t(`pagemanager.cvt_dialog.cancel.btn`)"
          @click="abortPageRemoval"
        />
      </div>
    </template>
  </cvt-dialog>

  <!-- Swap Page Layout Dialog -->
  <cvt-dialog
    :show="replacePageDialogVisible"
    :height-auto="true"
    size="sm"
    @close="replacePageDialogVisible = false"
  >
    <template #title>
      <h5>{{ $t("editor.left_nav.page_manager.layout_dialog.template.h5") }}</h5>
    </template>

    <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
      <p>{{ $t("editor.left_nav.page_manager.layout_dialog.cvt_alert.p") }}</p>
      <span>{{ $t("editor.left_nav.page_manager.layout_dialog.cvt_alert.span") }}</span>
    </cvt-alert>

    <template #modalFooter>
      <div class="d-flex">
        <cvt-button
          v-bind="cancelBtnProps"
          :text="$t(`editor.left_nav.page_manager.layout_dialog.template.footer.cancel.btn`)"
          @click="abortReplacePage"
        />
        <cvt-button
          v-bind="submitBtnProps"
          class="mr-1"
          :text="$t(`editor.left_nav.page_manager.layout_dialog.template.footer.submit.btn`)"
          @click.stop="confirmReplacePage"
        />
      </div>
    </template>
  </cvt-dialog>

  <!-- Firebase Pages List -->
  <cvt-save-pages
    v-show="isSavePage"
    :pages="filteredSavedPages"
    :loading="loading"
    :inserting-page="insertingOrReplacingPage"
    :page-tags="tags"
    @insert-page="(e) => insertOrReplacePage(e)"
    @insert-blank-page="(e) => insertBlankPage(e)"
    @back="back"
    @filter-section="(value) => (activeTag = value)"
    @user-view-preference="(data) => userViewPreference = data"
    @delete-bookmark-dialog="(page) => showDeleteBookmarkDialog(page)"
    @edit-page-name="(page, replaceText) => editPageName(page, replaceText)"
  />

  <!-- Delete Page Bookmark Dialog -->
  <cvt-dialog
    :show="deleteBookmarkVisible"
    :height-auto="true"
    size="sm"
    @close="deleteBookmarkVisible = false"
  >
    <template slot="title">
      <h5>Delete Page Bookmark</h5>
    </template>

    <cvt-alert class="w-100" color="warning">
      <p>
        You are deleting: {{ selectedBookmarkPage && selectedBookmarkPage.pageName }}. <br/>
        Are you sure you want to continue?
      </p>
    </cvt-alert>

    <template slot="modalFooter">
      <div class="d-flex">
        <cvt-button
          class="mr-1"
          :loading="deletingBookmark"
          color="light"
          :text="$t('marketplace.dialog.archive.cta.cancel')"
          @click="deleteBookmarkVisible = false"
        />
        <cvt-button
          color="warning"
          :loading="deletingBookmark"
          :text="$t('marketplace.dialog.archive.cta.submit')"
          @click="onDeleteBookmark"
        />
      </div>
    </template>
  </cvt-dialog>

  <!-- Page Settings Dialog -->
  <cvt-page-settings
 v-show="isPageSettings"
    :pages="filteredSavedPages"
    :loading="loading"
    :page-tags="tags"
    @insert-page="(e) => insertOrReplacePage(e)"
    @insert-blank-page="(e) => insertBlankPage(e)"
    @back="back">
    <page-settings :handle-close="back" />
  </cvt-page-settings>
</div>

import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { database } from '@/js/store/services/firebase.service'
import {
  PopUpUndoRedoManager,
  PopUpUndoRedoManagerV3,
} from '../services/popUpUndoRedoManager'
import { getBuilderServiceClass } from '../services/builderClassFactory'
import { PopupInsertBlockCommand } from '@/js/commands/PopupInsertBlockCommand'

const db = database()

export default {
  state: {
    surveyOpen: false,
    popupBuilderOpen: false,
    currentPopupIdx: -1,
    isPopupEmpty: true,
    popup: null,
    popupUndoRedoManagers: {},
    version: null,
    popupPath: null,
    // firebasePopup: null,
    standaloneBuilderActive: false,
  },
  getters: {
    [getters.SURVEY_OPEN]({ surveyOpen }) {
      return surveyOpen
    },

    [getters.POPUP_BUILDER_OPEN]({ popupBuilderOpen }) {
      return popupBuilderOpen
    },

    [getters.POPUP_IDX]({ currentPopupIdx }) {
      return currentPopupIdx
    },

    [getters.POPUP_EMPTY]({ isPopupEmpty }) {
      return isPopupEmpty
    },

    [getters.BUILDER_VERSION]({ version }) {
      return version
    },

    [getters.POPUP_PATH]({ popupPath }) {
      return popupPath
    },
    [getters.STANDALONE_POPUP_ACTIVE]({ standaloneBuilderActive }) {
      return standaloneBuilderActive
    },
  },
  mutations: {
    [mutations.SET_POPUP_IDX](state, idx) {
      state.currentPopupIdx = idx
    },
    [mutations.INIT_POPUP](state, popup) {
      state.popup = popup
    },
    [mutations.SET_POPUP_EMPTY](state, isEmpty) {
      state.isPopupEmpty = isEmpty
    },
    [mutations.SET_POPUP_PATH](state, popupPath) {
      state.popupPath = popupPath
    },
    // [mutations.SET_POPUP_FIREBASE_DATA] (state, firebasePopup) {
    //   state.firebasePopup = firebasePopup;
    // },
  },
  actions: {
    async [actions.SET_BUILDER_VERSION]({ state }, version) {
      state.version = version
    },
    async [actions.ACTIVATE_STANDALONE_BUILDER](
      { state },
      standaloneBuilderActive,
    ) {
      state.standaloneBuilderActive = standaloneBuilderActive
    },
    async [actions.POPUP_BUILDER_DELETE_POPUP_DATA]({ state }, popupIdx) {
      if (state.popupUndoRedoManagers[popupIdx]) {
        state.popupUndoRedoManagers[popupIdx].unsubscribe()
        delete state.popupUndoRedoManagers[popupIdx]
      }
    },
    async [actions.POPUP_PROPS_REFRESH]({ state, dispatch, rootGetters }) {
      if (state.standaloneBuilderActive) {
        return dispatch(actions.SDA_POPUP_PROPS_REFRESH)
      }
      const historyManager = rootGetters[getters.HISTORY_MANAGER]
      if (
        historyManager instanceof PopUpUndoRedoManagerV3 ||
        historyManager instanceof PopUpUndoRedoManager
      ) {
        historyManager.unsubscribe()
        await historyManager.subscribe()
      }
    },
    async [actions.POPUP_BUILDER_UNDO_REDO_MANAGER](
      { state, dispatch, commit, rootState },
      path,
    ) {
      // get popupUndoRedoManager with path as key from state popupUndoRedoManagers
      const popupUndoRedoManagerClass = getBuilderServiceClass(
        rootState,
        PopUpUndoRedoManagerV3,
        PopUpUndoRedoManager,
      )

      let popupUndoRedoManager =
        state.popupUndoRedoManagers[state.currentPopupIdx]

      if (!popupUndoRedoManager) {
        // create popupUndoRedoManager if there is none
        popupUndoRedoManager = new popupUndoRedoManagerClass(
          dispatch,
          commit,
          path,
          state.currentPopupIdx,
        )
        state.popupUndoRedoManagers[state.currentPopupIdx] =
          popupUndoRedoManager
      }
      await dispatch(actions.HISTORY_SET_POPUP_MANAGER, popupUndoRedoManager)
      return popupUndoRedoManager
    },
    [actions.SURVEY_TOGGLE]({ state }) {
      state.surveyOpen = !state.surveyOpen
    },

    [actions.POPUP_BUILDER_PUSH_NEW_BLOCK]({ state, dispatch }, popupWrapper) {
      return new Promise((resolve, reject) => {
        state.popup
          .push(popupWrapper)
          .then((response) => {
            resolve(response)
          })
          .catch((err) => reject(err))
      })
    },
    async [actions.POPUP_BUILDER_TOGGLE]({ state, dispatch }) {
      state.popupBuilderOpen = !state.popupBuilderOpen
      await dispatch(actions.HISTORY_GRIDBUILDER_POPUP, state.popupBuilderOpen)
    },

    [actions.POPUP_BUILDER_INSERT_BLOCK](
      { state, commit, dispatch, rootState, rootGetters },
      { block, idx },
    ) {
      // we need to verify that isPopupBlock flag is set on the block
      // that way, grid builder block converted to popup, becomes a popup block inside popup builder
      if (block.vnode.data.props.isPopupBlock !== true) {
        block.vnode.data.props.isPopupBlock = true
      }
      if (state.standaloneBuilderActive) {
        return dispatch(actions.SDA_POPUP_BUILDER_INSERT_BLOCK, { block, idx })
      }
      const path = rootGetters[getters.DELTA_KEY]
      const popupRef = `${path}/popup/`.replace('/vdom', '')

      // const popup = db.ref(popupRef + idx + '/children/0')
      // popup.set(block.vnode)
      dispatch(
        actions.HISTORY_COMMAND_PUSH,
        new PopupInsertBlockCommand(
          block,
          popupRef + idx + '/children/',
          'popup-wrapper',
        ),
      )
    },

    [actions.POPUP_BUILDER_UPDATE_WIDTH](
      { state, commit, dispatch, rootState, rootGetters },
      width,
    ) {
      if (state.standaloneBuilderActive) {
        return
      }
      const path = rootGetters[getters.DELTA_KEY]
      const popupRef = `${path}/popup/`.replace('/vdom', '')

      const widthProp = db.ref(popupRef + 'data/props/popupWidth')
      widthProp.set(width)
    },
    // popup btn position
    [actions.POPUP_BUILDER_UPDATE_CLOSE_BTN_POSITION](
      { state, commit, dispatch, rootState, rootGetters },
      position,
    ) {
      if (state.standaloneBuilderActive) {
        return
      }
      const path = rootGetters[getters.DELTA_KEY]
      const popupRef = `${path}/popup/`.replace('/vdom', '')

      const closeBtnPositionProp = db.ref(
        popupRef + 'data/props/closeButtonPosition',
      )
      closeBtnPositionProp.set(position)
    },

    // get popup btn position
    [actions.POPUP_GET_CLOSE_BTN_POSITION]({ state, rootGetters }) {
      if (state.standaloneBuilderActive) {
        return
      }
      return new Promise((resolve, reject) => {
        const path = rootGetters[getters.DELTA_KEY]
        const popupRef = `${path}/popup/`.replace('/vdom', '')

        const popup = db.ref(
          `${popupRef}${state.currentPopupIdx}/data/props/closeButtonPosition`,
        )

        popup.on(
          'value',
          (snapshot) => {
            resolve(snapshot.val())
          },
          (error) => {
            reject(error)
          },
        )
      })
    },

    [actions.POPUP_DELETE_PREMADE](
      { state, commit, dispatch, rootState, rootGetters },
      { block },
    ) {
      if (state.standaloneBuilderActive) {
        return
      }
      const { orgID } = rootGetters[getters.AUTH_GET_USER]
      const { MARKETPLACE_PATH } = rootGetters[getters.AUTH_GET_USER_CONFIG]

      const marketplace = db.ref(MARKETPLACE_PATH).child(orgID)
      return marketplace.child('blocks').child(block['.key']).remove()
    },

    [actions.POPUP_GET_LIST]({ state, rootGetters }) {
      if (state.standaloneBuilderActive) {
        return
      }
      return new Promise((resolve, reject) => {
        const path = rootGetters[getters.DELTA_KEY]
        const popupRef = `${path}/popup/`.replace('/vdom', '')

        const popup = db.ref(popupRef)
        popup.on(
          'value',
          (snapshot) => {
            resolve(snapshot.val())
          },
          function (errorObject) {
            reject(errorObject)
          },
        )
      })
    },

    [actions.POPUP_SET_PATH](
      { state, commit, dispatch, rootState, rootGetters },
      popupIndex,
    ) {
      if (state.standaloneBuilderActive) {
        return
      }
      const path = rootGetters[getters.DELTA_KEY]

      // omitting last entry only (which is vdom )and converting it to a path
      // example data:path['pieces_'] = ['sites-mt-2', 'convrrt-convrrt', '-N2MQwQzVXx-OfySNJa8', 'pages', '-N2MQwccufx8y3rHlyyY', 'vdom']
      // const originalPath = path['pieces_'].slice(0, -1).join('/')

      if (popupIndex !== -1) {
        commit(
          mutations.SET_POPUP_PATH,
          `${path}/popup/${popupIndex}`.replace('/vdom', ''),
        )
      } else {
        commit(mutations.SET_POPUP_PATH, null)
      }
    },

    // [actions.POPUP_SET_FIREBASE_DATA] ({ state, commit, dispatch, rootState, rootGetters }) {

    //   return new Promise( (resolve, reject) => {

    //     const popup = db.ref(`/${state.popupPath}`);

    //     popup.on('value',
    //       (snapshot) => {
    //         commit(mutations.SET_POPUP_FIREBASE_DATA, snapshot.val());
    //         resolve( snapshot.val())
    //       },
    //       (error) => {
    //         commit(mutations.SET_POPUP_FIREBASE_DATA, null);
    //         reject( error );
    //     });

    //   });
    // },

    // [actions.POPUP_REMOVE_FIREBASE_DATA] ({ state, commit, dispatch, rootState, rootGetters }) {
    //   commit(mutations.SET_POPUP_FIREBASE_DATA, null);
    // },

    [actions.POPUP_GET_PROPERTY](
      { state, commit, dispatch, rootState, rootGetters },
      propertyName,
    ) {
      if (state.standaloneBuilderActive) {
        return dispatch(actions.SDA_POPUP_FIREBASE_GET_PROPERTY, propertyName)
      }
      return new Promise((resolve, reject) => {
        const popup = db.ref(`/${state.popupPath}/data/props/${propertyName}`)

        popup.on(
          'value',
          (snapshot) => {
            resolve(snapshot.val())
          },
          (error) => {
            reject(error)
          },
        )
      })
    },

    [actions.POPUP_UPDATE_PROPERTY](
      { state, commit, dispatch, rootState, rootGetters },
      payload,
    ) {
      if (state.standaloneBuilderActive) {
        // do nothing
        return
      }

      // state.popup[payload.key] = payload.value

      const popup = db.ref(`/${state.popupPath}/data/props/${payload.key}`)
      popup.set(payload.value)
    },
  },
}

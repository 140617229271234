import { Notification } from 'element-ui'
import * as qs from 'qs'
import { CustomDomains, Marketplace, V1Sites } from '../services/api'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const sites = new V1Sites()
const customDomains = new CustomDomains()
const marketplace = new Marketplace()

export default {
  state: {
    templates: [],
    domains: [],
  },
  getters: {
    [getters.MARKETPLACE_TEMPLATES]({ templates }) {
      return templates
    },
    [getters.MARKETPLACE_SITE_DOMAINS]({ domains }) {
      return domains
    },
  },
  mutations: {
    [mutations.MARKETPLACE_SET_TEMPLATES](state, data) {
      // It might contain duplicate templates
      let allTemplates = state.templates.concat(data)

      // Making [TemplateId, TempalteObject] format
      // Grouping is made on 'id' attribute of template, it can be any unique key
      let allTemplatesFormated = allTemplates.map((template) => [
        template['id'],
        template,
      ])

      let allTemplatesMapValues = new Map(allTemplatesFormated).values() // 'allTemplatesMapValues' contains, only unique entries
      state.templates = [...allTemplatesMapValues]
    },
    [mutations.MARKETPLACE_UPDATE_DOMAINS](state, domainsList) {
      state.domains = domainsList
    },
  },
  actions: {
    async [actions.MARKETPLACE_LOAD_TEMPLATES](
      { commit, dispatch, rootState },
      query,
    ) {
      const { data } = await marketplace.templates({
        ...query,
        categories: rootState.route.query.categories,
      })
      commit(mutations.MARKETPLACE_SET_TEMPLATES, data)
      return data
    },
    async [actions.MARKETPLACE_CREATE_SITE_FROM_TEMPLATE](
      { dispatch, rootState },
      templateId,
    ) {
      await dispatch('loader/start', 'loader.scratch.message')

      const { nodeId, funnelId, siteId } = rootState.route.query

      const { foreignData } = qs.parse(window.location.search.slice(1)) || {}

      const req = templateId
        ? sites.fromTemplate(templateId, foreignData)
        : sites.create(foreignData)
      const resp = await req.catch((err) => {
        console.error('failed to create site', err)
        dispatch('loader/stop')
        dispatch(actions.PAGE_PREVIEW_TOGGLE, false)
        const { response = {} } = err
        const { data } = response
        if (data && data.errors) {
          for (const e of data.errors) {
            Notification({
              title: e.code || 'woops',
              message: e.message,
              type: 'error',
              position: 'bottom-right',
            })
          }
        } else {
          Notification({
            title: err.status || 'woops',
            message: err.response.data.message,
            type: 'error',
            position: 'bottom-right',
          })
        }
        throw err
      })
      const { data } = resp
      const { id } = data

      if (nodeId && funnelId && !siteId) {
        await sites.updateCampaignNode(id, {
          nodeId,
          funnelId,
        })
      }

      return resp
    },
    [actions.MARKETPLACE_LOAD_DOMAINS]({ commit, state }, templateId) {
      return customDomains.loadDomains({ siteId: templateId }).then((res) => {
        commit(mutations.MARKETPLACE_UPDATE_DOMAINS, res.data.list)
        return res.data.list
      })
    },
  },
}

<template>
  <div>
    <slot name="nav">
      <main-nav />
    </slot>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "GenAILayout",
  props: {}
};
</script>

<style scoped>

</style>
<template>
  <div class="element-image-manager">
    <div
      class="element-image-manager__preview checkered"
      @mouseover.stop.prevent="showOverlay"
      @mouseleave.stop.prevent="hideOverlay"
      @click.stop.prevent="openAssetManager('imageThumbnail')"
    >
      <small v-if="!src && !overlayVisible"
        ><i>{{ $t('imagesearch.results.empty.message') }}</i></small
      >

      <div
        v-if="!isGridBuilder"
        v-show="overlayVisible"
        class="element-image-manager__preview__overlay"
      >
        <small
          ><i>{{ assetMgrBtnText }} Image</i></small
        >
      </div>

      <div
        v-else-if="isGridBuilder"
        v-show="!isBackgroundImageAdded && overlayVisible"
        class="element-image-manager__preview__overlay"
      >
        <small
          ><i>{{ assetMgrBtnText }} Image</i></small
        >
      </div>

      <img
        v-show="isBackgroundImageAdded"
        :src="src"
        class="img-fluid"
        alt=""
      />

      <div
        v-if="canRepositionImage"
        :style="'move'"
        class="color-spectrum__barrier"
        @mousedown="captureClickAndMouseWheelEvent"
      >
        <drag-marker
          v-if="isBackgroundImageAdded"
          ref="backgroundImagePositioner"
          classes="color-spectrum__marker"
          containment
          :style="backgroundImgMarkerInitialPosition"
          @dragmove="positionMove"
        >
        </drag-marker>
      </div>
    </div>

    <div class="my-4">
      <cvt-input
        v-model="caption"
        type="text"
        :label="$t('element.property.image.caption')"
        :clearable="false"
          />
    </div>

    <div v-if="OrgName !== orgNameFreshWork" class="divider mt-3" />
    <div v-else class="mt-3" />
    <div v-if="OrgName !== orgNameFreshWork" class="row">
      <div
        v-if="OrgName !== orgNameFreshWork"
        class="col align-items-center text-capitalize"
      >
        {{ $t('element.property.image.url') }}
      </div>
      <div v-if="OrgName !== orgNameFreshWork" class="col-12">
        <url-input
          v-model="src"
          :placeholder="$t('element.property.image.hyperlink.alt')"
          autofocus
        />
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-center">
        <cvt-button
          v-bind="removeBtnProps"
          shape="rounded"
          @click.stop.prevent="clearImage"
        />
      </div>
      <div class="col d-flex justify-content-center">
        <cvt-button
          v-bind="replaceBtnProps"
          shape="rounded"
          @click.stop.prevent="openAssetManager('replaceButton')"
        />
      </div>
    </div>
    <div v-if="OrgName !== orgNameFreshWork" class="divider mt-3" />
    <div v-else class="mt-3" />
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
FRESHWORKS,
LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import DragMarker from '../../spectrum/DragMarker.vue'

export default {
  components: {
    DragMarker,
  },
  props: {
    imageKey: {
      type: String,
      required: true,
    },
    captionKey: {
      type: String,
      required: false,
      default: 'caption'
    },
    backgroundImgPosition: {
      type: Object,
      default() {},
      required: false,
    },
    hook: {
      type: Function,
      required: true,
    },
    canChangePostion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overlayVisible: false,
      mode: 'LIGHT',
      orgNameFreshWork: FRESHWORKS,
      backgroundImgMarkerInitialPosition: null,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    src: {
      get() {
        return _.get(this.selected, this.imageKey.replaceAll('/', '.'))
      },
      set: _.debounce(function (value) {
        return this.updateProp({ key: this.imageKey, value: value })
      }, 1000),
    },
     caption: {
      get() {
       return  _.get(this.selected, this.captionKey.replaceAll('/', '.'))
      },
      set: _.debounce(function ({target}) {
        return this.updateProp({ key: this.captionKey, value: target.value })
      }, 800),
    },
    assetMgrBtnText() {
      return this.src
        ? this.$t('element.property.image.replace')
        : this.$t('element.property.image.add')
    },
    removeBtnText() {
      return this.OrgName === FRESHWORKS
        ? this.$t(`element.property.image.remove`) + ' Image'
        : this.$t(`element.property.image.remove`)
    },
    replaceBtnText() {
      return this.OrgName === FRESHWORKS
        ? this.$t('element.property.image.replace') + ' Image'
        : this.$t('element.property.image.replace')
    },
    removeBtnProps() {
      return {
        mode: this.mode,
        text: this.removeBtnText,
        fullWidth: !this.isFreshworksOrg,
        outlined: this.isFreshworksOrg,
        color: this.isFreshworksOrg ? 'light' : 'danger',
        size: this.isFreshworksOrg ? 'sm' : '',
      }
    },
    replaceBtnProps() {
      return {
        mode: this.mode,
        text: this.replaceBtnText,
        fullWidth: !this.isFreshworksOrg,
        outlined: this.isFreshworksOrg,
        color: this.isFreshworksOrg ? 'LIGHT' : 'primary',
        size: this.isFreshworksOrg ? 'sm' : '',
        specialStyle: this.isFreshworksOrg ? 'dark' : '',
      }
    },
    isBackgroundImageAdded() {
      return this.src ? true : false
    },
    isBlockElement() {
      return _.get(this, 'selected.name') === 'Block'
    },
    canRepositionImage() {
      // Image can be repositioned for below, background image( block or cointainer ) and for grid builder only
      return (
        this.isGridBuilder && (this.isBlockElement || this.canChangePostion)
      )
    },
    isFreshworksOrg() {
      return this.OrgName === FRESHWORKS
    },
  },
  mounted() {
    this.setBackgroundImgMarkerInitialPosition()
  },

  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
      initAssetManager: actions.ASSET_MANAGER_INIT,
    }),
    openAssetManager(source) {
      if (this.isGridBuilder) {
        if (
          source === 'imageThumbnail' &&
          this.isBackgroundImageAdded === false
        ) {
          this.initAssetManager(this.hook)
        } else if (source === 'replaceButton') {
          this.initAssetManager(this.hook)
        }
      } else {
        this.initAssetManager(this.hook)
      }
    },
    clearImage() {
      this.hook()
      this.src = ''
    },
    showOverlay() {
      this.overlayVisible = true
    },
    hideOverlay() {
      this.overlayVisible = false
    },
    setBackgroundImgMarkerInitialPosition() {
      if (this.canRepositionImage) {
        if (
          this.backgroundImgPosition &&
          this.backgroundImgPosition.rawPos &&
          this.backgroundImgPosition.rawPos.x != null &&
          this.backgroundImgPosition.rawPos.y != null
        ) {
          this.backgroundImgMarkerInitialPosition = {
            left: `${this.backgroundImgPosition.rawPos.x}px`,
            top: `${this.backgroundImgPosition.rawPos.y}px`,
          }
        } else {
          this.backgroundImgMarkerInitialPosition = {
            left: `110px`,
            top: `90px`,
          }
        }
      }
    },
    positionMove(value) {
      return this.$emit('backgroundImgPositionChanged', {
        eventType: 'mouseDrag',
        eventData: value,
      })
    },
    captureClickAndMouseWheelEvent(e) {
      let position = {
        x: e.offsetX,
        y: e.offsetY,
      }

      this.$emit('backgroundImgPositionChanged', {
        eventType: 'mouseClick',
        eventData: { position: position },
      })

      this.$refs.backgroundImagePositioner.updatePosition(position)
      this.$refs.backgroundImagePositioner.startDrag(e)
    },
  },
}
</script>
<style lang="scss">
@import '@/scss/utils';
.element-image-manager {
  .element-image-manager__preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 180px;
    img {
      max-height: 200px;
    }
    &__overlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
      background: rgba(41, 46, 55, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      // display: none;
      // transition: all 0.3s ease;
      cursor: pointer;
    }

    &.checkered {
      text-overflow: clip;
      background: linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.0980392) 25%,
          rgba(0, 0, 0, 0) 25%,
          rgba(0, 0, 0, 0) 75%,
          rgba(0, 0, 0, 0.0980392) 75%,
          rgba(0, 0, 0, 0.0980392) 0
        ),
        linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.0980392) 25%,
          rgba(0, 0, 0, 0) 25%,
          rgba(0, 0, 0, 0) 75%,
          rgba(0, 0, 0, 0.0980392) 75%,
          rgba(0, 0, 0, 0.0980392) 0
        ),
        rgb(255, 255, 255);
      background-position: 0 0, 10px 10px;
      background-origin: padding-box;
      background-clip: border-box;
      background-size: 20px 20px;
    }
  }

  .color-spectrum__marker {
    background-color: #5d62ab;
  }
}
</style>

<template>
  <el-dialog :visible.sync="visible" size="tiny" @close="handleClose">
    <span slot="title" class="save-block-title">
      {{
        $t('editor.left_nav.pages.page_actions_istemplate.popover.save_page')
      }}
    </span>
    <div class="row">
      <el-input
        v-model="pageName"
        class="col-12 mb-2"
        :placeholder="
          $t('element.property.save_page.input.page_name_placeholder')
        "
        autofocus
      />
      <el-input
        v-model="pageThumbnail"
        class="col-12 mb-2"
        :placeholder="
          $t('element.property.save_page.input.thumbnail_placeholder')
        "
      />

      <el-select
        v-model="pageTag"
        class="col-12"
        filterable
        allow-create
        :placeholder="
          $t('element.property.save_page.input.page_tags_placeholder')
        "
      >
        <el-option
          v-for="tag in pageTags"
          :key="tag"
          :label="tag"
          :value="tag"
        />
      </el-select>
    </div>

    <span slot="footer" class="dialog-footer">
      <div class="row">
        <cvt-switch
          class="col-1"
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="false"
          @switchToggle="(e) => savePageGlobally(e)"
        />
        <span class="col-2">{{
          $t(
            'editor.left_nav.pages.page_actions_istemplate.popover.save_page_globally',
          )
        }}</span>
        <div class="col-9 mr-0">
          <el-button @click="handleClose">{{
            $t('element.property.cta.cancel')
          }}</el-button>
          <el-button type="primary" :loading="loading" @click="savePage">{{
            $t('element.property.cta.confirm')
          }}</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import * as _ from 'lodash'
import * as getters from '../../../store/modules/getters'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
      pageName: '',
      pageThumbnail: '',
      pageTag: '',
      loading: false,
      globalSavePageFlag: false,
      pageTags: [
        'Thank you',
        'Opt-in',
        'Sales Page',
        'Checkout Page',
        'Upsell Page',
        'Downsell Page',
        'Content Page',
        'Survey',
        'Webinar',
        ' Webinar Registration',
        ' Webinar Live',
        ' Webinar Replay',
        ' Webinar Sales',
        ' Application Page',
        ' Calendar Invite Page',
      ],
    }
  },
  computed: {
    ...mapState({
      site: ({ site }) => _.get(site, 'site'),
      theme: ({ theme }) => theme,
    }),
    ...mapState('pageManager', {
      page: 'page',
      pageObject: 'pageObject',
      visible: 'saveVisible',
    }),
    ...mapGetters({
      vdom: getters.DELTA_VDOM,
    }),
    isGridBuilder() {
      const version = _.get(this.site, 'version')
      return ['v2', 'v3'].includes(version)
    },
    themeColors() {
      return {
        ...this.theme.colors,
        ...this.theme.blockColors,
      }
    },
  },
  methods: {
    ...mapMutations('pageManager', {
      handleClose: 'closeSave',
    }),
    ...mapActions('pageManager', {
      savePageToFirebase: 'savePageToFirebase',
    }),
    async savePage() {
      this.loading = true
      const paletteColors = this.getPaletteColorsFromCurrentSite()

      const pageProps = {
        pageName: this.pageName,
        pageThumbnail: this.pageThumbnail,
        pageTag: this.pageTag,
        global: this.globalSavePageFlag,
        isGridBuilder: this.isGridBuilder,
        meta: {
          anchors: {},
          colors: paletteColors,
          clonedFromSiteRef: this.site.firebaseRef,
          clonedFromPageRef: this.pageObject.firebaseRef,
        },
      }

      try {
        await this.savePageToFirebase({ pageProps })
      } finally {
        this.loading = false
        this.handleClose()
      }
    },
    savePageGlobally(e) {
      if (e === 'Active') this.globalSavePageFlag = true
      else if (e === 'Inactive') this.globalSavePageFlag = false
    },
    getPaletteColorsFromCurrentSite() {
      let paletteColors = {}

      this.vdom.forEach((block) => {
        const themeColorIds = this.getColorIdsFromChildElements(block, (n) => n.data?.props?.themeColorId)
        const overlayColorIds = this.getColorIdsFromChildElements(block, (n) =>  n.data?.props?.background?.coloroverlay)
        const backgroundShapeColorIds = this.getColorIdsFromChildElements(block, (n) => n.data?.props?.bgShape?.fillColor)

        const mergedColors = _.merge(
          themeColorIds,
          overlayColorIds,
          backgroundShapeColorIds,
        )
        let colors = _.chain(this.themeColors)
          .pick(mergedColors)
          .mapValues((c) => ({
            a: c.alpha(),
            hex: c.hex().toLowerCase(),
          }))
          .value()
        paletteColors = { ...paletteColors, ...colors }
      })

      return paletteColors
    },
    getColorIdsFromChildElements(block, selectCallback) {
      let colorIds = []
      const blockChildren = block?.children || []

      colorIds = _.chain(blockChildren)
        .findAllRecurs((n) => selectCallback(n))
        .map((n) => selectCallback(n))
        .value()

      if (selectCallback(block)) {
        colorIds.unshift(selectCallback(block))
      }
      return colorIds
    },
  },
}
</script>

<style lang="scss"></style>

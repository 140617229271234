<script lang='jsx'>
import CPopupBuilder from './editor/nodes/CPopupBuilder.vue'
import CPopupBuilderV3 from './v3/editor/nodes/CPopupBuilder.vue'
import * as getters from '@/js/store/modules/getters'
import {mapGetters} from "vuex";
export default {
  name: 'PopUpRenderer',
  components: { CPopupBuilder: CPopupBuilder, CPopupBuilderV3:CPopupBuilderV3 },
  props: {
    popupPath: {
      type: String,
      required: true,
    },
    pageEngineWrapperClass: {
      type: Array,
      required: true,
    },
    isPopupOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      builderVersion: getters.BUILDER_VERSION
    })
  },
  methods: {},
  render (h) {
    const parentClassWrapperData = {
      class: this.isPopupOpen ? [this.pageEngineWrapperClass, 'page-engine-wrapper-cpopup', 'typography', {'grid-builder': this.builderVersion === 'v3'}] : ''
    }
    const componentName = this.builderVersion === 'v3'?'CPopupBuilderV3': 'CPopupBuilder'
    return (
      <div {...parentClassWrapperData}>
        {h(componentName, {
          props: {
            ['.path']: this.popupPath,
          },
        })}
      </div>
    )
  },
}
</script>

<style lang="scss" scoped>
.page-engine-wrapper-cpopup{
  background: #06061666;
  height: 100%;
}
</style>


<div class="color-spectrum">
  <div class="color-spectrum__wrapper">
    <img
      class="color-spectrum__palette"
      :src="spectrum"
      alt="spectrum"
      draggable="false"
      @load="init"
    />
    <div
      class="color-spectrum__barrier"
      :style="cursor"
      @mousedown="captureFromWheelEvent"
    >
      <drag-marker
        ref="colorMarker"
        classes="color-spectrum__marker"
        containment
        @dragmove="colorMove"
      ></drag-marker>
    </div>
  </div>
  <div
    class="color-spectrum__value"
    :style="darknessSliderBg"
    @mousedown="captureFromValueEvent"
  >
    <drag-marker
      ref="darknessMarker"
      classes="color-spectrum__marker color-spectrum__marker--slider"
      containment
      @dragmove="darknessMove"
    ></drag-marker>
  </div>

  <div class="color-spectrum__code">
    <div class="color-spectrum__preview" :style="previewBg"></div>

    <div class="color-spectrum__hex-wrapper">
      <small>{{ $t('element.properties.color.hex') }}:</small>
      <input v-model="hex" type="text" class="color-spectrum__hex" />
      <input
        :value="darkness.toFixed(2)"
        type="number"
        class="color-spectrum__hex"
        style="margin: 3px 0"
        step="0.01"
        min="0"
        max="1"
        @input="onChangeDarkness"
      />
    </div>
  </div>
</div>

<template>
  <cvt-sections
    class="make-scrollable"
    :sections="filteredBlocks"
    :section-tags="tags"
    :loading="loading"
    :active-tag="activeTag"
    :type="$attrs.type"
    :category="'POPUP'"
    @close-section="() => toggleLeftSideMenu({ open: false })"
    @insert-block="(block) => insertPopupBlock(block)"
    @insert-blank="insertBlank"
    @filter-section="(value) => activeTag = value"
  />
</template>

<script>
import 'intersection-observer'

import * as _ from 'lodash'
import anime from 'animejs'
import { database } from '@/js/store/services/firebase.service'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { assignIdsRecursive, Block, GridContainer, MARKETPLACE_PATH, Row } from "@/js/lib/helper-classes";
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'

let db = database()

export default {

  data () {
    return {
      activeTag: 'POPUP',
      query: null,
      blocks: [],
      loading: true,
      tags: []
    }
  },
  computed: {
    ...mapGetters({
      user: getters.AUTH_GET_USER,
      config: getters.AUTH_GET_USER_CONFIG,
      popupIdx: getters.POPUP_IDX,
    }),

    isGridBuilder () {
      return this.config.THEME.gridBuilder || false
    },

    filteredBlocks () {
      if (this.isGridBuilder) {
        return _.filter(this.blocks, b => {
          return this.activeTag && this.activeTag === 'ALL' ? _.get(b, 'isGridBuilder') : _.get(b, 'isGridBuilder') && _.includes(b.tags, this.activeTag)
        })
      }

      return this.activeTag && this.activeTag !== 'ALL' ? _.filter(this.blocks, b => {
        return _.includes(b.tags, this.activeTag)
      }) : this.blocks
    }
  },

  created () {
    this.loadFirebaseData()
  },

  methods: {
    ...mapActions({
      quickLaunchBlockInsert: actions.QUICK_LAUNCH_BLOCK_INSERT,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      addBlockColors: actions.THEME_ADD_COLORS_FROM_BLOCK,
      popupInsertBlock: actions.POPUP_BUILDER_INSERT_BLOCK,
      popupDeletePremade: actions.POPUP_DELETE_PREMADE,
      togglePopup: actions.POPUP_BUILDER_TOGGLE,
    }),

    ...mapMutations({
      setPopupEmpty: mutations.SET_POPUP_EMPTY
    }),
    async loadFirebaseData() {
      const { orgID } = this.user
      const { MARKETPLACE_PATH } = this.config

      const paths = [
        { path: `${MARKETPLACE_PATH}/${orgID}`, child: 'blocks' },
        { path: `${MARKETPLACE_PATH}/${orgID}/${orgID}`, child: `blocks`},
        {path: MARKETPLACE_PATH, child: `globalBlocks`}, // add global blocks to list
      ]

      paths.forEach((item, pathIndex) => {
        db.ref(item.path).child(item.child).once('value', (snap) => {
          let blocks = snap.val()
          let tags = []
          for (let key in blocks) {
            let block = blocks[key]

            if ( _.includes(block.tags, 'POPUP') ) {
              this.blocks.push({
                ...block,
                '.key': key
              })
              if (block.tags) {
                block.tags.forEach(tag => {
                  tags[tag.toUpperCase()] = true
                })
              }
            }
          }

          tags = Object.keys(tags)
          tags.unshift('ALL')
          this.tags = _.sortedUniq(tags)
          this.loading = false
        });
      })
    },

    // observeImage (el) {
    //   console.log(this.observer)
    //   // this.observer.observe(el)
    // },

    insertBlank () {
      let block = new Block()
      if (this.config.THEME.gridBuilder) {
        block.children.push(new GridContainer())
      } else {
        block.children.push(new Row())
      }
      this.insertBlock({ vnode: block })
    },
    insertBlock ({ colors, vnode }) {
      this.addBlockColors(colors)
      this.quickLaunchBlockInsert(assignIdsRecursive(vnode))
      this.$nextTick(_ => {
        let node = document.getElementById(vnode.data.props.vnodeId)
        this.scrollTo(node)
        this.shakeNode(node)
        this.toggleLeftSideMenu({ open: false })
      })
    },
    scrollTo (e) {
      if (_.isFunction(e.scrollIntoViewIfNeeded)) {
        e.scrollIntoViewIfNeeded(true)
      } else {
        e.scrollIntoView({ behavior: 'smooth' })
      }
    },
    shakeNode (targets, delay = 0) {
      let animation = anime({
        targets,
        duration: 500,
        delay,
        translateY: [0, 50, -50, 0],
        easing: 'easeInOutQuart'
      })

      return animation.finished.then(_ => {
        targets.style.transform = null
      })
    },
    insertPopupBlock (block) {
      let blockColors = block.colors || {} 
      this.addBlockColors(blockColors)

      this.setPopupEmpty(false)
      this.popupInsertBlock({block, idx: this.popupIdx})
    },
    deletePopup (block, idx) {
      this.popupDeletePremade({block})
      this.blocks.splice(idx, 1);
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/utils";
  .block-gallery {
    width: 20rem;

    .el-select {
      width: 100%;
    }
  }

  .block-gallery__results {
    overflow-x: hidden;
    width: 100%;
    text-transform: capitalize;
  }

  .block-gallery__result__scroll {
    overflow-y: scroll;
    height: 75vh;
    width: 100%;
  }

  .block-gallery__result {
    img {
      height: 191px;
    }
  }

  .make-scrollable {
    overflow: scroll;
  }
</style>

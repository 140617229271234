import * as _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import PageList from '../../../common/PageList.vue'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
import {
  DATE_AND_TIME_BASED,
  URGENCY_BASED,
  COUNTRIES_TIMEZONES_DATA,
} from '../../../../common/constants/constants'

export default {
  components: {
    PageList,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      themeDesignsData: [
        {
          designTitle: 'themeOne',
          class: this.selectedThemeOneClasses,
          imgUrl: 'https://i.imgur.com/fjy91J0.png',
        },
        {
          designTitle: 'themeTwo',
          class: this.selectedThemeTwoClasses,
          imgUrl: 'https://i.imgur.com/CXTZSBe.png',
        },
        {
          designTitle: 'themeThree',
          class: this.selectedThemeThreeClasses,
          imgUrl: 'https://i.imgur.com/Ew6KRgI.png',
        },
      ],
      countriesTimezonesData: COUNTRIES_TIMEZONES_DATA,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
      config: getters.AUTH_GET_USER_CONFIG,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
      viewMode: getters.VIEW_MODE,
      headerDisabled: getters.HEADER_DISABLED,
      footerDisabled: getters.FOOTER_DISABLED,
    }),
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    countdownTimerType: {
      get() {
        return _.get(this, 'selected.type', DATE_AND_TIME_BASED)
      },
      set(value) {
        return this.updateProp({ key: 'type', value })
      },
    },
    themeStyle: {
      get() {
        return _.get(this, 'selected.themeStyle', 'themeOne')
      },
      set(value) {
        return this.updateProp({ key: 'themeStyle', value })
      },
    },
    endDate: {
      get() {
        return _.get(this, 'selected.endDate', '')
      },
      set(value) {
        return this.updateProp({ key: 'endDate', value })
      },
    },
    urgencyBasedTimeInMin: {
      get() {
        return _.get(this, 'selected.urgencyBasedTimeInMin', 0)
      },
      set(value) {
        return this.updateProp({
          key: 'urgencyBasedTimeInMin',
          value: Number(value),
        })
      },
    },
    urgencyBasedTimeInSec: {
      get() {
        return _.get(this, 'selected.urgencyBasedTimeInSec', 0)
      },
      set(value) {
        let valueInSec = Math.floor(Number(value) * 60)
        return this.updateProp({
          key: 'urgencyBasedTimeInSec',
          value: valueInSec,
        })
      },
    },
    urgencyBasedTimeInHour: {
      get() {
        return _.get(this, 'selected.urgencyBasedTimeInHour', 0)
      },
      set(value) {
        let valueInHour = Math.floor(Number(value) / 60)
        return this.updateProp({
          key: 'urgencyBasedTimeInHour',
          value: valueInHour,
        })
      },
    },
    urgencyBasedTimeInDay: {
      get() {
        return _.get(this, 'selected.urgencyBasedTimeInDay', 0)
      },
      set(value) {
        let valueInDay = Math.floor(Number(value) / (60 * 24))
        return this.updateProp({
          key: 'urgencyBasedTimeInDay',
          value: valueInDay,
        })
      },
    },
    minEndDate() {
      return new Date()
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')
    },
    endTime: {
      get() {
        return _.get(this, 'selected.endTime', '')
      },
      set(value) {
        return this.updateProp({ key: 'endTime', value })
      },
    },
    minEndTime() {
      return new Date()
        .toLocaleString('en-us', {
          hour12: false,
          hour: 'numeric',
          minute: 'numeric',
        })
        .replace(/(\d+):(\d+)/, '$1:$2')
    },
    shortEndTime: {
      get() {
        return _.get(this, 'selected.shortEndTime', 0).toString()
      },
      set(value) {
        return this.updateProp({ key: 'shortEndTime', value: value })
      },
    },
    timeZoneOffset: {
      get() {
        return _.get(this, 'selected.timeZoneOffset', '+00:00')
      },
      set(value) {
        return this.updateProp({ key: 'timeZoneOffset', value })
      },
    },
    expireAction: {
      get() {
        return _.get(this, 'selected.expireAction', 'redirect_to_url')
      },
      set(value) {
        return this.updateProp({ key: 'expireAction', value })
      },
    },
    expireActionMessage: {
      get() {
        return _.get(this, 'selected.expireActionMessage', '')
      },
      set(value) {
        return this.updateProp({ key: 'expireActionMessage', value })
      },
    },
    expireActionSelectedPage: {
      get() {
        return _.get(this, 'selected.expireActionPageId', '')
      },
      set(value) {
        return this.updateProp({ key: 'expireActionPageId', value })
      },
    },
    expireActionRedirectionUrl: {
      get() {
        return _.get(this, 'selected.expireActionRedirectionUrl', '')
      },
      set(value) {
        return this.updateProp({ key: 'expireActionRedirectionUrl', value })
      },
    },
    endDateValidationStatus() {
      return this.isEndDateValid(this.endDate, this.endTime)
        ? 'valid'
        : 'invalid'
    },
    endTimeValidationStatus() {
      return this.isEndTimeValid(this.endDate, this.endTime)
        ? 'valid'
        : 'invalid'
    },
    selectedThemeOneClasses() {
      return {
        'cdt-theme-selected': this.themeStyle === 'themeOne',
      }
    },
    selectedThemeTwoClasses() {
      return {
        'cdt-theme-selected': this.themeStyle === 'themeTwo',
      }
    },
    selectedThemeThreeClasses() {
      return {
        'cdt-theme-selected': this.themeStyle === 'themeThree',
      }
    },
    dateAndTimeBasedTimer(): boolean {
      return this.countdownTimerType === DATE_AND_TIME_BASED
    },
    urgencyBasedTimer(): boolean {
      return this.countdownTimerType === URGENCY_BASED
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    isEndDateValid(endDate: string, endTime: string) {
      if (endDate) {
        const todayDate = new Date()
        todayDate.setHours(0, 0, 0, 0) // setting the hours, minutes, seconds and mili-seconds to the start of the day

        if (Date.parse(endDate) >= todayDate.getTime()) {
          return true
        }
      }
      return false
    },
    isEndTimeValid(endDate: string, endTime: string) {
      if (endDate && endTime) {
        const savedDateTime = `${endDate} ${endTime}`
        if (Date.parse(savedDateTime) >= Date.now()) {
          return true
        }
      }
      return false
    },
    updateUrgencyBasedTime(key: string, value: string) {
      this.updateCountdownOptions(key, value)
      this.updateCountdownOptions('urgencyBasedTimeInDay', value)
      this.updateCountdownOptions('urgencyBasedTimeInHour', value)
      this.updateCountdownOptions('urgencyBasedTimeInMin', value)
      this.updateCountdownOptions('urgencyBasedTimeInSec', value)
    },
    updateCountdownOptions(key: string, value: string) {
      this[key] = value
    },
  },
}

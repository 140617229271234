import { getColorName } from '../lib/color-name-generator'

function generateRandomHex() {
  return (
    '#' +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')
  )
}

export function generateGlobalStyleColors(count = 12) {
  const colors = {}

  for (let i = 1; i <= count; i++) {
    const hex = generateRandomHex()
    colors[`color${i}`] = {
      hex,
      displayName: getColorName(hex),
    }
  }

  return colors
}

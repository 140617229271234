import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import { getBuilderServiceClass } from '../services/builderClassFactory'
import {
  BuilderUndoRedoManger,
  BuilderUndoRedoMangerV3,
} from '../services/builderUndoRedoManager'
import { PopUpUndoRedoManagerV3 } from '@/js/store/services/popUpUndoRedoManager'

class StateBuilderUndoRedoManger {
  constructor() {
    // initialize the builderManager instance
    this._builderUndoRedoManager = null
    this._popUndoRedoManager = null
  }

  get currentUndoRedoManager() {
    // this property is use as a means to common actions
    // canUndo, canRedo, RedoHandle and UndoHandle actions
    // setting states to each undoRedoManger are managed individually

    if (this._popUndoRedoManager) {
      return this._popUndoRedoManager
    }
    return this._builderUndoRedoManager
  }
  get builderUndoRedoManager() {
    return this._builderUndoRedoManager
  }

  set builderUndoRedoManager(value) {
    this._builderUndoRedoManager = value
  }

  set popUndoRedoManager(popUndoRedoManager) {
    this._popUndoRedoManager = popUndoRedoManager
  }

  get popUndoRedoManager() {
    return this._popUndoRedoManager
  }
}

export default {
  state: new StateBuilderUndoRedoManger(),
  getters: {
    [getters.HISTORY_CAN_UNDO](state, _, rootState) {
      if (rootState.loader.visible) {
        return false
      }
      return state.currentUndoRedoManager
        ? state.currentUndoRedoManager.canUndo
        : false
    },
    [getters.HISTORY_CAN_REDO](state, _, rootState) {
      if (rootState.loader.visible) {
        return false
      }
      return state.currentUndoRedoManager
        ? state.currentUndoRedoManager.canRedo
        : false
    },
    [getters.HISTORY_INITIALIZED](state, _, rootState) {
      if (rootState.loader.visible) {
        return false
      }
      return state.currentUndoRedoManager
        ? state.currentUndoRedoManager.initialized
        : false
    },
    [getters.HISTORY_MANAGER](state) {
      return state.currentUndoRedoManager
    },
  },
  mutations: {
    [mutations.HISTORY_DELTA_REF_PUSH](state, currentVersion) {
      if (state.builderUndoRedoManager === null) return
      state.builderUndoRedoManager.historyDeltaRefPush(currentVersion)
    },
    [mutations.HISTORY_VIEW_MODE_PUSH](
      state,
      { previousViewMode, currentViewMode },
    ) {
      if (state.builderUndoRedoManager === null) return
      state.builderUndoRedoManager.viewModeChanged(
        currentViewMode,
        previousViewMode,
      )
    },
    [mutations.HISTORY_LISTEN](state) {
      if (state.builderUndoRedoManager === null) return
      state.builderUndoRedoManager.listen = true
    },
    [mutations.HISTORY_IGNORE](state) {
      if (state.builderUndoRedoManager === null) return
      state.builderUndoRedoManager.listen = false
    },
  },
  actions: {
    async [actions.HISTORY_GRIDBUILDER_POPUP](
      { state, dispatch, rootState, commit },
      popUpBuilder,
    ) {
      if (!popUpBuilder) {
        state.popUndoRedoManager?.unsubscribe()
      }
      await state.builderUndoRedoManager.historyPopupBuilderActive(
        popUpBuilder,
        rootState.viewmode.mode,
      )
    },
    async [actions.HISTORY_SET_POPUP_MANAGER]({ state }, popUndoRedoManager) {
      popUndoRedoManager?.subscribe()
      state.popUndoRedoManager = popUndoRedoManager
    },
    [actions.HISTORY_SET_INITIAL_REV](
      { state, dispatch, commit, rootState },
      currentVersion,
    ) {
      if (state.builderUndoRedoManager === null) {
        const _builderUndoRedoManagerClass = getBuilderServiceClass(
          rootState,
          BuilderUndoRedoMangerV3,
          BuilderUndoRedoManger,
        )
        state.builderUndoRedoManager = new _builderUndoRedoManagerClass(
          dispatch,
          commit,
        )
      }
      state.currentUndoRedoManager.historyInitialRev(
        currentVersion,
        commit,
        dispatch,
      )

      window.addEventListener(
        'keydown',
        (e) => {
          const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
          const commandKeyPressed = isMac ? e.metaKey : e.ctrlKey

          if (commandKeyPressed && !e.shiftKey && e.keyCode === 90) {
            dispatch(actions.HISTORY_APPLY, 'undo')
          }

          if (commandKeyPressed && e.shiftKey && e.keyCode === 90) {
            dispatch(actions.HISTORY_APPLY, 'redo')
          }
        },
        {
          passive: true,
        },
      )
    },
    async [actions.HISTORY_COMMAND_PUSH]({ state }, command) {
      const currentManager = state.currentUndoRedoManager
      if (
        currentManager instanceof BuilderUndoRedoMangerV3 ||
        currentManager instanceof PopUpUndoRedoManagerV3
      ) {
        await state.currentUndoRedoManager.pushCommand(command)
      }
    },
    async [actions.HISTORY_COMMAND_UNDO]({ state }, levels = 1) {
      await state.currentUndoRedoManager.redoHandler(levels)
    },
    async [actions.HISTORY_COMMAND_REDO]({ state }, levels = 1) {
      await state.currentUndoRedoManager.undoHandler(levels)
    },
    async [actions.HISTORY_APPLY]({ state }, action) {
      await state.currentUndoRedoManager.apply(action)
    },
  },
}

<script lang="jsx">
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as getters from '../store/modules/getters'
import * as mutations from '../store/modules/mutations'
import LeftSideBar from './editor/left-side-menu/LeftSideBar.vue'
import MainNav from '@/js/components/common/MainNav.vue'
import Loader from '@/js/components/Loader.vue'
import { cvtRouteNodes } from "@/js/utils/routes"


export default {
  name: 'SitePreview',
  components: {
    LeftSideBar,
    MainNav,
    Loader
  },
  props: {
    page : {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapGetters({
      site: getters.SITE_GET_DATA,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
      viewMode: getters.VIEW_MODE,
      activeSitePreviewPageId:getters.SITE_PREVIEW_PAGE_ID,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    previewIframeClasses() {
      return {
        'preview-iframe': true,
        'desktop': this.viewMode === 'desktop',
        'mobile': this.viewMode === 'phone',
      }
    },
    pagePreviewUrl() {
      return `${this.$props.page.previewUrl}?previewMode=true&mode=${this.viewMode}`
    }
  },
  watch: {
    viewMode: {
      immediate: true,
      handler(newVal) {
        this.$mount()
      },
    },
  },
  mounted() {
    this.stopLoader()
  },
  methods: {
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop'
    }),
    ...mapActions('globalTheme', {
      setTheme: 'setTheme',
      setTopNavConfig: 'setTopNavConfig',
      setLeftNavConfig: 'setLeftNavConfig',
      setMarketplaceConfig: 'setMarketplaceConfig',
      setIconConfig: 'setIconConfig',
      setGlobalButtonConfig: 'setGlobalButtonConfig',
      setGlobalCheckBoxConfig: 'setGlobalCheckBoxConfig',
    }),
    ...mapMutations({
      setViewMode: mutations.VIEW_MODE_SET
    }),
    onExitPreviewMode () {
      const { siteId, pageId } = this.$route.params;
      this.setViewMode('desktop')
      this.$router.push({
        name: cvtRouteNodes.editorDesign,
        params: {
          siteId: siteId,
          pageId: pageId,
        }
      })
    }
  },
  render(h) {

    return (
      <div identity="SitePreview">
        <loader />
        <main-nav />
        <left-side-bar isSitePreview={true} />
        <div class="preview-wrapper">
          <cvt-alert-with-button
            alertBtnText={this.$t('site.preview.alert_btn_text')}
            alertMessage={this.$t('site.preview.alert_message')}
            onExitPreviewMode={this.onExitPreviewMode}
          />
          <iframe
            class={this.previewIframeClasses}
            key={this.pagePreviewUrl}
            src={this.pagePreviewUrl}
            frameborder={0}
          />
        </div>
      </div>
    )
  },
}
</script>

<style lang="scss" scoped>
  @import './SitePreview/SitePreview.scss';
</style>
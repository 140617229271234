<template>
  <div :key="`spacing-${viewMode}-${direction}-${selected.vNodeID}`" class="spacing-sliders">
    <div class="row">
      <div class="col text-left">
        <div class="d-flex">
          <i  v-if="OrgName !== orgNameFreshWork" class="material-icons mr-1" v-text="icon"></i>
          <p><strong class="text-capitalize mr-1" v-text="title"></strong></p>
        </div>
        <p v-text="subtext"></p>
      </div>
    </div>

    <div v-if="OrgName === orgNameFreshWork">
      <div class="row">
        <div class="col">
        <cvt-radio-group orientation='horizontal' :initial-value="space" :remove-active="removeActive">
          <cvt-radio-button size="md" shape="pill" :value='fmtKey(1)' @radioClick="updateSpace">
            XS
          </cvt-radio-button>
          <cvt-radio-button size="md" shape="pill" :value='fmtKey(2)' @radioClick="updateSpace">
            SM
          </cvt-radio-button>
          <cvt-radio-button size="md" shape="pill" :value='fmtKey(3)' @radioClick="updateSpace">
            MD
          </cvt-radio-button>
          <cvt-radio-button size="md" shape="pill" :value='fmtKey(4)' @radioClick="updateSpace">
            LG
          </cvt-radio-button>
          <cvt-radio-button size="md" shape="pill" :value='fmtKey(5)' @radioClick="updateSpace">
            XL
          </cvt-radio-button>
        </cvt-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <cvt-button
            :outlined="true"
            :text="btnText"
            shape="pill"
            color= "light"
            :mode= "mode"
            size="md" 
            @click.stop="remove"
          />
        </div>
      </div>
    </div>
    
    <div v-else>
      <div class="row">
        <div class="col">
        <cvt-radio-group orientation='horizontal' :initial-value="space" :remove-active="removeActive">
          <cvt-radio-button size="md" :value='fmtKey(1)' @radioClick="updateSpace">
            XS
          </cvt-radio-button>
          <cvt-radio-button size="md" :value='fmtKey(2)' @radioClick="updateSpace">
            SM
          </cvt-radio-button>
          <cvt-radio-button size="md" :value='fmtKey(3)' @radioClick="updateSpace">
            MD
          </cvt-radio-button>
          <cvt-radio-button size="md" :value='fmtKey(4)' @radioClick="updateSpace">
            LG
          </cvt-radio-button>
          <cvt-radio-button size="md" :value='fmtKey(5)' @radioClick="updateSpace">
            XL
          </cvt-radio-button>
        </cvt-radio-group>
        </div>
      </div>

      <div v-if="showRemove" class="row">
        <div class="col">
          <cvt-button
          :outlined="true"
          :text="$t(`elements.properties.spacing.${attr.toLowerCase()}.remove`)"
          :full-width="true"
          shape="rounded"
          color= "light"
          :mode= "mode"
          @click.stop="remove"
        />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import * as _ from 'lodash'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../../../storybook/components/constants'

  const SIDE_KEY_MAP = {
    padding: {
      vertical: 'py',
      horizontal: 'px'
    },
    margin: {
      vertical: 'my',
      horizontal: 'mx'
    }
  }

  const ICON_MAP = {
    margin: 'border_outer',
    padding: 'border_inner'
  }


  export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "Spacing",
    props: {
      attr: {
        type: String,
        required: true,
        validator (val) {
          return ['margin', 'padding'].includes(val)
        }
      },
      direction: {
        type: String,
        required: true,
        validator (val) {
          return ['vertical', 'horizontal'].includes(val)
        }
      },
      hideTitle: {
        type: Boolean,
        default: false
      },
      showRemove: Boolean
    },
    data() {
      return {
        mode: 'LIGHT',
        removeActive: false,
        orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS
      }
    },
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED,
        viewMode: getters.VIEW_MODE,
        config: getters.AUTH_GET_USER_CONFIG,
        isGridBuilder: getters.SITE_HAS_GRIDBUILDER
      }),
      ...mapState('globalTheme', {
        OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD
      }),
      title () {
        return this.attr === 'margin'
            ? this.$t('elements.properties.spacing.margin.message')
            : this.$t('elements.properties.spacing.padding.message')
      },
      space: {
        get () {
          if (this.isGridBuilder) {
            return _.get(this.selected['spacingMP'], [this.viewMode, this.attr, this.direction], '')
          } else {
            return this.selected[this.attr][this.direction]
          }
        },
        set (value) {
          return value
        }
      },
      icon () {
        return ICON_MAP[this.attr]
      },
      sideKey () {
        return SIDE_KEY_MAP[this.attr][this.direction]
      },
      subtext () {
        return this.$t(`elements.properties.spacing.${this.attr.toLowerCase()}.${this.direction.toLowerCase()}`)
      },
      btnText () {
        return 'No '+ this.attr.toLowerCase()
      },
      sizes () {
        return [
          { label: 'XS', value: `${this.sideKey}-1` },
          { label: 'SM', value: `${this.sideKey}-2` },
          { label: 'MD', value: `${this.sideKey}-3` },
          { label: 'LG', value: `${this.sideKey}-4` },
          { label: 'XL', value: `${this.sideKey}-5` }
        ]
      }
    },
    methods: {
      ...mapActions({
        updateProp: actions.SELECTOR_UPDATE_PROP
      }),
      fmtKey (level) {
        return `${this.sideKey}-${level}`
      },
      remove () {
        if (this.isGridBuilder) {
          this.updateProp({
            key: `marginPadding/${this.viewMode}/${this.attr}/${this.direction}`,
            value: ''
          }).then(() => {
            this.selected['spacingMP'][this.viewMode][this.attr][this.direction] = ''
          })
        } else {
          this.updateProp({
            key: this.attr,
            value: {
              vertical: this.selected[this.attr].vertical || '',
              horizontal: this.selected[this.attr].horizontal || '',
              [this.direction]: ''
            }
          })
        }
        this.space = ''
        this.removeActive = true
      },
      updateSpace (value) {
       this.removeActive = false
        this.space = value

        // defining this for backward compatibility
        let spacing = {
          desktop: {
            padding: {
              vertical: _.get(this.selected?.marginPadding, ['desktop', 'padding', 'vertical'], ''),
              horizontal: _.get(this.selected?.marginPadding, ['desktop', 'padding', 'horizontal'], ''),
            },
            margin: {
              vertical: _.get(this.selected?.marginPadding, ['desktop', 'margin', 'vertical'], ''),
              horizontal: _.get(this.selected?.marginPadding, ['desktop', 'margin', 'horizontal'], ''),
            }
          },
          phone: {
            padding: {
              vertical: _.get(this.selected?.marginPadding, ['phone', 'padding', 'vertical'], ''),
              horizontal: _.get(this.selected?.marginPadding, ['phone', 'padding', 'horizontal'], ''),
            },
            margin: {
              vertical: _.get(this.selected?.marginPadding, ['phone', 'margin', 'vertical'], ''),
              horizontal: _.get(this.selected?.marginPadding, ['phone', 'margin', 'horizontal'], ''),
            }
          }
        }

        spacing[this.viewMode][this.attr][this.direction] = value

        if (this.isGridBuilder) {
          this.updateProp({
            key: `marginPadding`, 
            value: spacing
          }).then(() => {
            this.selected['spacingMP'][this.viewMode][this.attr][this.direction] = value
          })
        } else {
          this.updateProp({
            key: `${this.attr}/${this.direction}`,
            value
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  .spacing-sliders {
    h6 {
      text-transform: capitalize;
    }
  }
  .button-margin {
    margin: 8px;
  }
</style>

<template>
  <div class="colors">
    <div class="d-flex flex-wrap">
      <div
        :id="`tooltip-${uniqueId}`"
        class="theme-color"
        :style="colorToStyle(defaultTextColor)"
      ></div>
    </div>

    <cvt-tooltip
      ref="tooltip"
      :show="showTooltip"
      :delay="20"
      :no-fade="true"
      :placement="'left'"
      :color="'light'"
      :mode="'LIGHT'"
      :boundary-padding="0"
      :inner-padding="0"
      :triggers="'click'"
      :target="`tooltip-${uniqueId}`"
    >
      <div class="tooltip-wrapper">
        <div
          v-for="(color, key) in colors"
          :key="key"
          class="theme-color"
          :style="colorToStyle(color)"
          @click.stop="applyColor(color, key)"
        ></div>
      </div>
    </cvt-tooltip>
  </div>
</template>

<script>
import Vue from 'vue'
import color from 'color'
import uuid from 'uuid/v4'
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../js/store/modules/actions'
import * as getters from '../../../js/store/modules/getters'

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '#414141',
    },
  },
  data() {
    return {
      showTooltip: false,
      uniqueId: uuid(),
    }
  },
  computed: {
    ...mapGetters({
      colors: getters.THEME_GET_COLORS,
    }),
    defaultTextColor() {
      return color(this.value)
    },
  },
  methods: {
    ...mapActions({
      updateThemeColor: actions.THEME_UPDATE_COLOR,
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    applyColor(colorId, key) {
      this.showTooltip = true
      this.$nextTick((_) => {
        this.showTooltip = false
      })
      this.$emit('input', color(colorId).hex())
    },
    colorToStyle(color) {
      return {
        backgroundColor: color.hsl().toString(),
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/scss/utils';

.tooltip-wrapper {
  padding: 20px;
  max-width: 275px;
  border: 1px solid #e2e6ed;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.theme-color {
  transition: all 100ms ease;
  transform: scale(1);

  width: 3rem;
  height: 3rem;

  box-shadow: inset 2px 1px 0 rgba(0, 0, 0, 0.05),
    inset -2px -1px 0 rgba(0, 0, 0, 0.05);

  cursor: pointer;
  user-select: none;

  display: inline-block;
  position: relative;

  margin: 5px;
  border-radius: 5px;

  &--active {
    outline: unset;
    z-index: 2;
  }

  &:active {
    transform: scale(0.95);
  }
}
</style>

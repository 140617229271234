<template>
  <div>
    <flex-align
      v-if="
        isGridBuilder &&
        selected.parent().name === 'GridItemWrapper' &&
        !hideFlexAlignment
      "
      :mode="mode"
      :disable-alignment="hideFlexAlignment"
      :disable-constrain="hideFlexAlignment"
    ></flex-align>
    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <cvt-collapse-item
        :title="$t('elements.properties.settings')"
        name="settings"
        :initial-active="true"
        :special-style="isSpecialStyle"
      >
        <div class="row">
          <div class="col">
            <icon-manager></icon-manager>
          </div>
        </div>
        <div v-if="OrgName !== orgNameFreshWork" class="divider"></div>
        <br v-if="OrgName === orgNameFreshWork" />
        <div class="row">
          <div class="col">
            <h6 :class="textColorClass" class="m-0">Resize</h6>
          </div>
          <div class="col-12">
            <resize
              size-key="size"
              :min="1"
              :step="1"
              :max="20"
              :hide-label="true"
            ></resize>
          </div>
        </div>
        <div v-if="OrgName !== orgNameFreshWork" class="divider"></div>
        <br v-if="OrgName === orgNameFreshWork" />

        <flex-align v-if="!isGridBuilder" :mode="mode"></flex-align>

        <div v-if="OrgName !== orgNameFreshWork" class="divider"></div>
        <br v-if="OrgName === orgNameFreshWork" />

        <div class="row">
          <div v-if="isGridBuilder" class="col-12">
            Background
            <colors :to-bg="true" :mode="mode"></colors>
          </div>
          <div class="col-12">
            Foreground
            <colors :mode="mode"></colors>
          </div>
        </div>

        <div v-if="isGridBuilder" class="divider mt-2" />
        <div v-if="isGridBuilder" class="nestedInnerItem">
          <cvt-select
            :value="borderStyle"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            :label="$t('elements.properties.border.style')"
            @input="(value) => updateBorderStyle(value)"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px" />
          <cvt-input
            v-model="borderColor"
            style="width: max-content"
            type="text"
            :clearable="false"
            :label="$t('elements.properties.border.color')"
          />
        </div>

        <div v-if="isGridBuilder" class="divider mt-2" />

        <div v-if="isGridBuilder" class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.width') }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="1"
              :max="10"
              :value="borderWidth"
              :step="1"
              @change="updateBorderWidth"
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div v-if="isGridBuilder" class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.radius') }}
          </div>
          <div class="col-12 mb-3">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="0"
              :max="100"
              :value="borderRadius"
              :step="5"
              @change="updateBorderRadius"
            />
          </div>
        </div>

        <element-anchor disable-alt-text></element-anchor>
      </cvt-collapse-item>

      <cvt-collapse-item
        :title="$t('elements.properties.spacing.label')"
        name="spacing"
        :special-style="isSpecialStyle"
      >
        <spacing-ctrl></spacing-ctrl>
      </cvt-collapse-item>
    </cvt-collapse>
  </div>
</template>

<script>
import * as _ from 'lodash'
import Resize from './Resize.vue'
import SpacingCtrl from './SpacingCtrl.vue'
import FlexAlign from './FlexAlign.vue'
import Colors from '../theme/Colors.vue'
import IconManager from './IconManager.vue'
import ElementAnchor from './ElementAnchor.vue'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
export default {
  components: {
    Resize,
    SpacingCtrl,
    FlexAlign,
    Colors,
    IconManager,
    ElementAnchor,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'settings',
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
      borderStyles: [
        'none',
        'solid',
        // 'ridge',
        // 'inset',
        // 'outset',
        'dashed',
        'dotted',
        'double',
        'groove',
      ],
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    borderStyle: {
      get() {
        return this.selected.parent().name === 'GridItemWrapper'
          ? this.selected.parent().borderStyle || 'none'
          : 'none'
      },
      set: _.throttle(function (value) {
        return this.selected.parent().updateProp('borderStyle', value)
      }, 300),
    },
    borderColor: {
      get() {
        return this.selected.parent().name === 'GridItemWrapper'
          ? this.selected.parent().borderColor
          : null
      },
      set: _.throttle(function ({ target }) {
        if (this.selected.parent().name === 'GridItemWrapper') {
          return this.selected.parent().updateProp('borderColor', target.value)
        }

        return
      }, 300),
    },
    hideFlexAlignment() {
      return this.config.HIDE_FLEX_ALIGNMENT_OPTIONS?.icon === true
    },
  },
  created() {
    this.radius = this.selected.radius
    if (this.selected.parent().name === 'GridItemWrapper') {
      this.borderWidth = this.selected.parent().borderWidth || 0
      this.borderRadius = this.selected.parent().borderRadius || 0
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    updateBorderStyle(value) {
      if (this.selected.parent().name === 'GridItemWrapper') {
        this.borderStyle = value
      }
    },
    updateBorderWidth: _.throttle(function (value) {
      if (this.selected.parent().name !== 'GridItemWrapper') return

      this.borderWidth = value
      return this.selected.parent().updateProp('borderWidth', value)
    }, 300),
    updateBorderRadius: _.throttle(function (value) {
      if (this.selected.parent().name !== 'GridItemWrapper') return

      this.borderRadius = value
      return this.selected.parent().updateProp('borderRadius', value)
    }, 300),
  },
}
</script>

<style lang="scss"></style>

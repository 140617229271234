<template>
  <GenAILayout class="intent-selection">
    <template #nav>
      <cvt-top-nav-bar
        mode="LIGHT"
        :route="genAISelectTemplate"
        variant="embedded"
        :class="'cvt-top-nav-bar'"
        template-name="Let's get started!"
        :on-genai-wizard="true"
        :fixed="true"
        :light-logo="logo"
        :dark-logo="logo"
        :type="orgName"
        @switch-preview="switchViewMode"
        @use-template="() => useTemplate()"
        @back="navigateToChooseATemplate"
      />
    </template>

    <cvt-left-side-bar class="marketplace-left-nav-bar intent-sidebar">
      <div>
        <cvt-button
          :disabled="loading"
          class="back-btn"
          text=""
          icon="cvt-icon-arrow-left-05"
          @click="goBack"
        />

        <div class="mt-4">
          <p class="mb-2">
            {{ $t('element.property.theme.brand.preference') }}
          </p>

          <cvt-select
            :key="brandingThemeList.length"
            class="w-100 mb-4 select-template-select"
            mode="dropdown"
            :background-mode="mode"
            :multiple="false"
            :value="selectedBrandTheme"
            :placeholder="'Select one'"
            :select-radius="'0'"
            @input="(value) => onSelectBrandTheme(value)"
          >
            <option
              v-for="data in brandingThemeList"
              :key="data.idx"
              :value="data.idx"
              v-text="data.brandingName"
            ></option>

            <optgroup :key="'...'" :label="'...'">
              <option
                v-for="newTheme in ['+ Create new theme']"
                :key="newTheme"
                :value="newTheme"
                v-text="newTheme"
              ></option>
            </optgroup>
          </cvt-select>

          <div class="sidebar-horizontal-line"></div>
        </div>

        <div v-if="selectedBrandThemeData">
          <div>
            <p class="mt-4" style="font-weight: 700">
              {{ $t('editor.left_nav.element.logo') }}
            </p>

            <p>
              <img
                style="height: 50px"
                :src="selectedBrandThemeData.brandLogo"
                alt="brand-logo"
              />
            </p>
          </div>

          <div>
            <p class="mt-4" style="font-weight: 700">
              {{ $t('element.property.color.palette') }}
            </p>
            <p class="mt-n3">
              {{ $t('element.property.branding.primary.colors') }}
            </p>

            <div class="d-flex flex-wrap mt-n2">
              <div
                v-for="(color, idx) in selectedBrandThemeData.theme.colors"
                :key="idx"
                class="sidebar-color-palette"
                :style="colorToStyle(color)"
              ></div>
            </div>
          </div>

          <div>
            <p class="mt-4" style="font-weight: 700">
              {{ $t('element.property.typography') }}
            </p>

            <div class="mt-n3 mb-3">
              <div class="mb-n2">
                Header -
                <span :style="selectedThemeTypography.headerStyles">{{
                  selectedThemeTypography.headerStyles['font-family']
                }}</span>
              </div>
              <div>
                Body -
                <span :style="selectedThemeTypography.bodyStyles">{{
                  selectedThemeTypography.bodyStyles['font-family']
                }}</span>
              </div>
            </div>

            <div class="sidebar-horizontal-line"></div>
          </div>
        </div>

        <div
          v-else
          class="d-flex justify-content-center align-items-center"
          style="height: 280px"
        >
          <p style="font-weight: 600">
            {{ $t('element.property.branding.no.theme.selected') }}
          </p>
        </div>

        <cvt-button
          :loading="loading"
          class="mt-4 mb-5 magic-btn"
          :text="$t('gen_ai.template.continue_btn')"
          icon="cvt-icon-arrow-refresh-06"
          :prepend="true"
          @click="switchTemplate"
        />

        <div v-if="showCycleLoader" class="select-template-footer">
          <div class="select-template-footer-box">
            <div>
              <div class="d-flex align-items-center">
                <img :src="genAILoadCycle" alt="load" />
                <div
                  v-if="showDynamicContent"
                  class="ml-3 font-weight-bold fade-text"
                  :class="{ 'fade-in': showDynamicContent }"
                  style="line-height: 18px"
                >
                  Analyzing Your Business Goals
                </div>
                <div
                  v-else
                  class="ml-3 font-weight-bold fade-text"
                  :class="{ 'fade-out': !showDynamicContent }"
                  style="line-height: 18px"
                >
                  Writing Content For Your Page
                </div>
              </div>

              <div
                v-if="showDynamicContent"
                class="mt-3 fade-text"
                :class="{ 'fade-in': showDynamicContent }"
              >
                We're currently processing your selected business goals and
                applying our methodology and research from 10,000 experiments
              </div>
              <div
                v-else
                class="mt-3 fade-text"
                :class="{ 'fade-out': !showDynamicContent }"
              >
                Choose your Blueprint Template and you'll move onto to the next
                step, where you'll see your personalized page ready to be
                reviewed and edited.
              </div>
            </div>
          </div>
        </div>
      </div>
    </cvt-left-side-bar>
    <div class="content-wrapper position-relative">
      <div v-if="loading" class="working-on-block">
        <div class="working-on-block__container">
          <h3 class="loader-message" style="font-family: 'Nunito'">
            {{ loadingMessage }}
          </h3>
        </div>
      </div>
      <div class="w-100, h-100">
        <div
          :class="{
            content: true,
            'd-none': currentAPIData.previewURL === '',
            mobile: viewMode !== 'desktop',
          }"
        >
          <iframe
            :key="currentAPIData.previewURL"
            :src="currentAPIData.previewURL"
            frameborder="0"
            @load="iframeLoaded"
          />
        </div>
      </div>
    </div>

    <cvt-dialog
      :show="modalVisible"
      :hide-borders="true"
      :width="'90%'"
      @close="onCloseModal"
    >
      <div class="integrations-wrapper">
        <marketplace-branding />
      </div>
    </cvt-dialog>
  </GenAILayout>
</template>

<script>
import genAILoadCycle from "../../../../static/img/gen-ai-load-cycle.gif";
import { cvtRouteNodes, cvtRoutes } from "../../utils/routes";
import { getGenAITemplate, getSessionItemGenAIOnboarding } from "./utils";
import { isNavigationFailure, NavigationFailureType } from "vue-router";
import { mapActions, mapGetters, mapState } from "vuex";
import * as actions from "@/js/store/modules/actions";
import { Notification } from "element-ui";
import { Marketplace, V1DynamicContent, V1Sites } from "@/js/store/services/api";
import color from "color";
import * as getters from "@/js/store/modules/getters";

import GenAILayout from "./baseLayout.vue";
import MarketplaceBranding from "../marketplace/branding/index.vue";

const dcAPI = new V1DynamicContent()
const sites = new V1Sites()
const marketplace = new Marketplace()

export default {
  name: 'SelectTemplate',
  components: {
    GenAILayout,
    MarketplaceBranding,
  },
  props: {
    // Your props here
  },
  data() {
    const { genAISelectTemplate } = cvtRouteNodes
    return {
      templates: [
        'This is a template',
        'This is another template',
        'Another template here',
      ],
      mode: 'LIGHT',
      activeTemplate: 0,
      loading: false,
      genAISelectTemplate,
      currentAPIData: {
        name: '',
        pageId: '',
        siteId: '',
        siteRef: '',
        state: '',
        thumbnail: '',
        previewId: '',
        previewURL: '',
      },
      viewMode: 'desktop',
      loadingMessage: 'Please Wait',
      genAiTemplateList: [],
      usedTemplates: [],
      brandThemeArray: [
        'Convrrt',
        'Infusionsoft',
        'Integrate',
        'Instamojo',
        'SendInBlue',
      ],
      selectedBrandTheme: '',
      selectedBrandThemeData: null,
      modalVisible: false,
      brandingThemeList: null,
      showDynamicContent: false,
      intervalId: null, // Storing the interval ID for cleanup
      genAILoadCycle,
      showCycleLoader: false,
    }
  },
  computed: {
    // Your computed properties here
    ...mapGetters({
      theme: getters.AUTH_GET_USER_BUILDER_THEME,
      brandingData: getters.MP_GET_BRANDS,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) => globalTheme.OrgName,
    }),
    navColor() {
      if (this.theme.color) {
        const colorPrefix = [3, 6].includes(this.theme.color.length) ? '#' : ''
        return color(`${colorPrefix}${this.theme.color}`)
      }
      return ''
    },
    logo() {
      return this.navColor && this.navColor.dark()
        ? this.theme.logoAlt
        : this.theme.logo
    },
    selectedThemeTypography() {
      if (
        !this.selectedBrandThemeData ||
        !this.selectedBrandThemeData.theme ||
        !this.selectedBrandThemeData.theme.typography
      ) {
        return null
      }
      const { typography } = this.selectedBrandThemeData.theme
      return {
        headerStyles: {
          'font-family': typography.heading.font,
          'font-size': '20px',
          color: typography.heading.defaultTextColor,
          'font-weight': typography.heading.weight,
          'text-transform': 'capitalize',
        },
        bodyStyles: {
          'font-family': typography.normalText.font,
          'font-size': '20px',
          color: typography.normalText.defaultTextColor,
          'font-weight': typography.normalText.weight,
          'text-transform': 'capitalize',
        },
      }
    },
  },
  watch: {
    brandingData: {
      handler(newBrandingData) {
        this.brandingThemeList = newBrandingData
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.initBrandManager()
    this.initialContentLoading()
    this.genAiTemplateList = []

    this.intervalId = setInterval(this.toggleHelperBoxText, 4000)
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    ...mapActions({
      mixpanelTrack: actions.MIXPANEL_TRACK,
      initBrandManager: actions.MP_INIT_BRAND_MANAGER,
    }),
    goBack() {
      this.$router.push(cvtRoutes.genAIIntent.path)
    },
    startLoading(value, message = 'Please Wait...') {
      const cycleLoaderAllowed = [
        'Regenerating Template...',
        'Rendering Template...',
      ]
      if (cycleLoaderAllowed.includes(message)) {
        this.showCycleLoader = value
      } else {
        this.showCycleLoader = false
      }

      this.loading = value
      this.loadingMessage = message
    },
    async switchTemplate() {
      try {
        this.startLoading(true, 'Regenerating Template...')

        if (!this.genAiTemplateList.length) {
          const selectedIntentGoal = getSessionItemGenAIOnboarding('intentGoal')
          const selectedIntent = getSessionItemGenAIOnboarding('intent')

          const params = {
            intent: [selectedIntent],
            intentGoal: [selectedIntentGoal],
          }
          this.genAiTemplateList = await marketplace.genAiTemplates(params)
          this.usedTemplates = []
        }

        if (this.genAiTemplateList.length) {
          let availableTemplates = this.genAiTemplateList.filter(
            (template) => !this.usedTemplates.includes(template.pageId),
          )
          if (!availableTemplates.length) {
            // If all templates have been used, reset the usedTemplates list
            this.usedTemplates = []
            availableTemplates = [...this.genAiTemplateList]
          }

          let selectedTemplate =
            availableTemplates[
              Math.floor(Math.random() * availableTemplates.length)
            ]
          while (
            selectedTemplate.pageId === this.currentAPIData.pageId &&
            availableTemplates.length > 1
          ) {
            selectedTemplate =
              availableTemplates[
                Math.floor(Math.random() * availableTemplates.length)
              ]
          }

          this.usedTemplates.push(selectedTemplate.pageId)
          const previewURL = `${window.location.origin}/api/v1/sites/${selectedTemplate.siteId}/preview/${selectedTemplate.pageId}`

          if (selectedTemplate.pageId === this.currentAPIData.pageId) {
            return this.startLoading(false)
          }

          // Update the currentAPIData
          this.currentAPIData = Object.assign(
            {},
            this.currentAPIData,
            selectedTemplate,
            {
              previewURL,
              previewId: selectedTemplate.siteKey,
            },
          )
          this.addBrandingToPreviewUrl(true)
        } else {
          this.startLoading(false)
          return Notification({
            title: 'No Template',
            message: 'No templates found for that intent',
            type: 'info',
            position: 'top-right',
          })
        }
      } catch (err) {
        this.startLoading(false)
        throw err
      }
    },
    async onRegenerate() {
      this.showCycleLoader = true
      await this.switchTemplate()
      this.showCycleLoader = true
    },
    iframeLoaded() {
      this.checkIframeLoaded()
    },
    checkIframeLoaded() {
      const iframe = this.$el.querySelector('iframe')
      if (
        iframe &&
        iframe.contentDocument &&
        iframe.contentDocument.readyState === 'complete'
      ) {
        this.startLoading(false)
      } else {
        setTimeout(() => {
          this.checkIframeLoaded()
        }, 2000)
      }
    },
    async createSiteFromPreviewID(previewId) {
      const req = dcAPI.selectTemplate({ previewId: previewId })
      // const { data } = resp
      // const { id } = data
      return await req.catch((err) => {
        console.error('failed to create site', err)
        const { response = {} } = err
        const { data } = response
        if (data && data.errors) {
          for (const e of data.errors) {
            Notification({
              title: e.code || 'woops',
              message: e.message,
              type: 'error',
              position: 'bottom-right',
            })
          }
        }
        throw err
      })
    },
    switchViewMode(currentSelected) {
      if (!this.currentAPIData.previewURL) return
      this.viewMode = currentSelected
      this.startLoading(true, 'Switching View Mode...')
      if (currentSelected === 'desktop') {
        this.currentAPIData = {
          ...this.currentAPIData,
          previewURL: dcAPI.getPreviewURLAlt(this.currentAPIData.previewURL),
        }
        this.startLoading(false)
      } else {
        this.currentAPIData = {
          ...this.currentAPIData,
          previewURL: dcAPI.getPreviewURLAlt(
            this.currentAPIData.previewURL,
            true,
          ),
        }
      }
    },
    printError(message) {
      Notification({
        title: 'woops',
        message: message,
        type: 'error',
        position: 'bottom-right',
      })
    },
    async useTemplate() {
      const selectedIntentGoal = getSessionItemGenAIOnboarding('intentGoal')
      const selectedIntent = getSessionItemGenAIOnboarding('intent')
      const description = getSessionItemGenAIOnboarding('businessDescription')
      const industry = getSessionItemGenAIOnboarding('industry')

      const params = {
        businessDescription: description,
        businessSummary: industry,
        intent: selectedIntent,
        intentGoal: selectedIntentGoal,
      }

      if (this.currentAPIData.previewId !== '' && !this.loading) {
        this.startLoading(true, 'Switching to Content Builder...')

        try {
          const siteResp = await sites.fromGenAiTemplate(
            this.currentAPIData.siteId,
            params,
          )

          // Find the page with isIndex set to true
          // const indexPage = siteResp.pages.find((page) => page.isIndex)
          // const pageRef = indexPage ? indexPage.firebaseRef : null

          // await sites.generateSiteContent({
          //   intent: selectedIntent,
          //   intentGoal: selectedIntentGoal,
          //   pageRef: pageRef,
          // })
          // Move to the wizard-editor
          this.$router.push({
            name: cvtRouteNodes.wizardEditorDesign,
            params: {
              siteId: siteResp.id,
            },
          })
          this.mixpanelTrack({
            event: 'Generative AI Template',
            category: 'gen-ai-templates',
            data: { template_id: siteResp.id },
          })
          this.startLoading(false)
        } catch (e) {
          this.startLoading(false)
          if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
            throw e
          }
        }
      } else {
        if (this.loading) {
          return Notification({
            title: 'In Progress',
            message: 'We are processing the template.. Please Wait.',
            type: 'info',
            position: 'bottom-right',
          })
        }
        this.printError('Onboarding did not follow due process')
      }
    },
    async initialContentLoading() {
      const template = getGenAITemplate()
      if (template) {
        this.startLoading(true, 'Rendering Template...')
        const templatesArray = JSON.parse(atob(template))
        if (templatesArray.length) {
          const firstTemplate = templatesArray[0]
          const previewURL = `${window.location.origin}/api/v1/sites/${firstTemplate.siteId}/preview/${firstTemplate.pageId}`
          this.currentAPIData = {
            ...firstTemplate,
            previewURL,
            previewId: firstTemplate.siteKey,
          }
          this.usedTemplates.push(firstTemplate.pageId)
          return
        } else {
          this.startLoading(false)
          return Notification({
            title: 'No Template',
            message: 'No templates found for that intent',
            type: 'info',
            position: 'top-right',
          })
        }
      }
      this.printError('Onboarding did not follow due process')
    },
    navigateToChooseATemplate() {
      this.$router.push({
        name: cvtRouteNodes.chooseAtemplate,
      })
    },
    onSelectBrandTheme(value) {
      this.selectedBrandTheme = value
      if (this.selectedBrandTheme === '+ Create new theme') {
        return (this.modalVisible = true)
      }

      this.addBrandingToPreviewUrl()
      this.selectedBrandThemeData = this.brandingThemeList.find(
        (theme) => theme.idx === this.selectedBrandTheme,
      )
    },
    async onCloseModal() {
      await this.initBrandManager()
      this.modalVisible = false
    },
    colorToStyle(color) {
      return {
        backgroundColor: color.hex,
      }
    },
    toggleHelperBoxText() {
      this.showDynamicContent = !this.showDynamicContent
    },
    addBrandingToPreviewUrl(isRegenerate = false) {
      if (!this.selectedBrandTheme) return
      let url = new URL(this.currentAPIData.previewURL)
      url.searchParams.delete('brandingId')
      url.searchParams.append('brandingId', this.selectedBrandTheme)
      this.currentAPIData.previewURL = url.toString()

      !isRegenerate && this.startLoading(true, 'Applying Theme...')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/IntentSelection.style.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.select-template-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  width: 315px;
  margin-top: 200px;
  margin-bottom: 20px;
  color: #494c53;
  margin-left: -7px;

  .select-template-footer-box {
    background-color: #fff;
    border-radius: 16px;
    height: 200px;
    line-height: normal;
  }

  .select-template-footer-box > div {
    margin: 24px 20px;
  }

  .fade-text {
    animation-duration: 6s;
    animation-fill-mode: both;
  }

  .fade-in {
    animation-name: fadeIn;
  }

  .fade-out {
    animation-name: fadeOut;
  }
}
</style>

<template>
  <div
    v-show="open"
    class="right-click-menu"
    :style="style"
    @mouseover.stop.prevent="highlightTargetedElement"
  >
    <div
      v-for="(action, idx) in actions"
      :key="action.label + idx"
      class="right-click-menu__option"
      @click.stop.prevent="execute(action)"
    >
      <i class="material-icons" v-text="action.icon" />
      <span class="right-click-label-shortcut-wrap">
        <span class="right-click-label">
          {{ action.label }}
        </span>

        <span v-if="isWindows || isLinux" :id="'right-click-shortcut-key-win'+ idx" class="right-click-shortcut-key">
          <span>{{ action.shortcutKeyWin }}</span>
          <cvt-tooltip ref="right-click-shortcut-key-win" :target="'right-click-shortcut-key-win'+ idx"> {{ action.shortcutKeyWinTooltip }}</cvt-tooltip>
        </span>

        <span v-if="isMac" :id="'right-click-shortcut-key-mac'+ idx" class="right-click-shortcut-key">
          <span>{{ action.shortcutKeyMac }}</span>
          <cvt-tooltip ref="right-click-shortcut-key-mac" :target="'right-click-shortcut-key-mac'+ idx">{{ action.shortcutKeyMacTooltip }}</cvt-tooltip>
        </span>

      </span>
    </div>
  </div>
</template>

<script>
  import * as getters from '../../store/modules/getters'
  import * as actions from '../../store/modules/actions'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
        actions: [
          { 
            label: this.$t('layout.manage.duplicate'), 
            icon: 'content_copy', 
            method: 'duplicate', 
            shortcutKeyWin: this.$t('shortcut.key.win.duplicate.short_label'),
            shortcutKeyMac: this.$t('shortcut.key.mac.duplicate.short_label'),
            shortcutKeyWinTooltip: this.$t('shortcut.key.win.duplicate.full_label'),
            shortcutKeyMacTooltip: this.$t('shortcut.key.mac.duplicate.full_label')
          },
          { 
            label: this.$t('layout.manage.delete'), 
            icon: 'delete_forever', 
            method: 'remove', 
            shortcutKeyWin: this.$t('shortcut.key.win.delete.short_label'),
            shortcutKeyMac: this.$t('shortcut.key.mac.delete.short_label'),
            shortcutKeyWinTooltip: this.$t('shortcut.key.win.delete.full_label'),
            shortcutKeyMacTooltip: this.$t('shortcut.key.mac.delete.full_label')
          },
        ]
      }
    },
    computed: {
      ...mapGetters({
        open: getters.RIGHT_CLICK_MENU_OPEN,
        position: getters.RIGHT_CLICK_MENU_POSITION,
        selected: getters.SELECTOR_SELECTED,
        target: getters.RIGHT_CLICK_MENU_TARGET,
        config: getters.AUTH_GET_USER_CONFIG,
        version: getters.BUILDER_VERSION,
      }),
      style () {
        return {
          position: 'fixed',
          ...this.position
        }
      },
      isMac() {
        return navigator.userAgent.indexOf('Mac') >= 0
      },
      isWindows() { // for windows
        return navigator.userAgent.indexOf('Win') >= 0
      },
      isLinux() { // for linux
        return navigator.userAgent.indexOf('Linux') >= 0
      }
    },
    watch: {
      selected () {
        this.reset()
      },
    },
    destroyed () {
      window.removeEventListener('click', this.hideWhenClickedOutside, {passive: true})
    },
    mounted () {
      window.addEventListener('click', this.hideWhenClickedOutside, {passive: true})
    },
    methods: {
      ...mapActions({
        reset: actions.RIGHT_CLICK_MENU_RESET,
        mutateTargeted: actions.SELECTOR_TARGET
      }),
      execute (action) {
        switch (action.method) {
          case 'duplicate':
            if(this.version === 'v3') {
              // code for v3
              if(this.target?.name === 'Block') {
                this.target?.copySection()
              }else {
                this.target?.parent()?.duplicateElement()
              }
            } else { 
              // code for old-builder and v2
              this.target?.duplicateAndValidate()
            }
            break;
          case 'remove':
            if(this.version === 'v3') {
              // code for v3
              if(this.target?.name === 'Block') {
                this.target?.deleteSection()
              }else {
                this.target?.parent()?.removeElement()
              }
            } else { 
              // code for old-builder and v2
              this.target?.removeAndValidate()
            }
            break;
        }
        this.reset()
      },
      hideWhenClickedOutside (e) {
        if (this.open && e.target !== this.$el) {
          this.reset()
        }
      },
      highlightTargetedElement () {
        if(this.target) {
          this.mutateTargeted(this.target)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  .right-click-menu {
    width: 200px;
    max-height: 200px;
    z-index: 5;
    background: $inf-dark-blue;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
  }

  .right-click-shortcut-key {
    font-size: 12px;
  }

  .right-click-menu__option {
    cursor: pointer;
    color: $white;
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 17px;
    padding: 10px;

    .material-icons {
      font-size: 20px;
      position: unset;
      top: unset;
    }

    &:hover {
      color: $green;
    }

    .right-click-label-shortcut-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right-click-label {
        padding-left: 10px;
      }
    }

  }
</style>

import Editor from '@/js/components/Editor.vue'
import Maintenance from '@/js/components/Maintenance.vue'
import SitePreview from '@/js/components/SitePreview.vue'
import StripeConnect from '@/js/components/StripeConnect.vue'
import Branding from '@/js/components/admin/Branding.vue'
import Configurations from '@/js/components/admin/Configurations.vue'
import AdminDashboard from '@/js/components/admin/Dashboard.vue'
import Documentation from '@/js/components/admin/Documentation.vue'
import ReleaseNotes from '@/js/components/admin/ReleaseNotes.vue'
import Templates from '@/js/components/admin/Templates.vue'
import CheckoutContainer from '@/js/components/editor/checkout/CheckoutContainer.vue'
import LaunchStep from '@/js/components/editor/steps/Launch.vue'
import ChooseATemplate from '@/js/components/marketplace/ChooseATemplate.vue'
import LandingPageDescription from '@/js/components/genAi/LandingPageDescription.vue'
import ChooseATemplatePreview from '@/js/components/marketplace/ChooseATemplatePreview.vue'
import Playground from '@/js/components/playground/Playground.vue'
import PageEngineV3 from '@/js/components/v3/PageEngine.vue'
import WizardPageEngineV3 from '@/js/components/v3/wizard-page-engine/WizardPageEngine.vue'
import WizardEditor from '@/js/components/v3/wizard-page-engine/WizardEditor.vue'
import { safeLocalStorage } from '@/js/lib/localStorage'
import EnterpriseAdminRouteView from '@/js/modules/enterprise/AdminDashboardManager.vue'
import EnterpriseMoreInfo from '@/js/modules/enterprise/CollectMoreInfo/CollectMoreInfo.vue'
import EnterpriseDashboardBranding from '@/js/modules/enterprise/Dashboard/Branding/Branding.vue'
import EnterpriseDashboardConfigurations from '@/js/modules/enterprise/Dashboard/Configurations/Configurations.vue'
import EnterpriseDashboardOnboarding from '@/js/modules/enterprise/Dashboard/Dashboard.vue'
import EnterpriseDashboardIntegrations from '@/js/modules/enterprise/Dashboard/Integrations/Integrations.vue'
import EnterpriseDashboardUser from '@/js/modules/enterprise/Dashboard/Users/Users.vue'
import ErrorPage from '@/js/modules/enterprise/ErrorPage.vue'
import EnterpriseIBAuthCustomers from '@/js/modules/enterprise/IntegrateBuilder/AuthenticateCustomers/AuthenticateCustomers.vue'
import EnterpriseIBConfigDns from '@/js/modules/enterprise/IntegrateBuilder/ConfigDns/ConfigDns.vue'
import EnterpriseIBDnsAccess from '@/js/modules/enterprise/IntegrateBuilder/DnsAccess/DnsAccess.vue'
import EnterpriseIntegrateBuilder from '@/js/modules/enterprise/IntegrateBuilder/IntegrateBuilder.vue'
import EnterpriseIBInviteIT from '@/js/modules/enterprise/IntegrateBuilder/InviteIT/InviteIT.vue'
import EnterpriseIBSetCustomHost from '@/js/modules/enterprise/IntegrateBuilder/SetCustomHost/SetCustomHost.vue'
import EnterpriseIBFeatureConfiguration from '@/js/modules/enterprise/IntegrateBuilder/SetFeatureConfiguration/SetFeatureConfiguration.vue'
import EnterpriseIBVerifyCustomHostnames from '@/js/modules/enterprise/IntegrateBuilder/VerifyCustomHostnames/VerifyCustomHostnames.vue'
import EnterpriseBrandConfiguration from '@/js/modules/enterprise/brandConfiguration/BrandIndex.vue'
import EnterpriseSignup from '@/js/modules/enterprise/signup/indexSignup.vue'
import EnterpriseSmbOnboarding from '@/js/modules/enterprise/smbOnboarding/indexSmb.vue'
import EnterpriseCodeVerification from '@/js/modules/enterprise/verification/codeVerification.vue'
import PopupEditor from '@/js/modules/popup/editor/EditorIndex.vue'
import PopupSlideSettings from '@/js/modules/popup/layouts/PopupSettings.vue'
import PopupSlidePublish from '@/js/modules/popup/layouts/PublishLayout.vue'
import PopupSlideEngine from '@/js/modules/popup/layouts/SlideEngine.vue'
import PopupChooseATemplate from '@/js/modules/popup/marketplace/PopupChooseATemplate.vue'
import GenAiIntentGoalSelection from '@/js/components/genAi/IntentGoalSelection.vue'
import GenAISelectTemplate from '@/js/components/genAi/SelectTemplate.vue'
import SelectIndustry from '@/js/components/genAi/SelectIndustry.vue'
import LandingPageIntent from '@/js/components/genAi/LandingPageIntent.vue'
import * as actions from '@/js/store/modules/actions'
import * as getters from '@/js/store/modules/getters'
import * as mutations from '@/js/store/modules/mutations'
import { editSitePage, launchStep } from '@/js/store/modules/steps'
import { apiClient } from '@/js/store/services/ApiClient'
import { cvtRouteNodes, cvtRoutes } from '@/js/utils/routes'
import * as _ from 'lodash'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const popupRouteGuard = (config, next) => {
//   if (!config.STANDALONE_POPUP) {
//     // if STANDALONE_POPUP key is FALSE, then OrgID is not allowed to access STANDALONE_POPUP
//     // so we move then to Editor Choose a Template
//     next({ name: 'choose:a:template' })
//   }
// }

// In Vuejs new router version, 'Navigation-Errors' are classified as errors instead of warnings.
// This code block only ignore such Navigation redirection errors.

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  try {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location)
  } catch (error) {
    if (VueRouter.isNavigationFailure(error)) {
      console.debug('Navigation Error From Convrrt: ', error)
      return error
    }
    return Promise.reject(error)
  }
}

export default function Router(store) {
  const routes = [
    {
      path: cvtRoutes.campaign.path,
      name: cvtRoutes.campaign.name,
      redirect: cvtRoutes.campaign.redirect,
    },
    {
      path: cvtRoutes.playground.path,
      name: cvtRoutes.playground.name,
      component: Playground,
    },
    {
      path: cvtRoutes.maintenance.path,
      name: cvtRoutes.maintenance.name,
      component: Maintenance,
    },
    {
      path: cvtRoutes.campaignRedirect.path,
      beforeEnter(to, from, next) {
        if (
          [cvtRouteNodes.editorSetup, cvtRouteNodes.chooseAtemplate].includes(
            from.name,
          )
        ) {
          return next(false)
        }

        const query = _.clone(to.query)

        // construct redirectURL for campaign builder
        if (query.redirectUrl) {
          const newUrl = new URL(query.redirectUrl)

          query.redirectUrl = `${newUrl.protocol}//${newUrl.host}${newUrl.pathname}?funnelId=${to.query.funnelId}`
          if (query.redirectUrl !== '' || query.redirectUrl !== '/') {
            safeLocalStorage.setItem('SITE_REDIRECT_URL', query.redirectUrl)
          }
        }

        const siteId = query.siteId
        delete query.siteId

        store.dispatch(
          'loader/start',
          siteId
            ? 'loader.redirecting.to.builder.message'
            : 'loader.redirecting.to.marketplace.message',
        )

        next({
          name: siteId
            ? cvtRouteNodes.editorSetup
            : cvtRouteNodes.chooseAtemplate,
          query,
          params: {
            siteId,
          },
        })
      },
    },
    {
      path: cvtRoutes.editor.path,
      redirect: cvtRoutes.editor.redirect,
    },
    {
      name: cvtRoutes.editor.name,
      path: cvtRoutes.editor.paramPath,
      component: Editor,
      meta: {
        editor: true,
      },
      beforeEnter(to, from, next) {
        store.dispatch('loader/start', { msg: '', route: 'editor' })
        store
          .dispatch(actions.SITE_FETCH_DATA, to.params.siteId)
          .then((_) => {
            if (to.name === 'editor') {
              return next({
                name: cvtRouteNodes.editorSetup,
                query: to.query,
                params: to.params,
              })
            }

            next()
          })
          .catch((err) => {
            console.error(err)
            next(false)
          })
      },
      children: [
        {
          path: 'pages',
          redirect: { name: 'editor' },
        },
        {
          name: cvtRouteNodes.editorSetup,
          path: 'setup',
          redirect: {
            name: cvtRouteNodes.editorDesign,
          },
        },
        {
          name: cvtRouteNodes.editorDesign,
          path: cvtRouteNodes.design,
          beforeEnter(to, from, next) {
            const { id } = store.getters[getters.SITE_GET_INDEX]
            next({
              name: 'editor:design:page',
              params: {
                ...to.params,
                pageId: id,
              },
            })
          },
        },
        {
          name: 'editor:design:page',
          path: 'design/:pageId',
          alias: cvtRouteNodes.design,
          component: () => {
            const config = store.getters[getters.AUTH_GET_USER_CONFIG]
            const isGridBuilder = store.getters[getters.SITE_HAS_GRIDBUILDER]

            if (config.GRID_BUILDER_V3_DEFAULT_ROUTE && isGridBuilder) {
              return import('../components/v3/PageEngine.vue')
            } else {
              return import('../components/PageEngine')
            }
          },
          beforeEnter(to, from, next) {
            store.dispatch('loader/start', {
              msg: 'editor.loading.message',
              route: 'editor',
            })
            store.commit(mutations.STEPS_SET_STEP, editSitePage.id)
            next()
          },
          props(route) {
            const { pageId } = route.params

            const page = _.chain(store)
              .get('state.site.site.pages')
              .find({ id: pageId })
              .value()

            return {
              page,
              '.path': `${page.firebaseRef}/vdom`,
              popupPath: `${page.firebaseRef}/popup`,
            }
          },
          meta: {
            pageEngine: true,
          },
        },
        // for test v3 builder
        {
          name: cvtRouteNodes.editorDesignPage,
          path: 'design/:pageId/v3',
          alias: cvtRouteNodes.design,
          component: PageEngineV3,
          beforeEnter(to, from, next) {
            store.dispatch('loader/start', 'editor.loading.message')
            store.commit(mutations.STEPS_SET_STEP, editSitePage.id)
            next()
          },
          props(route) {
            const { pageId } = route.params

            const page = _.chain(store)
              .get('state.site.site.pages')
              .find({ id: pageId })
              .value()

            return {
              page,
              '.path': `${page.firebaseRef}/vdom`,
              popupPath: `${page.firebaseRef}/popup`,
            }
          },
          meta: {
            pageEngine: true,
          },
        },
        {
          name: cvtRouteNodes.editorLaunch,
          path: cvtRouteNodes.launch,
          component: LaunchStep,
          beforeEnter(to, from, next) {
            store.dispatch('loader/start', 'editor.loading.launch.step.message')
            store.commit(mutations.STEPS_SET_STEP, launchStep.id)
            next()
          },
        },
        // for checkout page mocking
        {
          name: cvtRoutes.checkoutPageDesign.name,
          path: cvtRoutes.checkoutPageDesign.path,
          component: CheckoutContainer,
          beforeEnter(to, from, next) {
            store.dispatch('loader/start', 'Loading Checkout Design')
            store.dispatch(actions.CHECKOUT_CONFIG_INIT)
            next()
          },
        },
      ],
    },
    {
      name: cvtRoutes.sitePreview.name,
      path: cvtRoutes.sitePreview.path,
      component: SitePreview,
      beforeEnter(to, from, next) {
        const config = store.getters[getters.AUTH_GET_USER_CONFIG]
        const userSessionData = store.getters[getters.PAGES_SESSION_DATA]

        if (config.SUBSCRIPTION_ENABLED && !userSessionData.user_subscribed) {
          return next({
            name: cvtRouteNodes.editorDesign,
            params: {
              siteId: to.params.siteId,
              pageId: to.params.pageId,
            },
          })
        }

        store.dispatch('loader/start')
        store
          .dispatch(actions.SITE_FETCH_DATA, to.params.siteId)
          .then((data) => next())
          .catch((err) => {
            console.error(err)
            next(false)
          })
      },
      props(route) {
        const { pageId } = route.params

        const page = _.chain(store)
          .get('state.site.site.pages')
          .find({ id: pageId })
          .value()

        return { page: page }
      },
    },
    {
      name: cvtRoutes.chooseATemplate.name,
      path: cvtRoutes.chooseATemplate.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: ChooseATemplate,
    },
    {
      name: cvtRoutes.aboutLandingPage.name,
      path: cvtRoutes.aboutLandingPage.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: LandingPageDescription,
    },
    {
      name: cvtRoutes.selectIndustry.name,
      path: cvtRoutes.selectIndustry.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: SelectIndustry,
    },
    {
      name: cvtRoutes.landingPageGoal.name,
      path: cvtRoutes.landingPageGoal.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: LandingPageIntent,
    },
    {
      name: cvtRoutes.chooseATemplatePreview.name,
      path: cvtRoutes.chooseATemplatePreview.path,
      alias: cvtRoutes.chooseATemplatePreview.alias,
      props: true,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: ChooseATemplatePreview,
    },
    {
      name: cvtRoutes.adminDashboard.name,
      path: cvtRoutes.adminDashboard.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: AdminDashboard,
    },
    {
      name: cvtRoutes.templates.name,
      path: cvtRoutes.templates.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: Templates,
    },
    {
      name: cvtRoutes.branding.name,
      path: cvtRoutes.branding.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: Branding,
    },
    {
      name: cvtRoutes.adminConfigurations.name,
      path: cvtRoutes.adminConfigurations.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: Configurations,
    },
    {
      name: cvtRoutes.adminDocumentation.name,
      path: cvtRoutes.adminDocumentation.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: Documentation,
    },
    {
      name: cvtRoutes.adminReleaseNotes.name,
      path: cvtRoutes.adminConfigurations.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      component: ReleaseNotes,
    },
    {
      name: cvtRoutes.stripeConnect.name,
      path: cvtRoutes.stripeConnect.path,
      component: StripeConnect,
    },
    // POPUP STANDALONE ROUTES
    {
      path: cvtRoutes.popup.path,
      redirect: cvtRoutes.popup.redirect,
    },
    {
      name: cvtRoutes.popupChooseATemplate.name,
      path: cvtRoutes.popupChooseATemplate.path,
      beforeEnter(to, from, next) {
        store.dispatch('loader/start', 'editor.loading.message')
        // const config = store.state.config.config
        // popupRouteGuard(config, next)
        store.dispatch('loader/stop')
        next()
      },
      component: PopupChooseATemplate,
    },
    {
      name: cvtRoutes.popupEditor.name,
      path: cvtRoutes.popupEditor.path,
      component: PopupEditor,
      meta: {
        editor: true,
      },
      beforeEnter(to, from, next) {
        store.dispatch('loader/start')
        // const config = store.state.config.config
        // popupRouteGuard(config, next)
        store.dispatch(actions.INTERCOM_INIT_FROM_CONFIG, 'POPUP_HELP_BUTTON')

        store
          .dispatch(actions.POPUP_FETCH_DATA, to.params.popupId)
          .then(() => next())
      },
      children: [
        {
          name: 'popup:editor:design',
          path: cvtRouteNodes.design,
          component: PopupSlideEngine,
          beforeEnter(to, from, next) {
            store.dispatch('loader/start', 'editor.loading.message')
            store.commit(mutations.STEPS_SET_STEP, editSitePage.id)
            const popup = store.getters[getters.SDA_POPUP_SITE_DATA]

            store.dispatch(actions.ACTIVATE_STANDALONE_BUILDER, true)
            store
              .dispatch(
                actions.SDA_POPUP_FIREBASE_INIT,
                `${popup.slide.firebaseRef}/vdom`,
              )
              .then(() => {
                next()
              })
          },
          props() {
            // TODO: save popup to state.popup.popup
            const popup = store.getters[getters.SDA_POPUP_SITE_DATA]

            return {
              '.path': store.getters[getters.SDA_POPUP_FIREBASE_PATH],
              popup: popup.slide,
              rootPath: store.getters[getters.SDA_POPUP_ROOTPATH],
            }
          },
          meta: {
            pageEngine: true,
          },
        },
        {
          name: cvtRouteNodes.popupEditorPublish,
          path: cvtRouteNodes.publish,
          component: PopupSlidePublish,
          props() {
            const popup = store.getters[getters.SDA_POPUP_SITE_DATA]
            return {
              popup,
            }
          },
        },
        {
          name: cvtRouteNodes.popupEditorSettings,
          path: cvtRouteNodes.settings,
          component: PopupSlideSettings,
          props() {
            const popup = store.getters[getters.SDA_POPUP_SITE_DATA]
            return {
              popupProps: popup.slide,
            }
          },
        },
      ],
    },
    {
      name: cvtRoutes.enterpriseSignup.name,
      path: cvtRoutes.enterpriseSignup.path,
      component: EnterpriseSignup,

      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
    },
    {
      name: cvtRoutes.enterpriseCodeVerification.name,
      path: cvtRoutes.enterpriseCodeVerification.path,
      component: EnterpriseCodeVerification,

      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
    },
    {
      name: cvtRoutes.enterpriseOnboarding.name,
      path: cvtRoutes.enterpriseOnboarding.path,
      component: EnterpriseAdminRouteView,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      children: [
        {
          name: cvtRouteNodes.enterpriseOnboardingCollectMoreInfo,
          path: cvtRouteNodes.information,
          component: EnterpriseMoreInfo,

          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
        },
        {
          name: cvtRouteNodes.enterpriseOnboardingSmb,
          path: cvtRouteNodes.smb,
          component: EnterpriseSmbOnboarding,

          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
        },
        {
          name: cvtRouteNodes.enterpriseOnboardingBrandingPage,
          path: cvtRouteNodes.brandConfig,
          component: EnterpriseBrandConfiguration,

          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
        },
        {
          name: cvtRouteNodes.enterpriseOnboardingError,
          path: cvtRouteNodes.error,
          component: ErrorPage,

          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
        },
      ],
    },
    {
      name: cvtRoutes.enterpriseDashboard.name,
      path: cvtRoutes.enterpriseDashboard.path,
      component: EnterpriseAdminRouteView,
      beforeEnter(to, from, next) {
        store.dispatch('loader/stop')
        next()
      },
      children: [
        {
          name: cvtRoutes.enterpriseIntegrateBuilder.name,
          path: cvtRoutes.enterpriseIntegrateBuilder.path,
          redirect: cvtRoutes.enterpriseIntegrateBuilder.redirect,
          component: EnterpriseIntegrateBuilder,

          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
          children: [
            {
              name: cvtRoutes.enterpriseSetCustomHost.name,
              path: cvtRoutes.enterpriseSetCustomHost.path,
              component: EnterpriseIBSetCustomHost,
            },
            {
              name: cvtRoutes.enterpriseAuthCustomers.name,
              path: cvtRoutes.enterpriseAuthCustomers.path,
              component: EnterpriseIBAuthCustomers,
            },
            {
              name: cvtRoutes.enterpriseFeatConfig.name,
              path: cvtRoutes.enterpriseFeatConfig.path,
              component: EnterpriseIBFeatureConfiguration,
            },
            {
              name: cvtRoutes.enterpriseDnsAccess.name,
              path: cvtRoutes.enterpriseDnsAccess.path,
              component: EnterpriseIBDnsAccess,
            },
            {
              name: cvtRoutes.enterpriseDnsConfig.name,
              path: cvtRoutes.enterpriseDnsConfig.path,
              component: EnterpriseIBConfigDns,
            },
            {
              name: cvtRoutes.enterpriseVerifyCustomHostname.name,
              path: cvtRoutes.enterpriseVerifyCustomHostname.path,
              component: EnterpriseIBVerifyCustomHostnames,
            },
            {
              name: cvtRoutes.enterpriseInviteIT.name,
              path: cvtRoutes.enterpriseInviteIT.path,
              component: EnterpriseIBInviteIT,
            },
          ],
        },
        {
          name: cvtRoutes.enterpriseDashboardRedirect.name,
          path: cvtRoutes.enterpriseDashboardRedirect.path,
          redirect: cvtRoutes.enterpriseDashboardRedirect.redirect,
          component: EnterpriseDashboardOnboarding,

          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
          children: [
            {
              name: cvtRoutes.enterpriseDashboardBranding.name,
              path: cvtRoutes.enterpriseDashboardBranding.path,
              component: EnterpriseDashboardBranding,
            },
            {
              name: cvtRoutes.enterpriseDashboardUsers.name,
              path: cvtRoutes.enterpriseDashboardUsers.path,
              component: EnterpriseDashboardUser,
            },
            {
              name: cvtRoutes.enterpriseDashboardConfig.name,
              path: cvtRoutes.enterpriseDashboardConfig.path,
              component: EnterpriseDashboardConfigurations,
            },
            {
              name: cvtRoutes.enterpriseDashboardIntegrations.name,
              path: cvtRoutes.enterpriseDashboardIntegrations.path,
              component: EnterpriseDashboardIntegrations,
            },
            {
              name: cvtRoutes.enterpriseDashboardDocumentation.name,
              path: cvtRoutes.enterpriseDashboardDocumentation.path,
              component: EnterpriseDashboardBranding,
            },
            {
              name: cvtRoutes.enterpriseDashboardTemplates.name,
              path: cvtRoutes.enterpriseDashboardTemplates.path,
              component: EnterpriseDashboardBranding,
            },
            {
              name: cvtRoutes.enterpriseDashboardReleaseNotes.name,
              path: cvtRoutes.enterpriseDashboardReleaseNotes.path,
              component: EnterpriseDashboardBranding,
            },
          ],
        },
        {
          name: cvtRoutes.genAIIntent.name,
          path: cvtRoutes.genAIIntent.path,
          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
          component: GenAiIntentGoalSelection,
        },
        {
          name: cvtRoutes.genAISelectTemplate.name,
          path: cvtRoutes.genAISelectTemplate.path,
          beforeEnter(to, from, next) {
            store.dispatch('loader/stop')
            next()
          },
          component: GenAISelectTemplate,
        },
      ],
    },
    {
      name: cvtRouteNodes.wizardEditorDesign,
      path: '/wizard-editor-design',
      beforeEnter(to, from, next) {
        store.dispatch('loader/start', {
          msg: 'Setting up your Content Builder...',
          route: 'editor',
        })
        store
          .dispatch(actions.SITE_FETCH_DATA, to.params.siteId)
          .then((data) => {
            const { id } = store.getters[getters.SITE_GET_INDEX]
            next({
              name: cvtRouteNodes.wizardEditorDesignPage,
              params: {
                ...to.params,
                pageId: id,
              },
            })
          })
          .catch((err) => {
            console.error(err)
            next(false)
          })
      },
    },
    {
      name: cvtRoutes.wizardEditor.name,
      path: cvtRoutes.wizardEditor.paramPath,
      component: WizardEditor,
      meta: {
        editor: true,
      },
      beforeEnter(to, from, next) {
        store.dispatch('loader/start', {
          msg: 'Setting up your Content Builder...',
          route: 'editor',
        })
        store
          .dispatch(actions.SITE_FETCH_DATA, to.params.siteId)
          .then((_) => {
            if (to.name === 'editor') {
              return next({
                name: cvtRouteNodes.wizardEditorDesignPage,
                query: to.query,
                params: to.params,
              })
            }

            next()
          })
          .catch((err) => {
            console.error(err)
            next(false)
          })
      },
      children: [
        {
          name: cvtRouteNodes.wizardEditorDesignPage,
          path: 'design/:pageId',
          alias: 'wizard-design',
          component: WizardPageEngineV3,
          beforeEnter(to, from, next) {
            store.dispatch('loader/start', {
              msg: 'Setting up your Content Builder...',
              route: 'editor',
            })
            store.commit(mutations.STEPS_SET_STEP, editSitePage.id)
            next()
          },
          props(route) {
            const { pageId } = route.params

            const page = _.chain(store)
              .get('state.site.site.pages')
              .find({ id: pageId })
              .value()

            return {
              page,
              '.path': `${page.firebaseRef}/vdom`,
              popupPath: `${page.firebaseRef}/popup`,
            }
          },
          meta: {
            pageEngine: true,
          },
        },
      ],
    },
  ]

  const router = new VueRouter({
    mode: 'history',
    routes,
  })

  // watch firebase auth state
  store.dispatch(actions.AUTH_HEARTBEAT)
  store.dispatch(actions.AUTH_FIREBASE_WATCH_AUTH)

  // load current user
  router.beforeEach(async (to, from, next) => {
    const { MAINTENANCE_ENABLED, AUTH_REDIRECT_URL } = await store.dispatch(
      'config/load',
    )

    if (MAINTENANCE_ENABLED && to.name === 'maintenance') {
      return next()
    }

    if (
      new Array(
        'enterprise:sign:up',
        'enterprise:code:verification',
        'enterprise:onboarding:error',
      ).includes(to.name)
    ) {
      return next()
    }

    if (MAINTENANCE_ENABLED) {
      return next('/maintenance')
    }

    if (store.getters[getters.AUTH_GET_USER]) {
      return next()
    }

    if (to.query.name) {
      store.commit(mutations.SITE_TRIGGER_SETTINGS, true)
    }

    store
      .dispatch(actions.AUTH_LOAD_USER, to)
      .then((_) => {
        next()
      })
      .catch((error) => {
        const { response = {} } = error
        const { data, status } = response
        next(false)

        if (AUTH_REDIRECT_URL) {
          window.location.href = AUTH_REDIRECT_URL
          return
        }

        console.error('failed to load user', {
          error,
          response,
          status,
          data,
        })

        if (to.query.iframeLogin !== 'true') {
          store.commit(
            'loader/setMessage',
            'Failed to load user, no redirect configured',
          )
        }
      })
  })

  // ensure user is authenticated into firebase
  router.beforeEach((to, from, next) => {
    if (to.name === 'maintenance') {
      return next()
    }

    if (
      new Array(
        'enterprise:sign:up',
        'enterprise:code:verification',
        'enterprise:onboarding:error',
      ).includes(to.name)
    ) {
      return next()
    }

    if (store.getters[getters.AUTH_GET_FIREBASE_USER]) {
      return next()
    }

    store
      .dispatch(actions.AUTH_FIREBASE_LOGIN)
      .then((_) => {
        next()
      })
      .catch((err) => {
        console.error(err)
        next(false)
      })
  })

  router.beforeEach((to, from, next) => {
    let changed = false
    if (from.query.redirectUrl && !to.query.redirectUrl) {
      to.query.redirectUrl = from.query.redirectUrl
      changed = true
      if (to.query.redirectUrl !== '' || to.query.redirectUrl !== '/') {
        safeLocalStorage.setItem('SITE_REDIRECT_URL', to.query.redirectUrl)
      }
    }

    if (from.query.headerTitle && !to.query.headerTitle) {
      to.query.headerTitle = from.query.headerTitle
      changed = true
    }

    if (changed) {
      return next({
        path: to.fullPath,
        query: to.query,
      })
    }

    next()
  })

  router.beforeEach((to, from, next) => {
    store.dispatch(actions.INTERCOM_TRACK, {
      event: 'navigated-to',
      context: {
        route: to.name,
        fullPath: to.fullPath,
      },
    })
    next()
  })

  // router.beforeEach((to, from, next) => {
  //   to.matched.some(r => {
  //     if (r.meta.step) {
  //       store.commit(mutations.STEPS_SET_STEP, r.meta.step)
  //     }
  //   })
  //   next()
  // })

  // When 3rd party cookies are disabled the builder cannot authenticate
  // This provides a way for the parent window to inject an authentication token
  // The token is attached to an axios request interceptor
  // TODO: we may want to append a banner to the site explaining that some features may be degraded
  window.addEventListener(
    'message',
    (event) => {
      const url = new URL(window.location)

      // TODO: add config for allowed origins
      // if (event.origin !== 'http://example.com:8080') return

      // event.source is window.opener
      // event.data is "hello there!"
      if (url.searchParams.get('sessionActivated') === 'true') {
        console.debug('user is already authenticated')
        return
      }

      if (event.data.command === 'login' && event.data.token) {
        // reload the window after authentication
        url.searchParams.set('sessionActivated', 'true')
        // inject session token into all API requests
        apiClient.interceptors.request.use((config) => {
          config.headers['Authorization'] = `Bearer ${event.data.token}`
          return config
        })
      } else {
        console.debug('window message missing data', event)
      }
    },
    false,
  )

  return router
}

import Vue from "vue";
import { Notification } from "element-ui";
import { V1PopupSite } from '../../services/api';
import * as actions from '../actions';
import * as getters from '../getters';
import * as mutations from '../mutations';

const popupSite = new V1PopupSite()

export default {
  state: {
    popup: null,
    settingsVisible: false,
    activeTab: 'general',
    thumbnails: []
  },
  getters: {
    [getters.SDA_POPUP_SITE_DATA]({ popup }) {
      return popup
    },
    [getters.SDA_POPUP_SETTINGS_ACTIVE_TAB]({ activeTab }) {
      return activeTab
    },
  },
  mutations: {
    [mutations.POPUP_SET_DATA](state, data) {
      state.popup = data
    },
    [mutations.SDA_POPUP_TRIGGER_TAB](state, activate) {
      state.activeTab = activate
    },
    [mutations.SDA_POPUP_LOCK]({popup}) {
      Vue.set(popup, 'locked', true)
    },
    [mutations.SDA_POPUP_UNLOCK]({popup}) {
      Vue.set(popup, 'locked', false)
    },
  },
  actions: {
    async [actions.POPUP_FETCH_DATA]({ state, commit, dispatch }, popupId) {
      const { data } = await popupSite.byId(popupId)
      commit(mutations.POPUP_SET_DATA, data)
      await dispatch(actions.THEME_INIT, data.firebaseRef)
      await dispatch(actions.OPEN_GRAPH_INIT, data.firebaseRef)
      await dispatch(actions.ANCHORS_INIT, data.firebaseRef)
      await dispatch(actions.SDA_POPUP_SETTINGS_INIT, data.firebaseRef, {root:true})
      await dispatch('sitePreferences/init', data.firebaseRef, false)
      return data
    },
    [actions.POPUP_UPDATE]({ commit }, popup) {
      commit(mutations.POPUP_SET_DATA, popup)
      return popupSite.updateById(popup.id, popup).then(() => {
        commit(mutations.POPUP_SET_DATA, popup)
        // commit(mutations.SITE_TRIGGER_SETTINGS, false)
      })
    },
    [actions.SDA_POPUP_SITE_UPDATE_CATEGORIES]({ state, commit }, { id, categories }) {
      return popupSite.updateCategories(id, categories)
        .then(() => {
          commit(mutations.POPUP_SET_DATA, {
            ...state.popup,
            categories,
          })
        })
    },
    [actions.SDA_POPUP_SITE_ENABLE_MARKETPLACE]({ state, commit }, { id, enabled }) {
      return popupSite.enablePopupMarketplace(id, enabled)
        .then(() => {
          commit(mutations.POPUP_SET_DATA, {
            ...state.popup,
            marketplace: enabled,
          })
        })
    },
    [actions.SDA_POPUP_SITE_CLONE]({ state, commit }, popupSiteId) {
      return popupSite.fromPopupSite(popupSiteId)
        .then(({ data: popupSite }) => {
          commit(mutations.POPUP_SET_DATA, popupSite)
          return popupSite
        })
    },
    [actions.SDA_POPUP_SITE_ARCHIVE]({ state, commit }, popupSiteId) {
      return popupSite.archive(popupSiteId).then(({ data }) => data)
    },
    [actions.SDA_POPUP_SITE_RESTORE]({ state, commit }, popupSiteId) {
      return popupSite.restore(popupSiteId).then(({ data }) => data)
    },
    [actions.SDA_POPUP_CONNECT_URL]({ state, commit }, url) {
      return popupSite.connect_url(state.popup.id, url)
          .then(() => {
            return {
              errors: false
            }
          }, (err) => {
            Notification.error({
              title: 'Error',
              message: err.message,
              position: 'bottom-right',
            })
            return {
              errors: true
            }
          })
    },
    [actions.SDA_POPUP_PUBLISH]({ state, commit }) {
      commit(mutations.SDA_POPUP_LOCK)
      return popupSite.publish(state.popup.id).then(
          ({ data }) => {
            Notification.success({
              title: `Published (${state.popup.popupName})`,
              duration: 10000,
              position: 'bottom-right',
              message: data.message
            })

            commit(mutations.SDA_POPUP_UNLOCK)
            commit(mutations.POPUP_SET_DATA, data)

            return {
              errors: false,
            }
          },
          (err) => {
            commit(mutations.SDA_POPUP_UNLOCK)
            const { data } = err?.response || {}
            console.log("SDA_POPUP_PUBLISH: ERROR -> ", err.toJSON())
            if (data && data.errors) {
              for (const e of data.errors) {
                Notification({
                  title: e.code || 'woops',
                  message: e.message,
                  type: 'error',
                  duration: 15000,
                  position: 'bottom-right',
                })
              }
            } else {
              Notification.error({
                title: 'Error',
                message: err.message,
                position: 'bottom-right',
              })
            }
            return { errors: (data && data.errors) || [err] }
          },
      )
    },
    [actions.SDA_POPUP_UPDATE_THUMBNAIL]({ state, commit }) {
      // commenting it for now, once the thumbnail generating API is complete
      // we will continue to use this API again. 
      // return popupSite.updateThumbnails(state.popup.id).then(response => {
      //   commit(mutations.POPUP_SET_DATA, {
      //       ...state.popup,
      //       thumbnails: response.data,
      //     })
      //   }).catch(error => console.error('error generation popup thumbnails', error))
    },
    [actions.SDA_POPUP_UPDATE_PROPERTIES]({ state, commit }, { popupID, popupSiteProperties }) {
      return popupSite.updateProperties(popupID, popupSiteProperties)
        .then(({ data }) => {
          commit(mutations.POPUP_SET_DATA, {
            ...state.popup,
            ...data,
          })
        })
        .catch(error => console.error('error in updating popup properties', error))
    },
  },
}

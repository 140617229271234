<template>
  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item v-if="config.IS_GEN_AI_ENABLED" title="Generative AI">
      <GenAIOptions :mode="mode" />
    </cvt-collapse-item>
    <cvt-collapse-item
      v-if="!selected.isPopupBlock"
      :title="$t('elements.properties.label')"
      name="properties"
      :initial-active="true"
    >
      <div class="row no-gutters my-3">
        <div class="col-12">
          <cvt-input
            v-model="anchorName"
            type="text"
            :placeholder="$t('editor.section.name.message')"
            :label="$t('editor.section.name.message')"
          ></cvt-input>
        </div>
      </div>
      <div v-if="isHeaderBlock" class="row mt-2">
        <div class="col">
          <h6 :class="textColorClass">Disable Header</h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="headerDisabled"
            :mode="mode"
            @switchToggle="() => disableHeaderAction(!headerDisabled)"
          ></cvt-switch>
        </div>
      </div>
      <div v-if="isFooterBlock" class="row mt-2" data-test-id="footer-block">
        <div class="col">
          <h6 :class="textColorClass">Disable Footer</h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="footerDisabled"
            :mode="mode"
            @switchToggle="() => disableFooterAction(!footerDisabled)"
          ></cvt-switch>
        </div>
      </div>
    </cvt-collapse-item>
    <cvt-collapse-item
      v-if="selected.isPopupBlock"
      :title="'Close Button Location'"
      name="Close Button Location"
    >
      <div class="flex row justify-content-between mx-5">
        <cvt-radio-group
          size="md"
          orientation="horizontal"
          :initial-value="cCloseButtonPosition"
        >
          <cvt-radio-button
            size="md"
            :value="'start'"
            @radioClick="setPopupClosePosition"
          >
            {{ 'Left' }}
          </cvt-radio-button>

          <cvt-radio-button
            size="md"
            :value="'end'"
            @radioClick="setPopupClosePosition"
          >
            {{ 'Right' }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </cvt-collapse-item>
    <cvt-collapse-item
      v-if="selected.isPopupBlock && showPopupSizeConstraints"
      :title="'Size Constraints'"
      name="Size Constraints"
      :initial-active="true"
    >
      <div class="row no-gutters">
        <div class="col-12">
          <cvt-radio-group
            size="md"
            orientation="horizontal"
            :initial-value="cPopupWidth"
            :disabled="cPopupIsFullScreen"
          >
            <cvt-radio-button
              size="md"
              value="small"
              :disabled="cPopupIsFullScreen"
              @radioClick="setPopupWidth"
            >
              {{ $t('elements.popups.setting.size.small') }}
            </cvt-radio-button>

            <cvt-radio-button
              size="md"
              value="medium"
              :disabled="cPopupIsFullScreen"
              @radioClick="setPopupWidth"
            >
              {{ $t('elements.popups.setting.size.medium') }}
            </cvt-radio-button>

            <cvt-radio-button
              size="md"
              value="large"
              :disabled="cPopupIsFullScreen"
              @radioClick="setPopupWidth"
            >
              {{ $t('elements.popups.setting.size.large') }}
            </cvt-radio-button>
          </cvt-radio-group>
        </div>

        <div class="divider"></div>

        <div class="row mt-2">
          <div class="col">
            <h6 :class="textColorClass">
              {{ $t('elements.popups.setting.size.fs') }}
            </h6>
          </div>
          <div class="col-3">
            <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="cPopupIsFullScreen"
              :mode="mode"
              @switchToggle="
                (status) => setPopupWidthFullSreen(status, 100, 100)
              "
            ></cvt-switch>
          </div>
        </div>
      </div>
    </cvt-collapse-item>
    <cvt-collapse-item
      :title="$t('elements.properties.background.color')"
      name="color"
    >
      <colors alpha :mode="mode"></colors>
    </cvt-collapse-item>
    <cvt-collapse-item
      :title="$t('elements.properties.background.image')"
      name="bgImage"
    >
      <element-image-manager
        image-key="background/image"
        :hook="selected.updateBgSrc"
        :background-img-position="backgroundPosition"
        @backgroundImgPositionChanged="onBgImgPositionChanged"
      >
      </element-image-manager>
      <background-size-repeat> </background-size-repeat>

      <div class="divider"></div>
      <background-color-overlay :mode="mode"> </background-color-overlay>
    </cvt-collapse-item>
    <cvt-collapse-item
      v-if="isGridBuilder"
      :title="$t('elements.properties.background.shape')"
      name="bgShape"
    >
      <background-shape />
    </cvt-collapse-item>
    <cvt-collapse-item
      :title="$t('elements.properties.style.label')"
      name="style"
    >
      <div class="row mt-2">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('element.property.section.edge') }}
          </h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="isFluid"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('isFluid')"
          ></cvt-switch>
        </div>
      </div>

      <div v-if="!selected.isPopupBlock" class="divider"></div>

      <div v-if="!selected.isPopupBlock" class="row mt-2">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('element.property.section.full_height') }}
          </h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="isCover"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('isCover')"
          ></cvt-switch>
        </div>
      </div>

      <div v-if="!selected.isPopupBlock" class="divider"></div>

      <div v-if="!selected.isPopupBlock" class="row mt-2">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('element.property.section.parallax') }}
          </h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="backgroundParallax"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('backgroundParallax')"
          ></cvt-switch>
        </div>
      </div>
    </cvt-collapse-item>
    <cvt-collapse-item
      :title="$t('elements.properties.spacing.label')"
      name="spacing"
    >
      <spacing-ctrl></spacing-ctrl>
    </cvt-collapse-item>

    <!-- Hide it when New-Section-UI is enabled regardless of grid-builder or old-builder -->
    <cvt-collapse-item
      v-if="!isSectionUIEnabled"
      :title="$t('elements.properties.visibility.label')"
      name="visibility"
    >
      <visibility-ctrl :mode="mode"></visibility-ctrl>
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import Colors from '../theme/Colors.vue'
import BackgroundColorOverlay from './BackgroundColorOverlay.vue'
import BackgroundSizeRepeat from './BackgroundSizeRepeat.vue'
import ElementImageManager from './ElementImageManager.vue'
import SpacingCtrl from './SpacingCtrl.vue'
import VisibilityCtrl from './VisibilityCtrl.vue'
import BackgroundShape from './BackgroundShape.vue'
import GenAIOptions from '../../genAi/GenAIOptions.vue'

const fixedWidths = {
  small: '450px',
  medium: '625px',
  large: '980px',
}

export default {
  components: {
    GenAIOptions,
    SpacingCtrl,
    Colors,
    ElementImageManager,
    BackgroundSizeRepeat,
    VisibilityCtrl,
    BackgroundColorOverlay,
    BackgroundShape,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTabs: ['color', 'properties'],
      pWidth: fixedWidths.medium,
      pHeight: 'auto',
      oldPopupWidth: '0%',
      oldPopupHeight: 'auto',
      isFullScreen: false,
      closeBtnPosition: 'end',
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
      config: getters.AUTH_GET_USER_CONFIG,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      viewMode: getters.VIEW_MODE,
      headerDisabled: getters.HEADER_DISABLED,
      footerDisabled: getters.FOOTER_DISABLED,
      user: getters.AUTH_GET_USER,
    }),
    isMobile() {
      return this.viewMode === 'phone'
    },
    showPopupSizeConstraints() {
      return !this.isMobile
    },
    isHeaderBlock() {
      return this.selected?.isHeaderBlock || false
    },
    isFooterBlock() {
      return this.selected?.isFooterBlock || false
    },
    anchorName: {
      get() {
        return this.selected?.anchorName
      },
      set: _.debounce(function ({ target }) {
        this.anchorUpdateById({
          id: this.selected.vnodeId,
          value: target.value,
        })
      }, 800),
    },
    isFluid: {
      get() {
        return this.selected.isFluid
      },
      set(value) {
        return this.updateProp({ key: 'isFluid', value })
      },
    },
    isCover: {
      get() {
        return this.selected.isCover
      },
      set(value) {
        return this.updateProp({ key: 'isCover', value })
      },
    },
    backgroundParallax: {
      get() {
        return _.get(this, 'selected.background.parallax')
      },
      set(value) {
        return this.updateProp({ key: 'background/parallax', value })
      },
    },
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    backgroundPosition: {
      get() {
        return _.get(this, 'selected.background.imagePosition', {})
      },
      set(value) {
        return this.updateProp({ key: 'background/imagePosition', value })
      },
    },
    cPopupWidth: {
      get() {
        return this.pWidth ? this.pWidth : fixedWidths.medium
      },
      set(value) {
        this.pWidth = value
        return this.updatePopupProperty({ key: 'popupWidth', value: value })
      },
    },
    cPopupHeight: {
      get() {
        return this.pHeight ? this.pHeight : 'auto'
      },
      set(value) {
        this.pHeight = value
        return this.updatePopupProperty({ key: 'popupHeight', value: value })
      },
    },
    cPopupIsFullScreen: {
      get() {
        return this.isFullScreen
      },
      set(value) {
        this.isFullScreen = value
        return this.updatePopupProperty({ key: 'isFullScreen', value: value })
      },
    },
    cCloseButtonPosition: {
      get() {
        return this.closeBtnPosition
      },
      set(value) {
        this.closeBtnPosition = value
        return this.updatePopupProperty({
          key: 'closeBtnPosition',
          value: value,
        })
      },
    },
    isSectionUIEnabled() {
      return this.config.SECTION_UI_ENABLED
    },
  },
  async created() {
    if (this.selected.isPopupBlock) {
      try {
        ;[this.pWidth, this.pHeight, this.isFullScreen, this.closeBtnPosition] =
          await Promise.all([
            this.getPopupProperty('popupWidth'),
            this.getPopupProperty('popupHeight'),
            this.getPopupProperty('isFullScreen'),
            this.getPopupProperty('closeButtonPosition'),
          ])
      } catch (error) {
        console.log(error)
      }
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
      anchorUpdateById: actions.ANCHORS_UPDATE_BY_ID,
      getPopupProperty: actions.POPUP_GET_PROPERTY,
      updatePopupProperty: actions.POPUP_UPDATE_PROPERTY,
      updateV3PopupProperties: actions.SDA_POPUP_FIREBASE_UPDATE_PROPERTIES,
      popupUpdateClosePosition: actions.POPUP_BUILDER_UPDATE_CLOSE_BTN_POSITION,
      popupGetClosePosition: actions.POPUP_GET_CLOSE_BTN_POSITION,
      disableHeaderAction: actions.HEADER_DELTA_DISABLED,
      disableFooterAction: actions.FOOTER_DELTA_DISABLED,
    }),
    setPopupClosePosition(val) {
      this.closeBtnPosition = val

      this.updatePopupProperty({
        key: 'closeButtonPosition',
        value: val,
      })
      this.popupPropsUpdateV3({
        popupWidth: this.cPopupWidth,
        popupHeight: this.cPopupHeight,
        isFullScreen: this.cPopupIsFullScreen,
        closeButtonPosition: this.cCloseButtonPosition,
      })
    },
    onSwitchToggle(property) {
      property == 'isFluid'
        ? (this.isFluid = !this.isFluid)
        : property == 'isCover'
        ? (this.isCover = !this.isCover)
        : property == 'backgroundParallax'
        ? (this.backgroundParallax = !this.backgroundParallax)
        : ''
    },
    setPopupWidth(size) {
      this.cPopupWidth = fixedWidths[size]
      // this will reset the height to its previous value because
      // when a user clicks on full screen switch button it changes
      // the height too but this function only changes the width.
      // so we should also change the height to its previous default value
      this.cPopupHeight = this.oldPopupHeight
      this.cPopupIsFullScreen = false
      this.popupPropsUpdateV3({
        popupWidth: this.cPopupWidth,
        popupHeight: this.cPopupHeight,
        isFullScreen: this.cPopupIsFullScreen,
        closeButtonPosition: this.cCloseButtonPosition,
      })
    },
    setPopupWidthFullSreen(status, width, height) {
      if (status === 'Active') {
        this.oldPopupWidth = this.pWidth
        this.oldPopupHeight = this.pHeight

        this.cPopupWidth = `${width}vw`
        this.cPopupHeight = `${height}vh`
        this.cPopupIsFullScreen = true
        this.isCover = false
      } else {
        this.cPopupWidth =
          this.oldPopupWidth === '0%' ? fixedWidths.medium : this.oldPopupWidth
        this.cPopupHeight = this.oldPopupHeight
        this.cPopupIsFullScreen = false
      }
      this.popupPropsUpdateV3({
        popupWidth: this.cPopupWidth,
        popupHeight: this.cPopupHeight,
        isFullScreen: this.cPopupIsFullScreen,
        closeButtonPosition: this.cCloseButtonPosition,
      })
    },
    popupPropsUpdateV3: _.debounce(function (payloads) {
      this.updateV3PopupProperties(payloads)
    }),
    onBgImgPositionChanged(eventDetails) {
      this.backgroundPosition = this.formatPositionData(eventDetails)
    },
    formatPositionData(eventDetails) {
      let x = 0
      let y = 0
      let calculatedX = 0
      let calculatedY = 0

      if (eventDetails.eventType === 'mouseDrag') {
        ;({ x, y } = eventDetails.eventData.position)

        // the dimensions of the marker is 16x16, these adjustments like x-10 or y-10
        // are made to make sure that if marker is at 16px at X-axis and 16px at Y-axis
        // the background image position is at (background-position: 0% 0%)
        // also the 2.06 or 1.84 values are the ratio's by which our image will move
        // when our marker moves 1px on the right-side-pane which is 220x200 in dimension.
        // calculatedX = ((x - 10) / 2.06).toFixed()
        // calculatedY = ((y - 10) / 1.84).toFixed()
        calculatedX = (x - 10).toFixed()
        calculatedY = y.toFixed()
      } else if (eventDetails.eventType === 'mouseClick') {
        // when eventType is mouseClick, it only gets position object in eventData
        ;({ x, y } = eventDetails.eventData.position)

        // in this case the marker was not taking width and height, so actual values are implemented
        // calculatedX = (x / 2.21).toFixed()
        // calculatedY = (y / 2.0).toFixed()
        calculatedX = (x - 10).toFixed()
        calculatedY = y.toFixed()
      }

      return {
        rawPos: {
          // raw position
          x: x,
          y: y,
        },
        calcPos: {
          // calculated position
          x: calculatedX,
          y: calculatedY,
        },
      }
    },
  },
}
</script>

<style lang="scss">
.popup-close-btn {
  cursor: pointer;
  padding: 10px 15px;
  display: grid;
  place-content: center;
}
.popup-close-btn:hover,
.popup-close-btn.active {
  background-color: #f0f2f6;
}
</style>

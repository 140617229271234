import { EXIT_POST_MESSAGE } from "@/js/common/constants/constants"
import { safeLocalStorage } from '@/js/lib/localStorage'
import { database } from '@/js/store/services/firebase.service'
import { Notification } from 'element-ui'
import * as _ from 'lodash'
import isURL from 'validator/lib/isURL'
import Vue from 'vue'
import { CustomDomains, Marketplace, V1Sites } from '../services/api'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const v1Sites = new V1Sites()
const customDomains = new CustomDomains()
const v1Templates = new Marketplace()
const db = database()
const GEN_AI_TEMPLATES_PATH = 'gen-ai-templates'

const PublishSuccessMessage = Vue.extend({
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  // Removed template from here, because it does not work well
  // with the new vite-config untill we upgrade to vue-3
  render: function (h) {
    return h(`a`, {
      attrs: {
        href: this.url,
        target: "_blank",
      }
    },
      this.url);
  }
})

export default {
  state: {
    site: null,
    settingsVisible: false,
    activeTab: 'general',
    sites: [],
    categories: [],
    analytics: [],
    ready: false,
    pulsePreference: true,
    domains: [],
    tracking: {},
    checkout: {},
    settingsMessage: '',
    activePage: null,
    activeSitePreviewPage: null,
    sitePreviewRouteVisited: false,
    preferences: []
  },
  getters: {
    [getters.SITE_CHECKOUT]({ checkout }) {
      return checkout
    },
    [getters.SITE_TRACKING]({ tracking }) {
      return tracking
    },
    [getters.SITE_SETTINGS_ACTIVE_TAB]({ activeTab }) {
      return activeTab
    },
    [getters.SITE_SETTINGS_MESSAGE]({ settingsMessage }) {
      return settingsMessage
    },
    [getters.SITE_GET_DATA]({ site }) {
      return site
    },
    [getters.SITE_GET_INDEX]({ site }) {
      return site
        ? _.find(site.pages, {
          isIndex: true,
        })
        : null
    },
    [getters.SITE_GET_THANKYOU]({ site }) {
      return site
        ? _.find(site.pages, {
          isIndex: false,
        })
        : null
    },
    [getters.SITE_GET_ACTIVE_PAGE]({ site }) {
      return (pageId) => {
        return site && site.pages
          ? _.find(site.pages, {
            id: pageId,
          })
          : null
      }
    },
    [getters.SITES_GET_LIST]({ sites }) {
      return sites
    },
    [getters.SITE_GET_SETTINGS_VISIBLE]({ settingsVisible }) {
      return settingsVisible
    },
    [getters.SITE_READY]({ ready }) {
      return ready
    },
    [getters.SITE_PULSE_PREFERENCE]({ pulsePreference }) {
      return pulsePreference
    },
    [getters.SITE_DOMAINS]({ domains }) {
      return domains
    },
    [getters.SITE_ANALYTICS]({ analytics }) {
      return analytics
    },
    [getters.SITE_HAS_GRIDBUILDER]({ site }, getters, rootState) {
      if (site == null) {
        if (rootState.builders) {
          return rootState.builders.version !== 'v1'
        }
        return false
      } else {
        return site.version === 'v2'
      }
    },
    [getters.TEMPLATE_PAGE_ID]({ activePage }) {
      return activePage
    },
    [getters.SITE_PREVIEW_PAGE_ID]({ activeSitePreviewPage }) {
      return activeSitePreviewPage
    },
    [getters.SITE_PREVIEW_ROUTE_VISITED]({ sitePreviewRouteVisited }) {
      return sitePreviewRouteVisited
    },
    [getters.GET_ALL_CATEGORIES]({ categories }) {
      return categories
    },
    [getters.GET_PREFERENCES]({ preferences }) {
      return preferences
    },
  },
  mutations: {
    [mutations.SITE_SET_DATA](state, data) {
      state.site = data
    },
    [mutations.SITES_SET_DATA](state, data) {
      state.sites = data
    },
    [mutations.SITES_APPEND_DATA](state, data) {
      state.sites = state.sites.concat(data)
    },
    [mutations.SITES_REPLACE_SITE](state, site) {
      const idx = state.sites.indexOf(site.old)

      if (state.sites.length === 0) {
        return console.debug(
          'sites list was empty might not have loaded list because in editor',
        )
      }

      if (idx === -1) {
        throw new Error(`Site does not exist in sites ${site.id}`)
      }

      state.sites = Object.assign([], state.sites, {
        [idx]: site.new,
      })
    },
    [mutations.SITE_TRIGGER_SETTINGS](state, visibility) {
      state.settingsVisible = visibility
    },
    [mutations.SITE_LOCK](_, site) {
      Vue.set(site, 'locked', true)
    },
    [mutations.SITE_UNLOCK](_, site) {
      Vue.set(site, 'locked', false)
    },
    [mutations.SITE_TRIGGER_TAB](state, activate) {
      state.activeTab = activate
    },
    [mutations.SITE_SET_SETTINGS_MESSAGE](state, message) {
      state.settingsMessage = message
    },
    [mutations.SITE_TOGGLE_READY_STATE](state, ready) {
      state.ready = ready
    },
    [mutations.SITE_TOGGLE_PULSE_PREFERNCE](state, pulse) {
      state.pulsePreference = pulse
      safeLocalStorage.setItem('PULSE_INSERTION_POINTS', pulse)
    },
    [mutations.UPDATE_TEMPLATE_PAGE](state, pageId) {
      state.activePage = pageId
    },
    [mutations.UPDATE_SITE_PREVIEW_PAGE](state, pageId) {
      state.activeSitePreviewPage = pageId
    },
    [mutations.UPDATE_SITE_PREVIEW_ROUTE_VISITED](state, flagValue) {
      state.sitePreviewRouteVisited = flagValue
    },
    [mutations.UPDATE_DOMAINS](state, domainsList) {
      state.domains = domainsList
    },
    [mutations.SET_SITE_ANALYTICS](state, data) {
      state.analytics = data
    },
    [mutations.SET_ALL_CATEGORIES](state, data) {
      state.categories = data
    },
    [mutations.SET_PREFERENCES](state, data) {
      state.preferences = data
    },
  },
  actions: {
    [actions.SITE_FETCH_DATA]({ state, commit, dispatch }, siteId) {
      return v1Sites.byId(siteId).then(({ data }) => {
        dispatch(actions.SITE_TRACK_OLD_PAGE, data)

        commit(mutations.SITE_SET_DATA, data)
        return Promise.all([
          dispatch(actions.THEME_INIT, data.firebaseRef),
          dispatch(actions.OPEN_GRAPH_INIT, data.firebaseRef),
          dispatch(actions.SITE_JS_TRACKING, data.firebaseRef),
          dispatch(actions.SITE_CHECKOUT, data.firebaseRef),
          dispatch(actions.SITE_SYNC_READY_STATE, data.firebaseRef),
          dispatch(actions.STEPS_INIT, data.firebaseRef),
          dispatch(actions.ANCHORS_INIT, data.firebaseRef),
          dispatch('sitePreferences/init', data.firebaseRef, true),
        ])
      })
    },
    [actions.TEMPLATE_FETCH_DATA]({ state, commit, dispatch }, templateId) {
      return v1Templates.templateByID(templateId).then(({ data }) => {
        commit(mutations.SITE_SET_DATA, data)
        let indexPage = data.pages.find((page) => page.isIndex === true)
        commit(mutations.UPDATE_TEMPLATE_PAGE, indexPage.id)
      })
    },
    [actions.SITE_TRACK_OLD_PAGE]({ dispatch }, data) {
      if (data.old !== true) return
      dispatch(actions.INTERCOM_TRACK, {
        event: 'loaded-old-page',
        context: {
          id: data.id,
          name: data.name,
          orgID: data.orgID,
          projectID: data.projectID,
          appId: data.projectID,
          subdomain: data.subdomain,
          published: data.published,
          ref: data.firebaseRef,
          createdAt: data.createdAt,
        },
      })
    },
    [actions.SITES_FETCH_DATA]({ commit }, query) {
      return v1Sites.all(query).then(({ data }) => {
        commit(mutations.SITES_APPEND_DATA, data)
      })
    },
    [actions.SITE_UPDATE]({ commit }, site) {
      commit(mutations.SITE_SET_DATA, site)
      return v1Sites.updateById(site.id, site).then(() => {
        commit(mutations.SITE_SET_DATA, site)
        commit(mutations.SITE_TRIGGER_SETTINGS, false)
      })
    },
    [actions.SITE_REGENERATE_FORMS]({ commit }, site) {
      return v1Sites.regenerateForms({ siteId: site.id })
    },
    [actions.SITE_PUBLISH]({ commit }, site) {
      // let preview = window.open('', '_blank')
      // preview.document.write('Please wait, we are publishing your changes.')

      commit(mutations.SITE_LOCK, site)
      return v1Sites.publish(site.id).then(
        ({ data }) => {
          // preview.location.href = data.url

          const vm = new Vue()
          const h = vm.$createElement
          const { code } = data
          const notReady = (code === 'SITE_NOT_READY')

          Notification.success({
            title: `Published (${site.name})`,
            duration: 10000,
            position: 'bottom-right',
            message: notReady
              ? data.message
              : h(PublishSuccessMessage, {
                props: {
                  url: data.url,
                },
              }),
          })

          commit(mutations.SITE_UNLOCK, site)
          commit(mutations.SITES_REPLACE_SITE, {
            old: site,
            new: data.site,
          })

          return {
            errors: false,
          }
        },
        (err) => {
          commit(mutations.SITE_UNLOCK, site)

          const { response = {} } = err
          const { data } = response

          if (data && data.errors) {
            for (const e of data.errors) {
              Notification({
                title: e.code || 'woops',
                message: e.message,
                type: 'error',
                duration: 15000,
                position: 'bottom-right',
              })
            }
          } else {
            Notification.error({
              title: 'Error',
              message: err.message,
              position: 'bottom-right',
            })
          }
          return { errors: (data && data.errors) || [err] }
        },
      )
    },
    [actions.SITE_SYNC_READY_STATE]({ state, commit }, firebaseRef) {
      if (state.readyRef) {
        state.readyRef.off('value')
      }

      state.readyRef = db.ref(firebaseRef).child('ready')
      state.readyRef.on('value', (snap) => {
        commit(mutations.SITE_TOGGLE_READY_STATE, snap.val())
      })
    },
    [actions.SITE_TOGGLE_READY_STATE]({ commit, state }, ready) {
      return state.readyRef.set(ready)
    },
    [actions.SITE_INIT_PULSE_PREFERENCE]({ commit }) {
      commit(
        mutations.SITE_TOGGLE_PULSE_PREFERNCE,
        JSON.parse(safeLocalStorage.getItem('PULSE_INSERTION_POINTS') || false),
      )
    },
    [actions.SITE_UPDATE_THUMBNAIL]({ state }) {
      return v1Sites.updateThumbnails(state.site.id)
    },
    [actions.FETCH_ALL_CATEGORIES]({ commit }) {
      return v1Sites.getAllCategories().then(({ data }) => {
        commit(mutations.SET_ALL_CATEGORIES, data)
      })
    },
    [actions.SITE_UPDATE_CATEGORIES]({ state, commit, dispatch }, { id, categories }) {
      return v1Sites.updateCategories(id, categories).then(() => {
        commit(mutations.SITE_SET_DATA, {
          ...state.site,
          categories,
        })
        dispatch(actions.FETCH_ALL_CATEGORIES)
      })
    },
    [actions.SITE_UPDATE_PROPERTIES]({ state, commit }, { id, sitePropsToUpdate }) {
      return v1Sites.updateSiteProperties(id, sitePropsToUpdate).then(() => {
        commit(mutations.SITE_SET_DATA, {
          ...state.site,
          ...sitePropsToUpdate,
        })
      })
    },
    [actions.SITE_CAMPAIGN_REDIRECT]({ rootState, rootGetters }) {
      const { MARKETPLACE_LOGO_REDIRECT } = rootGetters[getters.AUTH_GET_USER_CONFIG]
      if (MARKETPLACE_LOGO_REDIRECT && MARKETPLACE_LOGO_REDIRECT === EXIT_POST_MESSAGE) {
        // clients that render builder on iframe can exit the builder through a post message
        window.parent.postMessage('Exit Builder Requested', '*')
        return
      }

      let redirectURL
      if (
        !rootState.route.query.redirectUrl?.trim()
      ) {
        redirectURL = safeLocalStorage.getItem('SITE_REDIRECT_URL')
      } else {
        redirectURL = rootState.route.query.redirectUrl
      }

      window.location.href =
        redirectURL && isURL(redirectURL) ? redirectURL : '/'
    },
    [actions.SITE_CRM_REDIRECT]({ rootState }, { url }) {
      if (url === EXIT_POST_MESSAGE) {
        // clients that render builder on iframe can exit the builder through a post message
        window.parent.postMessage("Exit Builder Requested", '*')
        return
      }

      let redirectURL
      if (
        !rootState.route.query.redirectUrl?.trim()
      ) {
        redirectURL = url === "" ? safeLocalStorage.getItem('SITE_REDIRECT_URL') || "" : url
      } else {
        redirectURL = rootState.route.query.redirectUrl
      }
      window.location.href =
        redirectURL && isURL(redirectURL) ? redirectURL : '/'
    },
    [actions.SITE_LOAD_ANALYTICS]({ commit, state }, { window = 30, siteId }) {
      if (siteId) {
        v1Sites
          .analytics({ siteId, window })
          .then(({ data }) => {
            commit(mutations.SET_SITE_ANALYTICS, data || [])
          })
          .catch((error) => {
            commit(mutations.SET_SITE_ANALYTICS, [])
            console.debug('Analytics Not Found: ', error)
          })
      }
    },
    [actions.SITE_JS_TRACKING]({ state }, siteRef) {
      if (state.checkoutRef) {
        state.trackingRef.off('value')
      }

      state.trackingRef = db.ref(siteRef).child('tracking')

      state.trackingRef.on('value', (snap) => {
        state.tracking = snap.val() || {
          head: '',
          afterOpeningBody: '',
          beforeClosingBody: '',
        }
      })
    },
    [actions.SITE_JS_TRACKING_SAVE]({ state }, tracking) {
      return state.trackingRef.set(tracking).then(() => {
        state.tracking = tracking
        return Promise.resolve()
      })
    },
    [actions.SITE_CHECKOUT]({ state }, siteRef) {
      if (state.checkoutRef) {
        state.checkoutRef.off('value')
      }

      state.checkoutRef = db.ref(siteRef).child('checkout')

      state.checkoutRef.on('value', (snap) => {
        state.checkout = snap.val() || {}
      })
    },
    async [actions.SITE_CHECKOUT_SAVE]({ state }, checkout) {
      state.checkout = checkout
      return state.checkoutRef.set(checkout)
    },
    [actions.SITE_ARCHIVE]({ commit, state }, id) {
      return v1Sites.archive(id).then(() => {
        commit(
          mutations.SITES_SET_DATA,
          state.sites.filter((s) => s.id !== id),
        )
      })
    },
    [actions.SITE_RESTORE]({ commit, state }, id) {
      return v1Sites.restore(id).then((res) => {
        const data = { res }
        commit(mutations.SITES_APPEND_DATA, data)
        return data
      }).catch(error => {
        Notification.error({
          title: 'Error',
          message: error.response.data.message,
          position: 'bottom-right',
        })
      })
    },
    [actions.CUSTOM_DOMAIN_LOAD_DOMAINS]({ commit, state }) {
      return customDomains
        .loadDomains({ siteId: state.site.id })
        .then((res) => {
          commit(mutations.UPDATE_DOMAINS, res.data.list)
          return res.data.list
        })
    },
    [actions.CUSTOM_DOMAIN_DISCONNECT]({ state }, { siteId, domain }) {
      return customDomains
        .disconnectDomain({
          siteId: siteId ? siteId : state.site.id,
          domain: domain,
        })
        .then((res) => {
          state.domains = state.domains.filter(
            (record) => record.domain === domain,
          )
          return res.data.unlinked
        })
    },
    [actions.CUSTOM_DOMAIN_CONNECT]({ state }, { siteId, domain }) {
      return customDomains.connectDomain({
        siteId: siteId ? siteId : state.site.id,
        domain: domain,
      })
    },
    async [actions.FETCH_PREFERENCES]({ commit }) {
      return v1Sites.fetchPreferences().then(({ data }) => {
        commit(mutations.SET_PREFERENCES, data)
      })
    },
    async [actions.SAVE_PREFERENCE]({ commit, state }, { name, siteId }) {
      return v1Sites.savePreference({ name }, siteId ? siteId : state.site.id).then(({ data }) => {
        return (data)
      })
    },
    async [actions.UPDATE_PREFERENCE]({ commit, state }, { name, siteId }) {
      return v1Sites.updatePreference({ name }, siteId ? siteId : state.site.id).then(({ data }) => {
        return (data)
      })
    },
    async [actions.SAVE_GEN_AI_SITE]({ state }, { selectedIntents, selectedIntentGoals }) {

      let siteSnapshot = await db.ref(state.site.firebaseRef).once('value') // current site's snapshot

      let siteKey = siteSnapshot.key // current site's ID in firebase

      let fbSiteObj = siteSnapshot.val()

      let genAiSiteData = {
        intents: selectedIntents,
        intentGoals: selectedIntentGoals,
        data: {
          isGridbuilder: true,
          ...fbSiteObj,
        }
      }

      // saves the replica of current site to GEN_AI_TEMPLATES_PATH with the same key as current site
      await db.ref(GEN_AI_TEMPLATES_PATH).child(siteKey).set(genAiSiteData)


      // saves the GenAI site's reference in preferences of current site in Firebase
      await db.ref(state.site.firebaseRef).child('preferences/genAI/siteRef').set(`${GEN_AI_TEMPLATES_PATH}/${siteKey}`)
    },
    async [actions.DELETE_GEN_AI_SITE]({ state }) {

      let siteSnapshot = await db.ref(state.site.firebaseRef).once('value')

      let siteKey = siteSnapshot.key // current site's ID in firebase

      // remove the replica of current site from GEN_AI_TEMPLATES_PATH which was saved with the same key as current site
      await db.ref(GEN_AI_TEMPLATES_PATH).child(siteKey).remove()


      // deletes the GenAI site's reference in preferences of current site in Firebase
      await db.ref(state.site.firebaseRef).child('preferences/genAI/siteRef').remove()
    },
  },
}

<template>
    <span>
      <div class="row mt-4">
        <div class="col">
          <strong> {{ $t("elements.properties.background.size") }} </strong>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <cvt-radio-group :key="backgroundSizeVal" :initial-value="backgroundSizeVal" orientation='horizontal' >
            <cvt-radio-button size="sm" value="auto" @radioClick="updateBackground">
              {{ $t("elements.properties.background.original") }}
            </cvt-radio-button>
            <cvt-radio-button size="sm" value="cover" @radioClick="updateBackground">
              {{ $t("elements.properties.background.cover") }}
            </cvt-radio-button>
            <cvt-radio-button size="sm" value="contain" @radioClick="updateBackground">
              {{ $t("elements.properties.background.box") }}
            </cvt-radio-button>
          </cvt-radio-group>
        </div>
      </div>

      <div v-if="OrgName !== orgNameFreshWork" class="divider"/>
      
      <div class="row mt-4">
        <div class="col">
          <strong> {{ $t("elements.properties.background.repeat") }} </strong>
        </div>
        <div class="col-3">
            <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="isRepeatOn"
              @switchToggle="() => repeatToggle()"
            ></cvt-switch>
        </div>
      </div>
    </span>

</template>

<script>
import * as _ from 'lodash'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import { mapGetters, mapActions, mapState } from 'vuex'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../../../storybook/components/constants'
  
export default {
  props: {
    section: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
      defaultBackgroundSize: 'cover',
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD
    }),   
    isRepeatOn() {
      return this.selected.background.repeat == 'repeat'
    },
    backgroundSizeVal(){
      return this.backgroundSize || this.defaultBackgroundSize;
    },
    backgroundRepeat: {
      get() {
        return this.selected.background.repeat
      },
      set(value) {
        return this.updateProp({ key: 'background/repeat', value })
      }
    },
    backgroundSize: {
      get() {
        return this.selected.background.size
      },
      set(value) {
        return this.updateProp({ key: 'background/size', value })
      }
    },
  },
  mounted (){
    //As required
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    openAssetManager () {
      this.initAssetManager(this.hook)
    },
    updateBackground( key ){
      this.backgroundSize = key || this.defaultBackgroundSize;     
    },
    repeatToggle(){
      this.backgroundRepeat = this.backgroundRepeat == 'no-repeat' ? 'repeat' : 'no-repeat'
    }
  }
}
</script>

<template>
  <div identity="BackgroundColorOverlay">
    <div class="row">
      <div class="col">
        <strong>{{ $t("elements.properties.color.overlay") }}</strong>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="isOverlayOn"
          @switchToggle="() => repeatToggle()"
        />
      </div>
    </div>

    <colors
      v-if="bgOverlay" 
      alpha 
      :mode="mode" 
      :custom-options="customOptions"        
      @apply-color="applyBackgroundColor" 
      @apply-transparency="applyTransparency"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import { mapGetters, mapActions } from 'vuex'
import Colors from '../theme/Colors.vue'
  
export default {
  components: {
    Colors
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
  },
  data () {
    return {
      defaultTransparency: 0.9
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER
    }),
    background: {
      get() {
        return _.get(this, 'selected.background', {})
      },
      set(value) {
        return this.updateProp({ key: 'background', value: value })
      }
    },
    bgOverlay: {
      get() {
        return _.get(this, 'selected.background.overlay', false)
      },
      set(value) {
        return this.updateProp({ key: 'background/overlay', value: value })
      }
    },
    bgColorOverlay: {
      get() {
        return _.get(this, 'selected.background.coloroverlay', "")
      },
      set(value) {
        return this.updateProp({ key: 'background/coloroverlay', value: value })
      }
    },
    bgColorAlpha: {
      get() {
        return _.get(this, 'selected.background.coloralpha', this.defaultTransparency)
      },
      set(value) {
        return this.updateProp({ key: 'background/coloralpha', value: value })
      }
    },
    isOverlayOn() {
      return this.bgOverlay;
    },
    customOptions() {
      return { 
        bindSelected: false, 
        transparency: this.bgColorAlpha, 
        activeColor: this.bgColorOverlay
      };
    }
  },
  mounted() {
    this.bgColorAlpha = this.bgColorAlpha || this.defaultTransparency;
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    repeatToggle() {

      let background = {
        ...this.background,
        overlay: !this.bgOverlay,
        coloroverlay: this.bgColorOverlay,
        coloralpha: this.bgColorAlpha,
      }

      this.background = background
    },
    applyBackgroundColor( options ) {
      this.bgColorOverlay = options.colorId;
    },
    applyTransparency( val ) {
      this.bgColorAlpha = val;
    }
  }
}
</script>

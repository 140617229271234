
<div identity="advance-side-menu-wrap">
  <cvt-properties-sidebar
    identity="cvt-properties-sidebar"
    :mode="mode"
    :show-sidebar="isGridBuilder ? true : showSidebar"
    :prepend-chevron="prependChevron"
    :variant="mappedName"
    :show-save-button="isBlock"
    :show-bookmark-button="isBlock"
    :grid-builder-enabled="isGridBuilder"
    :type="OrgName"
    :special-style-bg-color="isSpecialStyle"
    :allow-back-action="['CardItem']"
    @closeSidebar="() => toggle(false)"
    @delete="remove"
    @duplicate="duplicate"
    @save="showSaveBlock"
    @bookmark="showBookmarkBlock"
    @back="backAction"
  >
    <Block v-if="activeMenuOption === 'Block'" v-bind="componentProps" />
    <Row v-else-if="activeMenuOption === 'Row'" v-bind="componentProps" />
    <Column v-else-if="activeMenuOption === 'Column'" v-bind="componentProps" />

    <CImage v-else-if="activeMenuOption === 'CImage'" v-bind="componentProps" />
    <CForm v-else-if="activeMenuOption === 'CForm'" v-bind="componentProps" />
    <Draft v-else-if="activeMenuOption === 'Draft'" v-bind="componentProps" />

    <CButton v-else-if="activeMenuOption === 'CButton'" v-bind="componentProps" />
    <Divider v-else-if="activeMenuOption === 'Divider'" v-bind="componentProps" />
    <CVideo v-else-if="activeMenuOption === 'CVideo'" v-bind="componentProps" />

    <Icon v-else-if="activeMenuOption === 'Icon'" v-bind="componentProps" />
    <Layers v-else-if="activeMenuOption === 'Layers'" v-bind="componentProps" />
    <BrandLogo v-else-if="activeMenuOption === 'BrandLogo'" v-bind="componentProps" />

    <CodeSnippet v-else-if="activeMenuOption === 'CodeSnippet'" v-bind="componentProps" />
    <BuyNowButton v-else-if="activeMenuOption === 'BuyNowButton'" v-bind="componentProps" />
    <CSurvey v-else-if="activeMenuOption === 'CSurvey'" v-bind="componentProps" />
    <CountdownTimer v-else-if="activeMenuOption === 'CountdownTimer'" v-bind="componentProps" />
    <Checkout v-else-if="activeMenuOption === 'Checkout'" v-bind="componentProps" />
    
    <GridBoxContainer v-else-if="activeMenuOption === 'GridBoxContainer'" v-bind="componentProps" />
    <SubGridContainer v-else-if="activeMenuOption === 'SubGridContainer'" v-bind="componentProps" />
    <CheckoutPayment v-else-if="activeMenuOption === 'CheckoutPayment'" v-bind="componentProps" />
    <CheckoutThankYou v-else-if="activeMenuOption === 'CheckoutThankYou'" v-bind="componentProps" />
    <CCardOptions v-else-if="activeMenuOption === 'CCard'" v-bind="componentProps" />
    <CCardItemOptions v-else-if="activeMenuOption === 'CardItem'" v-bind="componentProps" />
    <CEmbeddedForm v-else-if="activeMenuOption === 'CEmbeddedForm'" v-bind="componentProps" />

  </cvt-properties-sidebar>
</div>

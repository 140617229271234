<template>
  <div>
    <div class="d-flex">
      <strong class="mr-2 mb-3">{{ $t('elements.property.edit.icon.image.label') }}</strong>
      <cvt-switch
        size="xs"
        color="active"
        type="circular"
        description-type="default"
        :initial-state="enableIcon"
        :mode="mode"
        @switchToggle="() => onSwitchToggle('enableIcon')"
      />
    </div>
    <div :class="{ 'pointer-events-none': !enableIcon }">
      <div class="row mb-3">
        <div class="col-auto mx-auto">
          <cvt-switch
            size="md"
            color="grey"
            type="rounded"
            description-type="text"
            inactive-text="Icon SVG"
            active-text="Use Image"
            :initial-state="useIconAsImage"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('useIconAsImage')"
          />
        </div>
      </div>

      <div v-if="useIconAsImage" id="image-settings">
        <element-image-manager caption-key="template/icon/caption" image-key="template/icon/src"  :hook="selected.updateIconImgSrc"></element-image-manager>
        <div class="row mt-4 mb-4 d-flex justify-content-between">
          <div class="col text-capitalize">
            Image Width
          </div>
          <div class="col-12">
            <resize :step="1" :min="1" :max="20" size-key="template/icon/imageWidth" hide-label></resize>
          </div>
        </div>
        <div class="divider"></div>
        <br>
      </div>
      <div v-if="!useIconAsImage" id="icon-settings" >
        <div class="row">
          <div class="col">
            <icon-manager></icon-manager>
          </div>
        </div>
        <div class="divider"></div>
        <br>
        <div class="row">
          <div class="col">
            <h6 :class="textColorClass" class="m-0">Resize</h6>
          </div>
          <div class="col-12">
            <resize size-key="template/icon/size" :min="1" :step="1" :max="20" :hide-label="true"></resize>
          </div>
        </div>
        <div class="divider"></div>
        <br>

        <div class="row">
          <div v-if="isGridBuilder" class="col-12">
            Background
            <colors
              :to-bg="true"
              :custom-options="{bindSelected: false}"
              :mode="mode"
              @apply-color="(value) => applyIconColor('template/icon/themeColorId', value)"></colors>
          </div>
          <div class="col-12">
            Foreground
            <colors
              :custom-options="{bindSelected: false}"
              :mode="mode"
              @apply-color="(value) => applyIconColor('template/icon/foreground/themeColorId', value)"></colors>
          </div>
        </div>

        <div class="divider mt-2" />
        <div class="nestedInnerItem">
          <cvt-select
            :value="IconBorderStyle"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            :label="$t('elements.properties.border.style')"
            @input="(value) => updateBorderProps('template/icon/borderStyle', value)"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px;" />
          <cvt-input
            v-model="IconBorderColor"
            style="width: max-content;"
            type="text"
            :clearable="false"
            :label="$t('elements.properties.border.color')"
          />
        </div>

        <div v-if="isGridBuilder" class="divider mt-2" />

        <div v-if="isGridBuilder" class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t("elements.properties.border.width") }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="0"
              :max="10"
              :value="IconBorderWidth"
              :step="1"
              @change="(value) => updateBorderProps('template/icon/borderWidth', value)"
            />
          </div>
        </div>
        <div class="divider mt-2" />
      </div>
      <div v-if="isGridBuilder" class="row">
        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t("elements.properties.border.radius") }}
        </div>
        <div class="col-12 mb-3">
          <cvt-slider
            id="slider-default"
            :tooltips-enabled="true"
            :step-marks-enabled="true"
            class="slider"
            :min="0"
            :max="100"
            :value="IconBorderRadius"
            :step="5"
            @change="(value) => updateBorderProps('template/icon/borderRadius', value)"
          />
        </div>
      </div>

      <div v-if="isGridBuilder && useIconAsImage" class="divider mt-2" />

      <div v-if="isGridBuilder && useIconAsImage" class="row mt-4 d-flex justify-content-between">
        <div class="col">
          <h6>{{ $t("element.property.label.crop_image") }}</h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="hideImgOverflow"
            :mode="mode"
            @switchToggle="(status) => updateImageCrop(status)"
          ></cvt-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementImageManager from "@/js/components/editor/advanced-side-menu/ElementImageManager.vue";
import Resize from "@/js/components/editor/advanced-side-menu/Resize.vue";
import IconManager from "@/js/components/editor/advanced-side-menu/IconManager.vue";
import Colors from "@/js/components/editor/theme/Colors.vue";
import * as _ from "lodash";
import { mapGetters, mapState, mapActions } from "vuex";
import * as getters from "@/js/store/modules/getters";
import * as actions from "@/js/store/modules/actions";
import { LEFT_SIDEBAR_FRESHWORKS } from "../../../../../../../storybook/components/constants";

export default {
  name: "IconImageOptions",
  components: { Colors, IconManager, Resize, ElementImageManager },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data () {
    return {
      borderStyles: ['none', 'solid', 'dashed', 'dotted', 'double', 'groove'],
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
    }),
    IconBorderStyle: {
      get() {
        return _.get(this, 'selected.template.icon.borderStyle', "")
      },
    },
    enableIcon: {
      get() {
        return _.get(this, 'selected.template.enableIcon', true)
      },
      set(value) {
        return this.updateProp({ key: 'template/enableIcon', value })
      },
    },
    hideImgOverflow: {
      get () {
        return _.get(this, 'selected.template.icon.hideImgOverflow', false)
      },
      set (value) {
        return this.updateProp({ key: 'template/icon/hideImgOverflow', value })
      }
    },
    useIconAsImage: {
      get() {
        return _.get(this, 'selected.template.useIconAsImage', false)
      },
      set(value) {
        return this.selected.updateProp('template/useIconAsImage', value)
      },
    },
    IconBorderWidth: {
      get() {
        return _.get(this, 'selected.template.icon.borderWidth', 0)
      },
    },
    IconBorderRadius: {
      get() {
        return _.get(this, 'selected.template.icon.borderRadius', 0)
      },
    },
    IconBorderColor: {
      get() {
        return _.get(this, 'selected.template.icon.borderColor', "")
      },
      set: _.throttle(function ({ target }) {
        this.selected.updateProp('template/icon/borderColor', target.value)
      }, 300),
    },
    textColorClass() {
      return { 'text-white': this.mode === 'DARK' }
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    updateBorderProps: _.throttle(function (key, value) {
      return this.selected.updateProp(key, value)
    }, 300),
    applyIconColor (key, { colorId, toBg }) {
      this.selected.updateProp(key, colorId)
    },
    onSwitchToggle (property) {
      this[property] = !this[property]
    },
    updateImageCrop (status) {
      if(status === 'Active') {
        this.hideImgOverflow = true
      } else if (status === 'Inactive') {
        this.hideImgOverflow = false
      }
    },
  }
};
</script>

<style scoped>

</style>
import * as actions from "../actions";
import * as mutations from "../mutations";
import { relativePath } from "../../services/firebase.service";
import { database } from "@/js/store/services/firebase.service";
import { createRecord, getKey, indexForKey } from "../delta";
import * as getters from "@/js/store/modules/getters";
import * as _ from "lodash";

const db = database()

export default {
  state: {
    key: null,
    dRef: null,
    vdom: [],
    ready: false,
    pageFirebaseRefKey: null,
    disableHeader: false
  },
  getters: {
    [getters.HEADER_DELTA_VDOM]({ vdom }) {
      return vdom
    },
    [getters.HEADER_DISABLED]({ disableHeader }) {
      return disableHeader
    },
    [getters.HEADER_DELTA_KEY]({ key }) {
      return key
    },
    [getters.HEADER_DELTA_REF] ({ dRef }) {
      return dRef
    },
    [getters.HEADER_DELTA_VDOM_READY] ({ ready }) {
      return ready
    },

    [getters.HEADER_DELTA_PATH]({ dRef, vdom }, rootGetters) {
      return (path) => {
        if (!dRef || !vdom.length || !path) return []

        if (path && !path.startsWith('/header')) {
          return rootGetters[getters.DELTA_PATH](path)
        }
        return _.get(
          vdom,
          path.split('/').slice(relativePath(dRef).split('/').length),
        )
      }
    }
  },
  mutations: {
    [mutations.HEADER_DELTA_SET_KEY](state, key) {
      state.key = key
    },

    [mutations.HEADER_DELTA_ADDED] ({ vdom }, { snap, prevKey }) {
      const index = prevKey ? indexForKey(vdom, prevKey) + 1 : 0
      vdom.splice(index, 0, createRecord(snap))
    },

    [mutations.HEADER_DELTA_CHANGED] ({ vdom }, { snap }) {
      const index = indexForKey(vdom, getKey(snap))
      vdom.splice(index, 1, createRecord(snap))
    },

    [mutations.HEADER_DELTA_MOVED] ({ vdom }, { snap, prevKey }) {
      const index = indexForKey(vdom, getKey(snap))
      const record = vdom.splice(index, 1)[0]
      const newIndex = prevKey ? indexForKey(vdom, prevKey) + 1 : 0
      vdom.splice(newIndex, 0, record)
    },

    [mutations.HEADER_DELTA_REMOVED] ({ vdom }, { snap }) {
      const index = indexForKey(vdom, getKey(snap))
      vdom.splice(index, 1)
    },
  },
  actions: {
    async [actions.HEADER_DELTA_INIT]({ state, commit, dispatch, rootGetters }, pageId) {
      if (state.dRef) {
        dispatch(actions.HEADER_DELTA_RESET)
      }
      const { GRID_BUILDER_HEADER_FOOTER_ENABLED } = rootGetters[getters.AUTH_GET_USER_CONFIG]
      const isGridBuilder =  rootGetters[getters.SITE_HAS_GRIDBUILDER]

      if (!GRID_BUILDER_HEADER_FOOTER_ENABLED || !isGridBuilder) {
        dispatch(actions.HEADER_DELTA_READY)
        return
      }

      const { firebaseRef } = rootGetters[getters.SITE_GET_DATA]
      await commit(mutations.HEADER_DELTA_SET_KEY, `${firebaseRef}/header/vdom`)

      state.dRef = db.ref(state.key)

      console.debug('header delta path', relativePath(state.dRef))

      await state.dRef.once('value').then(() => {
        setTimeout(() => {
          state.ready = true
          dispatch(actions.HEADER_DELTA_READY)
        }, 1200)
      })

      dispatch(actions.HEADER_DELTA_LISTEN)
      dispatch(actions.HEADER_DELTA_DISABLED_CHECK, pageId)
    },
    [actions.HEADER_DELTA_LISTEN] ({ state, dispatch, commit }) {
      state.dRef.on('child_added', (snap, prevKey) => {
        commit(mutations.HEADER_DELTA_ADDED, { snap, prevKey })
        commit('revisions/updatePageChanged')
      })

      state.dRef.on('child_changed', (snap) => {
        commit(mutations.HEADER_DELTA_CHANGED, { snap })
        commit('revisions/updatePageChanged')
      })

      state.dRef.on('child_moved', (snap, prevKey) => {
        commit(mutations.HEADER_DELTA_MOVED, { snap, prevKey })
        commit('revisions/updatePageChanged')
      })

      state.dRef.on('child_removed', (snap) => {
        commit(mutations.HEADER_DELTA_REMOVED, { snap })
        commit('revisions/updatePageChanged')
      })
    },
    async [actions.HEADER_DELTA_DISABLED] ({state, dispatch, commit}, value) {
      if (typeof value != "boolean") {
        throw 'Invalid Type'
      }
      await db.ref(state.pageFirebaseRefKey).set(value)
      state.disableHeader = value
    },
    async [actions.HEADER_DELTA_DISABLED_CHECK] ({state, dispatch, commit, rootGetters}, pageId) {

      const page = rootGetters[getters.SITE_GET_ACTIVE_PAGE](pageId)
      state.pageFirebaseRefKey = `${page.firebaseRef}/disableHeader`

      const ref = db.ref(state.pageFirebaseRefKey)
      ref.on('value', snap => {
        const disableHeader = snap.val()
        if (disableHeader === undefined || disableHeader === null) {
          ref.off('value')
          db.ref(state.pageFirebaseRefKey).set(false)
          return
        }
        state.disableHeader = disableHeader
        ref.off('value')
      })
    },
    [actions.HEADER_DELTA_IGNORE] ({ state }) {
      state.dRef.off('child_added')
      state.dRef.off('child_changed')
      state.dRef.off('child_moved')
      state.dRef.off('child_removed')
    },
    [actions.HEADER_DELTA_RESET] ({ state, commit, dispatch }) {
      state.vdom = []
      state.ready = false
      commit(mutations.HEADER_DELTA_SET_KEY, null)
      dispatch(actions.HEADER_DELTA_IGNORE)
    },
  },
}

<script lang="jsx">
import {cloneVDomFromDelta} from '../lib/vue-dom-obj'
import {mapActions, mapGetters, mapMutations} from "vuex";
import * as getters from '@/js/store/modules/getters'
import * as actions from '@/js/store/modules/actions'
import {HeaderBlock} from "@/js/lib/helper-classes";
import HeaderBlockUI from "./HeaderBlock.vue";
import {PAGE_ENGINE_VDOM_UPDATE} from "@/js/plugins/events";
import {AddSubNodeCommand} from "@/js/commands/AddSectionCommand";
import * as mutations from "@/js/store/modules/mutations";


export default {
  name: "HeaderEngineV3",
  components: {
    HeaderBlockUI,
  },
  props: {
    page: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      name: 'HeaderEngine',
      clonedVDom: [],
      isReload: false,
      isLoading: false,
      setInsertCallbackSet: false
    }
  },
  computed: {
    ...mapGetters({
      vdom: getters.HEADER_DELTA_VDOM,
      site: getters.SITE_GET_DATA,
      viewMode: getters.VIEW_MODE,
      domReady: getters.HEADER_DELTA_VDOM_READY,
      rootKey: getters.HEADER_DELTA_KEY,
      headerDisabled: getters.HEADER_DISABLED,
      sectionOpen: getters.LEFT_SIDE_SECTION_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER
    }),
  },
  watch: {
    viewMode: {
      handler (newVal) {
        setTimeout(()=> {
          this.$nextTick(() => {
            this.$mount()
            this.clonedVDom = [...this.clonedVDom]
          })
        }, 200)
      },
    },
    domReady: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.buildDOM()
          this.isLoading = false
        }
      },
    },
  sectionOpen: {
    handler (newVal, oldVal) {
      this.isLoading = newVal
      if (!newVal && this.setInsertCallbackSet) {
        this.setInsertCallback(null)
        this.setInsertCallbackSet = false
        this.forceBuildDOM()
      }
    },
  }
  },
  mounted() {
    this.$hub.$on(PAGE_ENGINE_VDOM_UPDATE, this.$hubEventHandler)

    this.initHeader(this.page.id) // init footer vdom fetching
  },
  methods: {
    ...mapMutations({
      setInsertCallback: mutations.QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER
    }),
    ...mapActions({
      leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE,
      pushCommand: actions.HISTORY_COMMAND_PUSH,
      initHeader:actions.HEADER_DELTA_INIT,
    }),
    buildDOM () {
      this.clonedVDom = cloneVDomFromDelta(this.vdom)
      this.isLoading = false
    },
    forceBuildDOM () {
      this.isReload = true
      this.buildDOM()
      this.$nextTick(() => {
        this.isReload = false
      })
    },
    $hubEventHandler(data ) {
      if (data.forceBuild) {
        this.forceBuildDOM()
        return
      }
      this.buildDOM()
    },
    async createNewHeader() {
      if (this.isLoading) return

      this.isLoading = true

      await this.pushCommand(
          new AddSubNodeCommand(this.rootKey, new HeaderBlock())
      )
    },
    loadPreMadeHeader (event) {
      if (this.isLoading) return

      event.preventDefault()
      event.stopPropagation()

      this.setInsertCallback(this.preMadeInsertCallback)
      this.setInsertCallbackSet = true
      this.leftSideMenuToggle({ activate: 'sections', context: {isHeaderBlock: true}});
    },
    async preMadeInsertCallback(vnodes) {
      vnodes.tag = 'header-block'
      vnodes.data.props.isHeaderBlock = true
      this.isLoading = true
      await this.pushCommand(
          new AddSubNodeCommand(this.rootKey, vnodes)
      )
      this.isLoading = false
    }
  },
  render(h) {
    if (!(this.config.GRID_BUILDER_HEADER_FOOTER_ENABLED && this.isGridBuilder)) {
        // Return Null if feature is not enabled
        return null
    }
    // We only expect cloneVDOM to have one Node available
    if (this.clonedVDom.length === 0 || this.isReload) {
      return (
          <header class="d-flex justify-content-center empty-header-footer">
            <div class="d-flex py-4">
              <cvt-button color="primary" size={'sm'} class="mr-2" text={this.$t('header.engine.start_blank_header_text')} disabled={this.isLoading} onClick={this.createNewHeader}></cvt-button>
              <cvt-button color={'light'} outlined={true} size={'sm'} class="ml-2" text={this.$t('header.engine.select_premade_header_text')}
                          disabled={this.isLoading} onClick={this.loadPreMadeHeader}></cvt-button>
            </div>
          </header>
      )
    }

    const getProps = (x) => {
      return {...x.node.data.props, vDomPath: this.rootKey, fbNode: x.node, blockDisabled: this.headerDisabled}
    }

    return (
        <div>
          {this.clonedVDom.map(x =>
              <HeaderBlockUI
                  key={x.key}
                  {...{ props:  getProps(x)}}
              />
          )}
        </div>
    )
  }
}
</script>
<style>
.empty-header-footer {
  background: #E2E6ED;
  border: 2px dashed #BCC2CB;
  border-radius: 8px;
  height: 5.125rem;
}
</style>

<template>
  <div class="color-spectrum">
    <div class="color-spectrum__wrapper">
      <img
        class="color-spectrum__palette"
        :src="spectrum"
        alt="spectrum"
        draggable="false"
        @load="init"
      />
      <div
        class="color-spectrum__barrier"
        :style="cursor"
        @mousedown="captureFromWheelEvent"
      >
        <drag-marker
          ref="colorMarker"
          classes="color-spectrum__marker"
          containment
          @dragmove="colorMove"
        ></drag-marker>
      </div>
    </div>
    <div
      class="color-spectrum__value"
      :style="darknessSliderBg"
      @mousedown="captureFromValueEvent"
    >
      <drag-marker
        ref="darknessMarker"
        classes="color-spectrum__marker color-spectrum__marker--slider"
        containment
        @dragmove="darknessMove"
      ></drag-marker>
    </div>

    <div class="color-spectrum__code">
      <div class="color-spectrum__preview" :style="previewBg"></div>

      <div class="color-spectrum__hex-wrapper">
        <small>{{ $t('element.properties.color.hex') }}:</small>
        <input v-model="hex" type="text" class="color-spectrum__hex" />
        <input
          :value="darkness.toFixed(2)"
          type="number"
          class="color-spectrum__hex"
          style="margin: 3px 0"
          step="0.01"
          min="0"
          max="1"
          @input="onChangeDarkness"
        />
      </div>
    </div>
  </div>
</template>

<script>
import color from 'color'
import DragMarker from './DragMarker.vue'
import Draggabilly from 'draggabilly'

export default {
  components: {
    DragMarker,
  },
  props: {
    value: {
      type: String,
      default() {
        return ''
      },
    },
    currentColor: {
      type: Array,
      default: () => [255, 255, 255],
    },
    valpha: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      spectrum: '/img/material-spectrum.png',
      wheelColor: color({
        r: this.currentColor[0],
        g: this.currentColor[1],
        b: this.currentColor[2],
      }).alpha(this.valpha),
      darkness: this.valpha,
      enabled: false,
      markerPos: {},
    }
  },
  computed: {
    color() {
      // return this.wheelColor.darken(this.darkness)
      return this.wheelColor
    },
    cursor() {
      return {
        cursor: this.enabled ? 'move' : null,
      }
    },
    previewBg() {
      this.$emit('color-changed', this.color.hex().toLowerCase())
      return {
        backgroundColor: this.color.hsl().string(),
      }
    },
    darknessSliderBg() {
      return {
        backgroundColor: this.wheelColor.hsl().string(),
      }
    },
    hex: {
      get() {
        return this.wheelColor.hex().toLowerCase()
      },
      set(value) {
        this.updateColorSpectrumValue(value, true)
      },
    },
  },
  watch: {
    color() {
      this.$emit('change', this.color)
    },
  },
  mounted() {
    let calcXVal = this.mapValueToBar(this.darkness)
    let position = { x: calcXVal }

    this.$refs.darknessMarker.updatePosition(position)
  },

  methods: {
    captureFromWheelEvent(e) {
      let position = {
        x: e.offsetX,
        y: e.offsetY,
      }

      this.caputrePixelData(position)
      this.$refs.colorMarker.updatePosition(position)
      this.$refs.colorMarker.startDrag(e)

      const [r, g, b] = this.wheelColor.color
      this.wheelColor = color({ r, g, b }).alpha(this.darkness)
    },
    captureFromValueEvent(e) {
      let position = { x: e.offsetX }
      this.$refs.darknessMarker.updatePosition(position)
      this.$refs.darknessMarker.startDrag(e)

      const [r, g, b] = this.wheelColor.color

      let markerSize = this.$refs.darknessMarker.size()
      let parentSize = this.$refs.darknessMarker.parentSize()

      const calcDarkness = position.x / (parentSize.width - markerSize.width)
      const newDarkness = calcDarkness >= 1 ? Math.floor(calcDarkness) : calcDarkness

      this.setDarkness(newDarkness)
      this.wheelColor = color({ r, g, b }).alpha(newDarkness)
    },
    caputrePixelData({ x, y }) {
      let [r, g, b, a] = this.canvas
        .getContext('2d')
        .getImageData(x, y, 1, 1).data
      this.wheelColor = color({ r, g, b }).alpha(a)
    },
    init({ target }) {
      this.canvas = document.createElement('canvas')
      this.canvas.width = target.width
      this.canvas.height = target.height
      this.canvas
        .getContext('2d')
        .drawImage(target, 0, 0, target.width, target.height)
      this.marker = this.$el.querySelector('.color-spectrum__marker')
    },
    colorMove({ position }) {
      let { x, y } = position
      // if (!this.pointInCircle(position)) return

      let [r, g, b, a] = this.canvas
        .getContext('2d')
        .getImageData(x, y, 1, 1).data
      this.wheelColor = color({ r, g, b }).alpha(this.darkness)
    },
    darknessMove({ position }) {
      let { x, y } = position
      let markerSize = this.$refs.darknessMarker.size()
      let parentSize = this.$refs.darknessMarker.parentSize()
      this.setDarkness(x / (parentSize.width - markerSize.width))

      this.$emit('color-changed', this.color.hex().toLowerCase())

      const [r, g, b] = this.wheelColor.color

      this.wheelColor = color({ r, g, b }).alpha(
        x / (parentSize.width - markerSize.width),
      )
    },
    setDarkness(percent) {
      this.darkness = percent
    },
    onChangeDarkness(e) {
      let x = +e.target.value
      let calcXVal = this.mapValueToBar(x)
      let position = { x: calcXVal }

      this.$refs.darknessMarker.updatePosition(position)
      this.setDarkness(x)
      this.$emit('color-changed', this.color.hex().toLowerCase())

      const [r, g, b] = this.wheelColor.color
      this.wheelColor = color({ r, g, b }).alpha(x)
    },
    mapValueToBar(value) {
      // Clamp value to 0-1 range (optional, handles potential edge cases)
      value = Math.max(0, Math.min(value, 1))
      // Scale the value to 0-225 range / using 240 range spans out of slider
      return value * 225
    },
    updateColorSpectrumValue(value, pushChange) {
      value = value || ''

      if (value.includes('#') === false) {
        value = `#${value}`
      }

      if (value.length === 7) {
        this.setColor(color(value))
      }
    },
    setColor(color) {
      this.wheelColor = color
    },
  },
}
</script>

<style lang="scss">
.color-view {
  width: 200px;
  height: 200px;
}

.color-spectrum {
  position: relative;
  display: inline-block;
  user-select: none;

  // background-color: white;
  color: #3f4652;
  // border-radius: 8px;
  // box-shadow: 0 0 0 2px rgba(63,70,82,.15);

  &__palette {
    width: 240px;
    // height: 200px;
  }

  &__marker {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 100px;
    top: 100px;
    margin: -10px;
    border: 2px solid #fff;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.333);
    cursor: move;

    &--slider {
      width: 20px;
      height: 20px;
    }
  }

  &__value {
    // width: 200px;
    height: 20px;
    border-radius: 3px;
    background-image: linear-gradient(to right, transparent, #000);
    cursor: crosshair;
    position: relative;
    margin-top: 5px;

    .color-spectrum__marker {
      left: 0;
      top: 0;
      margin: 0;
      cursor: ew-resize;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__barrier {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    // width: 200px;
    // height: 200px;
    // border-radius: 100px;

    background-size: cover;
    cursor: crosshair;
    // border-radius: 200px;
  }

  &__hex {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: none;
    // margin: 0 0 0 10px;
    font-weight: 300;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.15);
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    text-indent: 8px;
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    // margin-top: 5px;
  }

  &__preview {
    // width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 5px;
    flex-grow: 1;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.05),
      inset -1px -1px 0 rgba(0, 0, 0, 0.05);
  }

  &__code {
    display: flex;
    flex-basis: 0;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
  }

  &__hex-wrapper {
    small {
    }
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
</style>

import * as _ from "lodash";
import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";
import { relativePath } from "../services/firebase.service";
import { database } from "@/js/store/services/firebase.service";

const db = database()

// const _getters = getters

// ensure we talk about in the future
// binding to these callbacks so that we
// can notify the user they do not have
// permission to perform the actions they are
// attempting this will happen if someone removes
// them from the authorized users list
// cancelCallback = source.cancelCallback
// readyCallback = source.readyCallback
// afterSave
/**
 * Class Delta
 * creates a new delta object from a firebase snapshot
 */
// class Delta {
//   constructor (snap, prevKey) {
//     this.snap = snap
//     this.key = snap.key
//     this.path = snap.ref.path.toString()
//     this.val = snap.val()
//     this.prevKey = prevKey
//   }
// }

export function getKey(snapshot) {
  return typeof snapshot.key === 'function' ? snapshot.key() : snapshot.key
}

export function isObject(val) {
  return Object.prototype.toString.call(val) === '[object Object]'
}

export function indexForKey(array, key) {
  for (let i = 0; i < array.length; i++) {
    if (array[i]['.key'] === key) {
      return i
    }
  }
  /* istanbul ignore next */
  return -1
}

export function createRecord(snapshot) {
  const value = snapshot.val()
  const res = isObject(value) ? value : { '.value': value }
  res['.key'] = getKey(snapshot)
  res['.path'] = relativePath(snapshot.ref)
  return res
}

class DeltaLoadingTracker {
  constructor() {
    this.sectionsLoaded = false
    this.headerLoaded = false
    this.footerLoaded = false
  }

  set popupLoaded(value) {
    this.footerLoaded = this.headerLoaded = this.sectionsLoaded = value
  }

  get isDeltaReady() {
    return this.footerLoaded && this.headerLoaded && this.sectionsLoaded
  }
}

export default {
  state: {
    key: null,
    deltaRef: null,
    vdom: [],
    deltaReadyTracker: new DeltaLoadingTracker()
  },
  getters: {
    [getters.DELTA_VDOM]({ vdom }) {
      return vdom
    },
    [getters.DELTA_KEY]({ key }) {
      return key
    },
    [getters.DELTA_SOURCE]() {
      return db
    },
    [getters.DELTA_REF]({ deltaRef }) {
      return deltaRef
    },
    [getters.DELTA_PATH]({ deltaRef, vdom }, rootGetters) {
      return (path) => {
        if (!deltaRef || !vdom.length || !path) return []

        const { GRID_BUILDER_HEADER_FOOTER_ENABLED } = rootGetters[getters.AUTH_GET_USER_CONFIG]

        if (GRID_BUILDER_HEADER_FOOTER_ENABLED && path.startsWith('/header')) {
          return rootGetters[getters.HEADER_DELTA_PATH](path)
        }

        if (GRID_BUILDER_HEADER_FOOTER_ENABLED && path.startsWith('/footer')) {
          return rootGetters[getters.FOOTER_DELTA_PATH](path)
        }

        return _.get(
          vdom,
          path.split('/').slice(relativePath(deltaRef).split('/').length),
        )
      }
    },
  },
  mutations: {
    [mutations.DELTA_SET_KEY](state, key) {
      state.key = key
    },

    [mutations.DELTA_ADDED]({ vdom }, { snap, prevKey }) {
      const index = prevKey ? indexForKey(vdom, prevKey) + 1 : 0
      vdom.splice(index, 0, createRecord(snap))
    },

    [mutations.DELTA_CHANGED]({ vdom }, { snap }) {
      const index = indexForKey(vdom, getKey(snap))
      vdom.splice(index, 1, createRecord(snap))
    },

    [mutations.DELTA_MOVED]({ vdom }, { snap, prevKey }) {
      const index = indexForKey(vdom, getKey(snap))
      const record = vdom.splice(index, 1)[0]
      const newIndex = prevKey ? indexForKey(vdom, prevKey) + 1 : 0
      vdom.splice(newIndex, 0, record)
    },

    [mutations.DELTA_REMOVED]({ vdom }, { snap }) {
      const index = indexForKey(vdom, getKey(snap))
      vdom.splice(index, 1)
    },
  },
  actions: {
    [actions.DELTA_INIT]({ state, commit, dispatch, getters }, opts) {
      if (state.deltaRef) {
        dispatch(actions.DELTA_RESET)
      }

      commit(mutations.DELTA_SET_KEY, opts.key)
      state.deltaRef = db.ref(state.key)
      console.debug('delta path', relativePath(state.deltaRef))
      state.deltaRef.once('value').then(() => {
        setTimeout(() => {
          state.deltaReadyTracker.sectionsLoaded = true
          dispatch(actions.HISTORY_SET_INITIAL_REV, state.vdom)
          dispatch(actions.SITE_INIT_PULSE_PREFERENCE)
          dispatch('revisions/init')
          commit(mutations.HISTORY_LISTEN)
          if (state.deltaReadyTracker.isDeltaReady) {
            dispatch('loader/stop', '')
          }
        }, 1200)
      })

      dispatch(actions.DELTA_LISTEN)
    },
    [actions.DELTA_INIT_STANDALONE_POPUP]({ state, commit, dispatch, getters }, opts) {
      if (state.deltaRef) {
        dispatch(actions.DELTA_RESET)
      }

      commit(mutations.DELTA_SET_KEY, opts.key)
      state.deltaRef = db.ref(state.key)
      console.debug('delta path', relativePath(state.deltaRef))

      state.deltaRef.once('value').then(() => {
        setTimeout(() => {
          state.deltaReadyTracker.popupLoaded = true
          dispatch(actions.HISTORY_SET_INITIAL_REV, state.vdom)
          dispatch(actions.SITE_INIT_PULSE_PREFERENCE)
          dispatch('revisions/initStandalonePopup')
          commit(mutations.HISTORY_LISTEN)
          if (state.deltaReadyTracker.isDeltaReady) {
            dispatch('loader/stop', '')
          }
        }, 1200)
      })

      dispatch(actions.DELTA_LISTEN)
    },
    [actions.DELTA_LISTEN]({ state, dispatch, commit }) {
      state.deltaRef.on('child_added', (snap, prevKey) => {
        commit(mutations.DELTA_ADDED, { snap, prevKey })
        commit(mutations.HISTORY_DELTA_REF_PUSH, state.vdom)
        commit('revisions/updatePageChanged')
      })

      state.deltaRef.on('child_changed', (snap) => {
        commit(mutations.DELTA_CHANGED, { snap })
        commit(mutations.HISTORY_DELTA_REF_PUSH, state.vdom)
        commit('revisions/updatePageChanged')
      })

      state.deltaRef.on('child_moved', (snap, prevKey) => {
        commit(mutations.DELTA_MOVED, { snap, prevKey })
        commit(mutations.HISTORY_DELTA_REF_PUSH, state.vdom)
        commit('revisions/updatePageChanged')
      })

      state.deltaRef.on('child_removed', (snap) => {
        commit(mutations.DELTA_REMOVED, { snap })
        commit(mutations.HISTORY_DELTA_REF_PUSH, state.vdom)
        commit('revisions/updatePageChanged')
      })
    },

    [actions.DELTA_IGNORE]({ state }) {
      state.deltaRef.off('child_added')
      state.deltaRef.off('child_changed')
      state.deltaRef.off('child_moved')
      state.deltaRef.off('child_removed')
    },
    [actions.DELTA_MANUAL_CHANGES_PUSH]({ state, commit }) {
      commit(mutations.HISTORY_DELTA_REF_PUSH, state.vdom)
    },
    [actions.DELTA_RESET]({ state, commit, dispatch }) {
      state.vdom = []
      commit(mutations.DELTA_SET_KEY, null)
      dispatch(actions.DELTA_IGNORE)
    },
    [actions.HEADER_DELTA_READY]({ state, commit, dispatch }) {
      state.deltaReadyTracker.headerLoaded = true
      if (state.deltaReadyTracker.isDeltaReady) {
        dispatch('loader/stop', '')
      }
    },
    [actions.FOOTER_DELTA_READY]({ state, commit, dispatch }) {
      state.deltaReadyTracker.footerLoaded = true
      if (state.deltaReadyTracker.isDeltaReady) {
        dispatch('loader/stop', '')
      }
    },

    [actions.DELTA_SET_CURRENT_REV]({ state, commit, dispatch }, currentRev) {
      return state.deltaRef
        .set(currentRev.map((r) => _.omit(r, ['.key', '.path'])))
        .then(() => {
          state.vdom = currentRev
        })
    },
  },
}

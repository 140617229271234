import * as mutations from './mutations'
import * as actions from './actions'
import {AddSectionCommand} from "../../commands/AddSectionCommand";

export default {
  state: {
    insert: null,
    pageEngine: null
  },
  mutations: {
    [mutations.QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER] (state, insert) {
      state.insert = insert
    },
    [mutations.QUICK_LAUNCH_BLOCK_SET_PAGE_ENGINE] (state, {pageEngine}) {
      state.pageEngine = pageEngine
    }
  },
  actions: {
    [actions.QUICK_LAUNCH_BLOCK_INSERT] ({state, commit, rootState, dispatch}, vnode) {
      if (state.insert === null) {
        if (rootState.builders.version === 'v3') {
          dispatch(actions.HISTORY_COMMAND_PUSH, new AddSectionCommand(vnode))
        }
        else {
          state.pageEngine.push(vnode)
        }
      } else {
        state.insert(vnode)
      }
      state.insert = null
    }
  }
}

// @ts-nocheck
import FirebaseNodeV3 from '../../../../components/v3/base/FirebaseNode'
import {
  cloneVDomFromDelta
} from '../../../../components/v3/lib/vue-dom-obj'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as getters from '../../../../store/modules/getters'
import * as actions from '../../../../store/modules/actions'
import * as mutations from '../../../../store/modules/mutations'
import Block from '../../editor/nodes/Block.vue'
import FbVNode from '../../../../components/base/FbVNode.vue'
import {PAGE_ENGINE_VDOM_UPDATE} from "../../../../plugins/events";
import _ from "lodash";
import { Notification } from "element-ui";

export default FbVNode.extend({
  name: 'SlideEngineV3',
  mixins: [FirebaseNodeV3],
  components: {
    block: Block,
  },
  async beforeRouteLeave(to, from, next) {
    try {
      const nodesIsValid = await this.nodesIsValid()

      if (nodesIsValid === false) {
        return next(false)
      }

      this.toggleAdvancedMenu(false)
      this.toggleLeftSideMenu({ open: false })
      this.setViewMode('desktop')
      // TODO: enable when popup thumbnail auto generation is working
      // this.updatePopupThumbnail()
      this.deselect()
      this.ignore()
      next()
    } catch (err) {
      console.error('beforeRouteLeave error', err)
      next(false)
    }
  },

  props: {
    // usually passed from router
    popup: {
      type: Object,
      required: true,
    },
    rootPath: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      name: 'PageEngine',
      clonedVDom: [],
      blockVnodeId: null,
      isReload: false
    }
  },
  computed: {
    ...mapGetters({
      cPopupData:getters.SDA_POPUP_FIREBASE_DATA,
      vdom: getters.DELTA_VDOM,
      step: getters.STEPS_CURRENT_STEP,
      viewMode: getters.VIEW_MODE,
      canUndo: getters.COMMAND_CAN_UNDO,
      canRedo: getters.COMMAND_CAN_REDO,
      historyReady: getters.HISTORY_INITIALIZED,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
    }),
    isMobile () {
      return this.viewMode === 'phone'
    },
    styles () {
      return {
        'min-height': this.cPopupData.data.props.popupHeight? this.cPopupData.data.props.popupHeight === 'auto' ? '60%': this.cPopupData.data.props.popupHeight: '50%',
        'height': this.cPopupData.data.props.popupHeight ? this.cPopupData.data.props.popupHeight: 'auto',
        width: this.cPopupData.data.props.popupWidth? this.cPopupData.data.props.popupWidth: '60%',
      }
    },
    pageEngineClass () {
      return {'isFullScreen': this.cPopupData.data.props.isFullScreen && !this.isMobile,'page-engine-viewmode--sm': this.isMobile  }
    }
  },
  watch: {
    viewMode: {
      handler(newVal) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.$mount()
            this.clonedVDom = [...this.clonedVDom]
          })
        }, 200)
      },
    },
    historyReady: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.buildDOM()
        }
      },
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => setTimeout(vm.initVdom, 500))
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      this.startLoader('Switching Pages')
      setTimeout(this.initVdom, 500)
      next()
    } catch (err) {
      console.debug('beforeRouteUpdate error', err)
      next(false)
    }
  },

  methods: {
    ...mapMutations({
      ignore: mutations.SELECTOR_IGNORE,
      setQuickLaunchEngine: mutations.QUICK_LAUNCH_BLOCK_SET_PAGE_ENGINE,
      setViewMode: mutations.VIEW_MODE_SET,
    }),
    ...mapActions({
      deltaInitStandalonePopup: actions.DELTA_INIT_STANDALONE_POPUP,
      markStep: actions.STEPS_MARK,
      updatePopupThumbnail: actions.SDA_POPUP_UPDATE_THUMBNAIL,
      toggleAdvancedMenu: actions.ADVANCED_MENU_TOGGLE,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      initLayout: actions.LAYOUT_INIT,
      undo: actions.HISTORY_COMMAND_UNDO,
      redo: actions.HISTORY_COMMAND_REDO,
      setBuilderVersion: actions.SET_BUILDER_VERSION,
    }),

    ...mapMutations('loader', {
      setLoaderMsg: 'setMessage',
    }),

    ...mapActions('loader', {
      startLoader: 'start',
    }),
    async nodesIsValid () {
      try {
        const nodes = this.findAllDescendants().filter(n => n.isEditorNode).filter(n => _.isFunction(n.validationErrors))
        const nodeErrors = await Promise.all(nodes.map(n => n.validationErrors()))
        const errors = _.flatten(nodeErrors)

        // Requires upgrades to 2.4.5
        // http://element.eleme.io/#/en-US/component/notification#notification
        // Notification.closeAll()
        errors.forEach(message => setTimeout(() => { Notification.error({ message, duration: 10000, position: 'bottom-right' }) }, 300))

        // find a better way to notify them of the errors as this currently is not effective and won't allow them to switch to other page

        return _.isEmpty(errors)
      } catch (err) {
        console.error('failed to validate page', err)
      }
      return true
    },
    buildDOM () {
      let _vDom = [this.vdom]
      if (Array.isArray(this.vdom)) {
        _vDom = this.vdom
      }
      this.clonedVDom = cloneVDomFromDelta(_vDom)
      this.$nextTick(() => {
        this.blockVnodeId = this.cPopupData.children[0]?.data?.props?.vnodeId
      })
    },
    forceBuildDOM () {
      this.isReload = true
      this.disableCommandPush()
      this.buildDOM()
      this.$nextTick(() => {
        this.isReload = false
        setTimeout(() => this.enableCommandPush(), 500)
      })
    },
    mouseOverTarget (event) {
      if (event.target === this.$refs.pageEngineWrapper) {
        document.getElementById(`${this.blockVnodeId}`)?.dispatchEvent(
            new MouseEvent('mouseover', { 'bubbles': true })
        )
        event.stopPropagation()
      }
    },
    blockTargetClick (event) {
      if (event.target === this.$refs.pageEngineWrapper) {
        document.getElementById(`${this.blockVnodeId}`)?.dispatchEvent(
            new Event('click', {'bubbles': true})
        )
        event.stopPropagation()
      }
    },
    snapshotWrapper (value) {
      return {
        val: () => {
          return value
        }
      }
    },
    initVdom() {
      this.deltaInitStandalonePopup({
        key: this.rootPath,
      })

      this.initLayout({
        pageEngine: this,
      })

      this.setQuickLaunchEngine({
        pageEngine: this,
      })

      this.setBuilderVersion('v3')
    },
    sdaCloseButtonPosition() {
      return this.cPopupData?.data?.props?.closeButtonPosition
    },
    $hubEventHandler(data) {
      if (data.forceBuild) {
        this.forceBuildDOM()
        return
      }
      this.buildDOM()
    }
  },
  mounted() {
    this.$hub.$on(PAGE_ENGINE_VDOM_UPDATE, this.$hubEventHandler)
  }
})

import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

import {SwapHeaderFooterSectionCommand, SwapSectionCommand} from '../../commands/SwapSectionCommand.ts'

export default {
  state: {
    visible: false,
    currentBlock: null, // the block which is in editor (selected to be replaced)
    savedBlock: null,   // the block which is already saved in firebase
    rearrangingBlock: false,
    isBlockAdded: false
  },
  getters: {
    [getters.SWAP_BLOCK_VISIBLE]({ visible }) {
      return visible;
    },
    [getters.SWAP_BLOCK_IN_PROCESS] ({ currentBlock }) {
      return currentBlock !== null;
    },
    [getters.SWAP_BLOCK_GET_CURRENT_BLOCK] ({ currentBlock }) {
      return currentBlock;
    },
    [getters.SWAP_BLOCK_GET_SAVED_BLOCK] ({ savedBlock }) {
      return savedBlock;
    },
    [getters.GET_REARRANGING_BLOCK] ({ rearrangingBlock }) {
      return rearrangingBlock;
    },
    [getters.GET_BLOCK_ADD_STATUS] ({ isBlockAdded }) {
      return isBlockAdded;
    },
  },
  mutations: {
    [mutations.SWAP_BLOCK_SHOW_DIALOG](state) {
      state.visible = true;
    },
    [mutations.SWAP_BLOCK_HIDE_DIALOG](state) {
      state.visible = false;
    },

    [mutations.SWAP_BLOCK_SET_CURRENT_BLOCK_DATA] (state, currentBlock) {
      state.currentBlock = currentBlock;
    },
    [mutations.SWAP_BLOCK_SET_SAVED_BLOCK_DATA] (state, savedBlock) {
      state.savedBlock = savedBlock;
    },
    [mutations.SET_REARRANGING_BLOCK] (state, value) {
      state.rearrangingBlock = value;
    },
    [mutations.SET_BLOCK_ADDED] (state, value) {
      state.isBlockAdded = value;
    },
  },
  actions: {
    [actions.SWAP_BLOCK_SHOW_DIALOG]({ state, commit }) {
      commit(mutations.SWAP_BLOCK_SHOW_DIALOG);
    },

    [actions.SWAP_BLOCK_HIDE_DIALOG]({ state, commit }) {
      commit(mutations.SWAP_BLOCK_HIDE_DIALOG);
    },

    [actions.SWAP_BLOCK_SET_CURRENT_BLOCK_DATA] ({ state, commit }, currentBlock) {
      commit(mutations.SWAP_BLOCK_SET_CURRENT_BLOCK_DATA, currentBlock);
    },
    [actions.REARRANGING_BLOCK] ({ state, commit }, value) {
      commit(mutations.SET_REARRANGING_BLOCK, value);
    },
    [actions.SWAP_BLOCK] ({ state, commit, dispatch, rootState, rootGetters }, savedBlock) {

      commit(mutations.SWAP_BLOCK_SET_SAVED_BLOCK_DATA, savedBlock);

      let pageEngine = rootState.layout.pageEngine;

      if (rootState.builders.version === 'v3') {
        if (state.currentBlock.isFooterBlock || state.currentBlock.isHeaderBlock) {
          dispatch(
              actions.HISTORY_COMMAND_PUSH,
              new SwapHeaderFooterSectionCommand(state.currentBlock.vDomPath, state.currentBlock.fbNode, savedBlock)
          )
        }else {
          dispatch( actions.HISTORY_COMMAND_PUSH, new SwapSectionCommand(state.currentBlock.fbNode, savedBlock));
        }
        dispatch('revisions/saveSnapshot', {
          key: 'manually.saved',
          description: `Section-Swapped:${new Date().toISOString().slice(0, 16).replace('T', '@')}`
        })
      } else { // for builder version v1 and v2
        pageEngine.splice( state.currentBlock.idx(), 1, savedBlock);
      }

      // this makes sure that if user select new block to replace it with current block
      // then we are not mistakenly replacing it previous-old-saved-block
      commit(mutations.SWAP_BLOCK_SET_CURRENT_BLOCK_DATA, null);
      commit(mutations.SWAP_BLOCK_SET_SAVED_BLOCK_DATA, null);
    },
  }
}

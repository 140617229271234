import * as _ from 'lodash'
import uuid from 'uuid/v4'
import * as getters from './getters'
import moment from 'moment-timezone'
import { database } from '@/js/store/services/firebase.service'
import { RevisionCommand, PopupRevisionCommand } from '../../commands/RevisionCommand'

const db = database()

export default {
  namespaced: true,
  state: {
    visible: false,
    visibleSave: false,
    siteId: null,
    projectHash: null,
    historyRef: null,
    snapshotsRef: null,
    siteRef: null,
    saving: false,
    prevSnapshotID: null,
    currentSnapshotID: null,
    history: [],
    loading: false,
    pageChanged: false,
    versionChangeCommand: RevisionCommand
  },
  mutations: {
    open(state) {
      state.visible = true
    },
    openSave(state) {
      state.visibleSave = true
    },
    close(state) {
      state.visible = false
    },
    closeSave(state) {
      state.visibleSave = false
    },
    updateCurrentSnapshotID(state, snapshotID) {
      state.prevSnapshotID = state.currentSnapshotID
      state.currentSnapshotID = snapshotID
      state.pageChanged = false
    },
    updatePageChanged(state) {
      state.pageChanged = true
    },
    updateVersionChangeCommand(state, commandClass) {
      state.versionChangeCommand = commandClass
    },
  },
  actions: {
    async init({ state, commit, dispatch, rootGetters }) {
      const { id, firebaseRef } = rootGetters[getters.SITE_GET_DATA]
      const { projectHash } = rootGetters[getters.AUTH_GET_USER]

      state.siteId = id
      state.projectHash = projectHash
      state.siteRef = db.ref(firebaseRef)
      state.historyRef = db
        .ref('site-revisions')
        .child(projectHash)
        .child(id)
        .child('history')
      state.snapshotsRef = db
        .ref('site-revisions')
        .child(projectHash)
        .child(id)
        .child('snapshots')

      console.debug('revision manager historyRef', state.historyRef.toString())
      console.debug(
        'revision manager snapshotsRef',
        state.snapshotsRef.toString(),
      )
      console.debug('revision manager siteRef', state.siteRef.toString())

      state.historyRef.on('value', (snap) => {
        const data = snap.val()
        if (data) {
          state.history = _.chain(data)
            .map((val, key) => ({ id: key, ...val }))
            .orderBy('timestampUnix', 'desc')
            .value()
        }
        console.debug('revision manager history', data)
      })
      commit('updateVersionChangeCommand', RevisionCommand)
    },
    async initStandalonePopup({ state, commit, dispatch, rootGetters }) {
      const { id, firebaseRef } = rootGetters[getters.SDA_POPUP_SITE_DATA]
      const { projectHash } = rootGetters[getters.AUTH_GET_USER]

      state.siteId = id
      state.projectHash = projectHash
      state.siteRef = db.ref(firebaseRef)
      state.historyRef = db
          .ref('standalone-popup-revisions')
          .child(projectHash)
          .child(id)
          .child('history')
      state.snapshotsRef = db
          .ref('standalone-popup-revisions')
          .child(projectHash)
          .child(id)
          .child('snapshots')

      console.debug('popup revision manager historyRef', state.historyRef.toString())
      console.debug(
          'popup revision manager snapshotsRef',
          state.snapshotsRef.toString(),
      )
      console.debug('popup revision manager siteRef', state.siteRef.toString())

      state.historyRef.on('value', (snap) => {
        const data = snap.val()
        if (data) {
          state.history = _.chain(data)
              .map((val, key) => ({ id: key, ...val }))
              .orderBy('timestampUnix', 'desc')
              .value()
        }
        console.debug('popup revision manager history', data)
      })
      commit('updateVersionChangeCommand', PopupRevisionCommand)
    },
    async saveSnapshot(
      { state, commit, dispatch, rootGetters },
      { key, description },
    ) {
      if (!key) {
        throw new Error('must provide an event.key when saving a snapshot')
      }

      if (!description) {
        throw new Error(
          'must provide an event.description when saving a snapshot',
        )
      }

      const {
        email,
        firstName = '',
        lastName = '',
      } = rootGetters[getters.AUTH_GET_USER]

      if (state.saving) {
        console.warn('revision manager: saving is already in progress')
        return
      }

      try {
        state.saving = true
        const snapshotID = uuid()

        console.debug('revision manager save', state)

        await state.snapshotsRef
          .child(snapshotID)
          .set(await state.siteRef.once('value').then((snap) => snap.val()))

        await state.historyRef.child(snapshotID).set({
          snapshotID,
          event: { description, key },
          actor: { email, firstName, lastName },
          timestampUnix: moment().utc().unix(),
          timestampISO: moment().utc().toISOString(),
        })
        commit('updateCurrentSnapshotID', snapshotID)
      } finally {
        setTimeout(() => {
          state.saving = false
        }, 500)
      }
    },
    async restoreSnapshot(
      { state, commit, dispatch, rootGetters, rootState },
      snapshotID,
    ) {
      if (!snapshotID) {
        throw new Error(
          'a snapshotID is required to restore a previous revision',
        )
      }

      try {
        state.loading = true
        // load the snapshot and overwrite the current site data

        if (rootState.builders.version === 'v3') {
          // force page-engine to render all components

          const command = new state.versionChangeCommand(
              state.siteRef.toString().substring(state.siteRef.root.toString().length),
              state.snapshotsRef.toString().substring(state.siteRef.root.toString().length),
              snapshotID
          )
          await command.pullChanges()
          rootState.layout.pageEngine.validateAndPushCommand(command)
        }
        else {
          await state.siteRef.set(
              await state.snapshotsRef
                  .child(snapshotID)
                  .once('value')
                  .then((snap) => snap.val()),
          )
        }

        commit('updateCurrentSnapshotID', snapshotID)
      } finally {
        setTimeout(() => {
          state.loading = false
        }, 500)
      }
    },
  },
}

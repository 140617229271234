<template>
  <div class="colors">
    <div
      v-if="swatchVisible"
      class="color-swatch py-3 d-flex flex-wrap mx-auto"
    >
      <div
        class="theme-color theme-color--new d-flex flex-column align-items-center justify-content-around"
        data-identity="add-new-theme-color"
        @click.stop.prevent="addNewColorShowPicker"
      >
        <i class="material-icons">add</i>
        <small>{{ $t('elements.properties.color.add') }}</small>
      </div>

      <div
        v-for="(color, key) in colors"
        :key="key"
        class="theme-color theme-color--editable"
        :class="isActiveColor(key)"
        :style="colorToStyle(color)"
        @click.stop="applyColorsAll({ colorId: key, toBg: toBg })"
      >
        <div
          class="theme-color__edit__btn"
          @click.stop="enableColorPicker(color, key)"
        >
          {{ $t('editor.right_nav.theme.color_picker.edit.btn') }}
        </div>
      </div>
    </div>

    <div v-else-if="colorPickerVisible" class="color-picker">
      <div class="row no-gutters">
        <div class="col-auto">
          <color-picker
            ref="picker"
            :current-color="currentColor.color"
            :valpha="currentColor.valpha"
            @change="updateCurrentColor"
          />
        </div>
      </div>

      <div class="row">
        <div class="col d-flex mt-1">
          <cvt-button
            class="site-settings-btns mr-1"
            :color="defaultSiteSettingsColorsCompBtnConfig.props.save.color"
            :special-style="
              defaultSiteSettingsColorsCompBtnConfig.props.save.specialStyle
            "
            :outlined="
              defaultSiteSettingsColorsCompBtnConfig.props.save.outlined
            "
            :text="$t('editor.right_nav.theme.color_picker.save.btn')"
            @click.prevent.stop="disableColorPicker"
          >
          </cvt-button>
          <cvt-button
            class="px-3 mr-1"
            mode="dark"
            color="light"
            :outlined="true"
            btn-text-color="#000"
            :text="
              $t(
                'editor.left_nav.page_manager.layout_dialog.template.footer.cancel.btn',
              )
            "
            @click.prevent.stop="resetToCurrentColor"
          >
          </cvt-button>
          <cvt-button
            class="px-3"
            :color="defaultSiteSettingsColorsCompBtnConfig.props.delete.color"
            :special-style="
              defaultSiteSettingsColorsCompBtnConfig.props.delete.specialStyle
            "
            :outlined="
              defaultSiteSettingsColorsCompBtnConfig.props.delete.outlined
            "
            icon="trash"
            @click.prevent.stop="toggleRemoveColorDialog"
          >
          </cvt-button>
        </div>
      </div>
    </div>

    <cvt-dialog
      :show="removeColorVisisble"
      :height-auto="true"
      :border-radius="'8px'"
      size="sm"
      bg-color="#fff"
      :show-close="false"
    >
      <template #title>
        <h3 class="del-color-warn-title">{{ $t('marketplace.branding.list.delete.color.title') }}</h3>
      </template>

      <template #default>
        <h5 class="del-color-warn-subtitle">
          {{ $t('marketplace.branding.list.delete.color.subtitle') }}
        </h5>
      </template>

      <template #modalFooter>
        <div class="d-flex">
          <cvt-button
            class="mr-2"
            :text="$t('marketplace.dialog.archive.cta.cancel')"
            v-bind="cancelBtnProps"
            @click.stop="toggleRemoveColorDialog"
          />
          <cvt-button
            :text="$t('marketplace.dialog.archive.cta.submit')"
            v-bind="submitBtnProps"
            class="mr-2"
            @click.stop="deleteColorFromPalette"
          />
        </div>
      </template>
    </cvt-dialog>
  </div>
</template>
<script>
import color from 'color'
import * as _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import { FRESHWORKS } from '../../../../../../storybook/components/constants'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import ColorPicker from '../../spectrum/ColorPicker.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ColorsAlt',
  components: {
    ColorPicker,
  },
  props: {
    alpha: Boolean,
    mode: {
      type: String,
      default: 'LIGHT',
    },
    toBg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorPickerVisible: false,
      currentColor: color('#ff0000'),
      prevColor: color('#ff0000'),
      currentKey: null,
      removeColorVisisble: false,
      alphaChannel: 1,
      exclude: {
        GridBoxContainer: true,
        Icon: true,
      },
      isAddNewColorFlow: false,
    }
  },

  computed: {
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) =>
        globalTheme.OrgName != null ? globalTheme.OrgName : 'standard',
      colorsCompBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.colorsComponent,
      siteSettingsColorsCompBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.siteSettingsColorComponent,
      deleteColorCancelTheme: ({ globalTheme }) =>
        globalTheme.deleteColorCancelTheme != null
          ? globalTheme.deleteColorCancelTheme
          : 'warning',
      defaultColorsCompBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.colorsComponent.locations.default,
      defaultSiteSettingsColorsCompBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.siteSettingsColorComponent.locations
          .default,
    }),

    ...mapGetters({
      colors: getters.MP_GET_THEME_COLORS,
      defaultColor: getters.MP_GET_DEFAULT_COLOR,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
    }),

    activeColor() {
      return null
    },

    activeBgColor() {
      return null
    },

    swatchVisible() {
      return this.colorPickerVisible === false
    },

    saveBtnMsg() {
      return this.currentKey ? 'Save' : 'Add'
    },

    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },

    isFreshworksOrg() {
      return this.orgName === FRESHWORKS
    },

    cancelBtnProps() {
      if (this.isFreshworksOrg) {
        return { color: 'light' }
      } else {
        return { color: this.deleteColorCancelTheme }
      }
    },
    submitBtnProps() {
      if (this.isFreshworksOrg) {
        return {
          color: '',
          specialStyle: 'dark',
        }
      } else {
        return {
          color: 'light',
        }
      }
    },
  },

  created() {
    this.alphaChannel = 1
  },
  methods: {
    ...mapActions({
      addNewThemeColor: actions.MP_ADD_COLOR,
      updateThemeColor: actions.MP_UPDATE_COLOR,
      removeColor: actions.MP_REMOVE_COLOR,
    }),

    isActiveColor(color) {
      return { 'theme-color--active': true }
    },

    addNewColorShowPicker() {
      this.isAddNewColorFlow = true
      this.currentColor = color('white')
      this.addNewThemeColor({ color: this.currentColor }).then((colorId) => {
        this.enableColorPicker(this.currentColor, colorId)
        return this.applyColorsAll({ colorId: colorId, toBg: this.toBg })
      })
    },

    enableColorPicker(color, key) {
      this.currentKey = key
      this.currentColor = color
      this.prevColor = color
      this.colorPickerVisible = true

      // usage prop will always be equal to empty-string except
      // when this component is being rendered in "SiteSettings" component
      if (this.usage === '') {
        this.$nextTick((_) => {
          this.$refs.picker.setColor(this.currentColor)
        })
      }
    },

    disableColorPicker() {
      this.colorPickerVisible = false
      this.removeColorVisisble = false
    },

    resetToCurrentColor() {
      if (this.isAddNewColorFlow) {
        this.deleteColorFromPalette()
        this.isAddNewColorFlow = false
      } else {
        this.currentColor = this.prevColor
        this.updateThemeColor({
          color: this.currentColor,
          key: this.currentKey,
        })
      }
      return this.disableColorPicker()
    },

    updateCurrentColor: _.debounce(function (color) {
      this.currentColor = color

      return this.updateThemeColor({
        color: this.currentColor,
        key: this.currentKey,
      })
    }, 300),

    colorToStyle(color) {
      return {
        backgroundColor: color.hsl().toString(),
      }
    },

    setCurrentColor(color) {
      this.currentColor = color
    },

    onChange(value) {
      return this.$emit('apply-transparency', value)
    },

    applyColorsAll(options) {
      return this.$emit('apply-color', options)
    },

    toggleRemoveColorDialog() {
      this.removeColorVisisble = !this.removeColorVisisble
    },

    deleteColorFromPalette() {
      try {
        this.removeColor(this.currentKey)
        this.disableColorPicker()
      } catch (e) {
        console.log(e)
        this.$message({
          type: 'info',
          message: this.$t(
            'editor.right_nav.theme.color_picker.delete.failed.message',
          ),
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@/scss/utils';

.theme-color__edit__btn {
  transition: all 250ms ease;
  display: none;
  top: -10px;
  right: -10px;
  position: absolute;
  box-shadow: 0 0 0 2px rgba(63, 70, 82, 0.15);
  background: $white;
  border-radius: 3px;
  border: 1px solid $dark;
  color: $dark;
  z-index: 3;
  padding: 0 5px;
  // text-transform: uppercase;
  text-align: center;

  &:hover {
    background: $info;
    border-color: transparent;
    color: $white;
  }
}

.theme-color {
  transition: all 100ms ease;
  transform: scale(1);
  width: 3rem;
  height: 3rem;

  box-shadow: inset 2px 1px 0 rgba(0, 0, 0, 0.05),
    inset -2px -1px 0 rgba(0, 0, 0, 0.05);

  cursor: pointer;
  user-select: none;

  display: inline-block;
  position: relative;

  margin: 5px;
  border-radius: 5px;

  &--active {
    outline: 4px solid $light-blue;
    z-index: 2;
  }

  &--editable {
    .theme-color__edit__btn {
      display: flex;
      opacity: 0;
      padding: 0.2rem 0.5rem;
      &:hover {
        background: $bright-blue;
      }
    }

    &:hover {
      transform: scale(1.15);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      .theme-color__edit__btn {
        opacity: 1;
      }

      z-index: 3;
    }
  }

  &:active {
    transform: scale(0.95);
  }
}

.theme-color--new {
  // outline: 1px solid $dark-gray;
  font-size: 25px;
  box-shadow: none;
  small {
    font-size: 8px;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
  }

  &:hover {
    background: $bright-blue !important;
    color: $white;
  }
}

.color-picker {
  width: 100%;
}

.color-picker-controls {
  width: 100%;
  margin-top: 10px;
  .el-button {
    width: 50%;
  }
}

.vue-color__chrome {
  box-shadow: none !important;
  margin: 0 auto;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  background: transparent !important;
}

.vue-color__chrome__chrome-body {
  background: transparent !important;
}

.site-settings-btns {
  padding-left: 2.4rem !important;
  padding-right: 2.4rem !important;
}

.colors {
  .del-color-warn-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #12344d;
  }
  .del-color-warn-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #12344d;
  }
}
</style>

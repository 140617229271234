<template>
  <div identity="advance-side-menu-wrap">
    <cvt-properties-sidebar
      identity="cvt-properties-sidebar"
      :mode="mode"
      :show-sidebar="isGridBuilder ? true : showSidebar"
      :prepend-chevron="prependChevron"
      :variant="mappedName"
      :show-save-button="isBlock"
      :show-bookmark-button="isBlock"
      :grid-builder-enabled="isGridBuilder"
      :type="OrgName"
      :special-style-bg-color="isSpecialStyle"
      :allow-back-action="['CardItem']"
      @closeSidebar="() => toggle(false)"
      @delete="remove"
      @duplicate="duplicate"
      @save="showSaveBlock"
      @bookmark="showBookmarkBlock"
      @back="backAction"
    >
      <Block v-if="activeMenuOption === 'Block'" v-bind="componentProps" />
      <Row v-else-if="activeMenuOption === 'Row'" v-bind="componentProps" />
      <Column v-else-if="activeMenuOption === 'Column'" v-bind="componentProps" />

      <CImage v-else-if="activeMenuOption === 'CImage'" v-bind="componentProps" />
      <CForm v-else-if="activeMenuOption === 'CForm'" v-bind="componentProps" />
      <Draft v-else-if="activeMenuOption === 'Draft'" v-bind="componentProps" />

      <CButton v-else-if="activeMenuOption === 'CButton'" v-bind="componentProps" />
      <Divider v-else-if="activeMenuOption === 'Divider'" v-bind="componentProps" />
      <CVideo v-else-if="activeMenuOption === 'CVideo'" v-bind="componentProps" />

      <Icon v-else-if="activeMenuOption === 'Icon'" v-bind="componentProps" />
      <Layers v-else-if="activeMenuOption === 'Layers'" v-bind="componentProps" />
      <BrandLogo v-else-if="activeMenuOption === 'BrandLogo'" v-bind="componentProps" />

      <CodeSnippet v-else-if="activeMenuOption === 'CodeSnippet'" v-bind="componentProps" />
      <BuyNowButton v-else-if="activeMenuOption === 'BuyNowButton'" v-bind="componentProps" />
      <CSurvey v-else-if="activeMenuOption === 'CSurvey'" v-bind="componentProps" />
      <CountdownTimer v-else-if="activeMenuOption === 'CountdownTimer'" v-bind="componentProps" />
      <Checkout v-else-if="activeMenuOption === 'Checkout'" v-bind="componentProps" />
      
      <GridBoxContainer v-else-if="activeMenuOption === 'GridBoxContainer'" v-bind="componentProps" />
      <SubGridContainer v-else-if="activeMenuOption === 'SubGridContainer'" v-bind="componentProps" />
      <CheckoutPayment v-else-if="activeMenuOption === 'CheckoutPayment'" v-bind="componentProps" />
      <CheckoutThankYou v-else-if="activeMenuOption === 'CheckoutThankYou'" v-bind="componentProps" />
      <CCardOptions v-else-if="activeMenuOption === 'CCard'" v-bind="componentProps" />
      <CCardItemOptions v-else-if="activeMenuOption === 'CardItem'" v-bind="componentProps" />
      <CEmbeddedForm v-else-if="activeMenuOption === 'CEmbeddedForm'" v-bind="componentProps" />

    </cvt-properties-sidebar>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'
import { ELEMENT_NAME_MAP } from '../../../lib/helper-classes'
import Block from './BlockMenuOptions.vue'
import CountdownTimer from './CountdownTimerMenuOptions/CountdownTimerMenuOptions.vue'
import GridBoxContainer from './BoxContainerMenuOptions.vue'
import Row from './RowMenuOptions.vue'
import Column from './ColumnMenuOptions.vue'
import CButton from './CButtonMenuOptions.vue'
import Divider from './DividerMenuOptions.vue'
import CVideo from './CVideoMenuOptions.vue'
import CForm from './CFormMenuOptions.vue'
import Icon from './IconMenuOptions.vue'
import Draft from './DraftMenuOptionsFroala.vue'
import CImage from './CImageMenuOptions.vue'
import CSurvey from './CSurveyMenuOptions.vue'
import BrandLogo from './BrandLogoMenuOptions.vue'
import CodeSnippet from './CodeSnippetMenuOptions.vue'
import BuyNowButton from './BuyNowButtonMenuOptions.vue'
import CCardOptions from './CCardOptions.vue'
import CCardItemOptions from './CCardItemOptions/Index.vue'
import Checkout from './CheckoutMenuOptions/CheckoutMenuOptions.vue' // Checkout-Cart-MenuOptions
import CheckoutPayment from "./CheckoutPayment.vue"; // Checkout-Mock-Payment-Page-MenuOptions
import CheckoutThankYou from "./CheckoutThankYou.vue"; // Checkout-Mock-ThankYou-Page-MenuOptions
import Layers from './Layers.vue'
import CEmbeddedForm from './CEmbeddedForm.vue'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../../../storybook/components/constants'

const components = {
  CImage,
  Draft,
  Block,
  Row,
  Column,
  CButton,
  Divider,
  CVideo,
  CForm,
  Icon,
  Layers,
  BrandLogo,
  CodeSnippet,
  BuyNowButton,
  CSurvey,
  CountdownTimer,
  Checkout,
  GridBoxContainer,
  SubGridContainer: GridBoxContainer,
  CheckoutPayment,
  CheckoutThankYou,
  CCardOptions,
  CCardItemOptions,
  CEmbeddedForm
}

export default {
  components,
  props: {
    popup: {
      type: Object,
      default() {}
    }
  },
  data () {
    return { }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      open: getters.ADVANCED_MENU_OPEN,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER
    }),
    ...mapState('globalTheme', {
      mode: ({globalTheme}) => globalTheme.mode,
      prependChevron: ({globalTheme}) => globalTheme.prependChevron,
      OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD,
    }),
    activeMenuOption () {
      return this.selected?.name
    },
    mappedName () {
      if (this.selected && this.selected?.isPopupBlock) {
        return 'POPUP'
      }
      return ELEMENT_NAME_MAP[this.activeMenuOption] || this.activeMenuOption
    },
    title () {
      return `Edit ${this.mappedName}`
    },
    isBlock () {
      return this.isAdmin && this.selected?.name === 'Block'
    },
    showSidebar: {
      get () {
        return Boolean(this.selected && this.open)
      }
    },
    isSpecialStyle () {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    componentProps () {
      return {
        mode: this.mode,
        prependChevron: this.prependChevron
      }
    }
  },
  methods: {
    ...mapMutations({
      showSaveBlock: mutations.SAVE_BLOCK_SHOW,
      showBookmarkBlock: mutations.BOOKMARK_BLOCK_SHOW
    }),
    ...mapActions({
      toggle: actions.ADVANCED_MENU_TOGGLE
    }),
    remove () {
      return this.selected.removeAndValidate()
    },
    duplicate () {
      return this.selected.duplicateAndValidate()
    },
    backAction() {
      return this.selected.parent().selectSelfForHelp()
    },
  }
}
</script>

<style lang="scss"></style>

<script>
/**
 * class FbVNode
 * this class is intended to provide methods for interfacing with firebase
 *
 * Uses
 * https://github.com/vuejs/vue-class-component
 * https://github.com/kaorun343/vue-property-decorator
 */
import color from 'color'
import * as _ from 'lodash'
import Vue from 'vue'

import * as actions from '../../store/modules/actions'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'

import flexAlignment from '../../lib/flexAlignment'
import { assignIdsRecursive, GridItemWrapper } from '../../lib/helper-classes'
import moveElementInArray from '../../lib/moveElementInArray'

import { Notification } from 'element-ui'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../../storybook/components/constants'
import { StyleSheet } from '../../lib/styleGenerator'

function findAllDescendants (node, accumulator = []) {
  if (!node || !node.$children) return accumulator
  return accumulator.concat(node.$children, ...node.$children.map(n => findAllDescendants(n, accumulator)))
}

const SYNTH_EVENT = {
  shiftKey: false,
  stopPropagation () {},
  preventDefault () {}
}

class Spacing {
  constructor (type, spacing) {
    this.type = type
    this.top = spacing.top
    this.right = spacing.right
    this.bottom = spacing.bottom
    this.left = spacing.left
    this.unit = spacing.unit || 'px'
    this.vertical = spacing.vertical
    this.horizontal = spacing.horizontal
  }

  toObject () {
    return {
      [`${this.type}Top`]: this.top > 0 ? this.top + this.unit : null,
      [`${this.type}Right`]: this.right > 0 ? this.right + this.unit : null,
      [`${this.type}Bottom`]: this.bottom > 0 ? this.bottom + this.unit : null,
      [`${this.type}Left`]: this.left > 0 ? this.left + this.unit : null
    }
  }
}

const catchAllFormKey = `
  & .form-simple input::placeholder,
    .form-material input::placeholder,
    .form-creative input::placeholder,
    .form-minimalist input::placeholder,
    .form-simple input,
    .form-material input,
    .form-creative input,
    .form-minimalist input
`
const KEY = '.key'
const PATH = '.path'

export const FbVNodeMixin = {
  props: {
    // autoformatting will change these without this
    [KEY]: String,
    [PATH]: String,
    attrs: {
      type: Object,
      default () { return {} }
    },
    background: {
      type: Object,
      default () { return {} }
    },
    padding: {
      type: Object,
      default () { return {} }
    },
    margin: {
      type: Object,
      default () { return {} }
    },
    borderRadius: {
      type: Number,
      default: 0
    },
    borderWidth: {
      type: Number,
      default: 0
    },
    borderStyle: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    },
    borderPosition: {
      type: Object,
      default () { return {} }
    },
    flexAlignment: Object,
    bootstrapAlignment: String,
    vnodeId: String,
    themeColorId: String,
    themeColorAlpha: {
      type: Number,
      default: 1
    },
    customName: String,
    hideOnMobile: Boolean,
    hideOnTablet: Boolean,
    hideOnDesktop: Boolean,
    popupVNode: {
      type: Number,
      default: -1,
    },
    marginPadding: {
      type: Object,
      default: () => {
        return {
          desktop: {
            padding: {
              vertical: '',
              horizontal: ''
            },
            margin: {
              vertical: '',
              horizontal: ''
            }
          },
          phone: {
            padding: {
              vertical: '',
              horizontal: ''
            },
            margin: {
              vertical: '',
              horizontal: ''
            }
          }
        }
      }
    }
  },

  data () {
    this._sheet = new StyleSheet()

    return {
      isEditorNode: true,
      alternatedViewMode: {
        desktop: 'phone',
        phone: 'desktop'
      }
    }
  },

  computed: {
    ...mapGetters({
      targetedComponent: getters.SELECTOR_TARGETED,
      selectedComponent: getters.SELECTOR_SELECTED,
      deltaRef: getters.DELTA_SOURCE,
      vdom: getters.DELTA_VDOM,
      theme: getters.THEME,
      anchors: getters.ANCHORS,
      layoutPath: getters.LAYOUT_PATH,
      advancedMenuOpen: getters.ADVANCED_MENU_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      viewMode: getters.VIEW_MODE,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER
    }),
    ...mapGetters('targetmode', {
      targetLayoutMode: 'layoutMode'
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD
    }),
    anchorName () {
      return this.anchors[this.vnodeId]
    },
    anchorId () {
      return this.fmtAnchorId(this.anchorName) || this.vnodeId
    },
    themeColors () {
      return {
        ...this.theme.colors,
        ...this.theme.blockColors
      }
    },

    // stubbed for compoents
    styles () {},

    themeColor () {
      let color = this.themeColors[this.themeColorId]
      let alpha = this.themeColorAlpha
      return color && color.alpha(alpha)
    },
    overlayColor () {
      let colorId = this.background.coloroverlay || null;
      let color = this.themeColors[colorId];
      let alpha = this.background.coloralpha ? this.background.coloralpha : 1;

      let result = color && color.alpha(alpha);
      return result;
    },
    bgShapeColor () {
      let colorId = this.bgShape.fillColor || null;
      let color = this.themeColors[colorId];
      let alpha = this.bgShape.fillColorAlpha !== undefined ? this.bgShape.fillColorAlpha : 1;

      let result = color && color.alpha(alpha);
      return result;
    },
    fontColor () {
      if (this.themeColor) {
        if (this.themeColor.alpha() < 0.4) {
          return this.themeColor.darken(1)
        }

        if (this.themeColor.lightness() >= 60) {
          return color('black')
        }

        if (this.themeColor.lightness() < 60) {
          return color('white')
        }
      }
    },
    themeColorDarkened () {
      return this.themeColor ? this.themeColor.darken(0.1) : null
    },
    fontColorLightened () {
      return this.fontColor ? this.fontColor.lighten(0.1) : null
    },
    themeColorHoverHSL () {
      return this.themeColorDarkened ? this.themeColorDarkened.hsl().toString() : null
    },
    fontColorHoverHEX () {
      return this.fontColorLightened ? this.fontColorLightened.hex() : null
    },
    themeColorHSL () {
      return this.themeColor ? this.themeColor.hsl().toString() : null
    },
    overlayColorHSL () {
      return this.overlayColor ? this.overlayColor.hsl().toString() : null
    },
    bgShapeColorHSL () {
      return this.bgShapeColor ? this.bgShapeColor.hsl().toString() : null
    },
    fontColorHEX () {
      return this.fontColor ? this.fontColor.hex() : null
    },
    formColorTransform () {
      return {
        [catchAllFormKey]: {
          color: this.fontColorHEX
        },
        '& .form-creative input, .form-minimalist input': {
          border: `1px solid ${this.fontColorHEX}`
        },
        '& .form-simple input': {
          borderBottom: `1px solid ${this.fontColorHEX}`
        },
        '& .form-material input': {
          borderBottom: `1px solid ${this.fontColorHEX}`
        }
      }
    },
    showControlls () {
      return this.selected || this.targeted
    },
    selected () {
      return this.selectedComponent === this
    },
    targeted () {
      return this.targetedComponent === this
    },
    showOverlay () {
      return this.targeted && !this.selected
    },
    classes () {
      return {
        ...this.class,
        ...this.localClasses,
        ...this.verticalAndHorizontalSpacingClasses,
        'vnode--targeted': this.targeted,
        'vnode--selected': this.selected,
        [this.emptyStateClass]: this.isEmpty,

        // Conditional rendering by media
        ...this.visibilityClasses
      }
    },

    visibilityClasses () {
      // disabled on the editor to prevent content hiding
      // if (this.hideOnMobile && !this.hideOnTablet && !this.hideOnDesktop) {
      //   return { 'd-sm-none': true, 'd-md-block': true }
      // }

      // if (this.hideOnMobile && this.hideOnTablet && !this.hideOnDesktop) {
      //   return { 'd-sm-none': true, 'd-lg-block': true }
      // }

      // if (this.hideOnMobile && this.hideOnTablet && this.hideOnDesktop) {
      //   console.debug('This element is permanently hidden from view', this)
      //   return { 'd-none': true }
      // }

      // if (!this.hideOnMobile && this.hideOnTablet && !this.hideOnDesktop) {
      //   return { 'd-md-none': true, 'd-lg-block': true }
      // }

      // if (!this.hideOnMobile && this.hideOnTablet && this.hideOnDesktop) {
      //   return { 'd-md-none': true }
      // }

      // if (!this.hideOnMobile && !this.hideOnTablet && this.hideOnDesktop) {
      //   return { 'd-lg-none': true }
      // }

      return {}
    },

    verticalAndHorizontalSpacingClasses () {
      if (this.isGridBuilder) {
        return {
          [_.get(this.spacingMP, [this.viewMode, 'padding', 'vertical'])]: true,
          [_.get(this.spacingMP, [this.viewMode, 'padding', 'horizontal'])]: true,
          [_.get(this.spacingMP, [this.viewMode, 'margin', 'vertical'])]: true,
          [_.get(this.spacingMP, [this.viewMode, 'margin', 'horizontal'])]: true
        }
      }

      return {
        [this.padding.vertical]: true,
        [this.padding.horizontal]: true,
        [this.margin.vertical]: true,
        [this.margin.horizontal]: true
      }
    },

    flexAlignmentClasses () {
      return flexAlignment(this.flexAlignment || {})
    },

    myPath () {
      return this.$store.getters[getters.DELTA_PATH](this.path())
    },

    isEmpty () {
      return (this.myPath && this.myPath.children) ? this.myPath.children.length === 0 : true;
    },

    isFirstChild () {
      return this.idx() === 0
    },

    hasSiblingAbove () {
      return this.idx() > 0
    },

    stylesheet () {
      return this._sheet.update(this.styles)
    },

    hasColor () {
      return !!this.themeColorId
    },

    hasBgColor () {
      return this.background && this.background.color
    },

    color () {
      let { rgba } = this.background.color || {}
      let { r, g, b, a } = rgba || {}
      return color({ r, g, b }).alpha(a || 1)
    },

    spacing () {
      return {
        padding: new Spacing('padding', this.padding || {}),
        margin: new Spacing('margin', this.margin || {})
      }
    },

    spacingStyle () {
      return {
        ...this.spacing.padding.toObject(),
        ...this.spacing.margin.toObject()
      }
    },

    dropzoneZIndex () {
      return this.path() ? this.path().split('/').length : 0
    },

    showActionButtonsOnClick () {
      return this.OrgName === FRESHWORKS || this.isGridBuilder
    },

    spacingMP: {
      get () {
        return this.marginPadding
      },
      set (value) {
        return value
      }
    },
    nodeTag() {
      return this.fbNode?.tag || "Not available"
    }
  },

  methods: {
    ...mapMutations({
      ignore: mutations.SELECTOR_IGNORE,
      disableDropzones: mutations.DROPZONE_DISABLE,
      dropzoneDropped: mutations.DROPZONE_DROPPED
    }),

    ...mapActions({
      activateDropzones: actions.DROPZONE_ACTIVATE,
      deselect: actions.SELECTOR_DESELECT,
      mutateSelected: actions.SELECTOR_SELECT,
      mutateTargeted: actions.SELECTOR_TARGET,
      mutateTargetedDebounce: actions.SELECTOR_DEBOUNCED_TARGET,
      cancelDebounceTarget: actions.SELECTOR_DEBOUNCED_TARGET_CANCEL,
      showRightClick: actions.RIGHT_CLICK_MENU_SHOW,
      openAssetManager: actions.ASSET_MANAGER_INIT,
      advancedMenuToggle: actions.ADVANCED_MENU_TOGGLE,
      refreshLayout: actions.LAYOUT_REFRESH,
      setLayoutChildren: actions.SET_LAYOUT_CHILDREN
    }),

    fmtAnchorId (name) {
      return _.chain(name).trim().kebabCase().value()
    },

    showContextMenu (event) {
      this.showRightClick({
        event: event,
        target: this,
      })
    },

    getDimentions () {
      return JSON.parse(JSON.stringify(this.$el.getBoundingClientRect()))
    },

    allowPropagation (e = SYNTH_EVENT) {
      const isLayoutElmAndNotInTargetMode = this.layoutElm && !this.targetLayoutMode
      const isLayoutElementAndShiftKeyIsNotHeld = this.layoutElm && !e.shiftKey
      const sectionUiVisibilityEventPropagation = e.srcElement && e.srcElement.className.indexOf?.('element-enable-propagation')

      if (this.isGridBuilder && this.parent().name === 'GridItemWrapper') {
        return false
      }

      if (isLayoutElementAndShiftKeyIsNotHeld && isLayoutElmAndNotInTargetMode) {
        return true
      }

      if (this.targetLayoutMode && !this.layoutElm) {
        return true
      }

      // This check allows the Section-ui-toggle-visibilty feature to work properly.
      // Without this code, whenever a user clicks on radio button, its event does not propagate
      // Hence results in no UI is updated and user thinks the radio button is not clicked properly.
      if (sectionUiVisibilityEventPropagation >= 0) {
        return true;
      }

      e.preventDefault()
      e.stopPropagation()

      return false
    },

    selectSelfForHelp () {
      this.mutateTargeted(this)
      this.mutateSelected(this)
      this.advancedMenuToggle(true)
    },

    select (e) {
      if (this.allowPropagation(e)) { return false }
      this.advancedMenuToggle(true)
      this.mutateSelected(this.targetedComponent)
      return true
    },

    target (e) {
      if (this.allowPropagation(e)) { return false }
      this.cancelDebounceTarget()
      this.mutateTargeted(this)
      return true
    },

    edit (e) {
      if (this.allowPropagation(e)) { return false }
      this.cancelDebounceTarget()
      this.mutateTargeted(this)
      this.advancedMenuToggle(true)
      this.mutateSelected(this.targetedComponent)
      return true
    },

    debouncedTarget () {
      this.mutateTargetedDebounce(this)
    },

    idx () {
      return +this.self().key
    },

    self () {
      return this.deltaRef.ref(this.path())
    },

    path () {
      return this['.path']
    },

    parent () {
      return this.$parent
    },

    moveChild (idx, direction) {
      return this.transaction((children) => {
        return moveElementInArray(children, idx, direction)
      })
    },

    moveUp (steps = -1) {
      return this.$parent.moveChild(this.idx(), steps)
    },

    moveDown (steps = 1) {
      return this.$parent.moveChild(this.idx(), steps)
    },

    append (vNode, method) {
      if (_.isArray(vNode) === false) {
        vNode = [vNode]
      }

      return this.transaction((children) => {
        children[method](...vNode)
        return children
      })
    },

    push (vNode) {
      return this.append(vNode, 'push')
    },

    unshift (vNode) {
      return this.append(vNode, 'unshift')
    },

    splice (index, deleteCount, vNode) {
      return this.transaction((children) => {
        children.splice(index, deleteCount, vNode)
        return children
      })
    },

    before (vNode) {
      return this.$parent.splice(this.idx(), 0, vNode)
    },

    after (vNode) {
      return this.$parent.splice(this.idx() + 1, 0, vNode)
    },

    transaction (cb) {
      return this.self().transaction((val) => {
        if (val && val.tag) {
          val.children = val.children || []
          val.children = cb(val.children)
          return val
        }
        val = val || []
        return cb(val)
      })
    },

    children () {
      return this.$children.filter(comp => comp.isEditorNode)
    },

    siblings () {
      return this.parent().children()
    },

    isFirst () {
      return _.chain(this.siblings()).head().isEqual(this).value()
    },

    isLast () {
      return _.chain(this.siblings()).last().isEqual(this).value()
    },

    first () {
      return this.children().slice(0).pop()
    },

    last () {
      return this.children().slice(-1).pop()
    },

    remove (fromChild = false) {
      if (this.isGridBuilder && this.parent().$el.classList.contains('vue-grid-item')) {
        return this.parent().$parent.$parent.transaction((children) => {
          children.splice(this.idx(), 1)
          return children
        }).then(() => {
          this.parent().$parent.$parent.removeItem(this.idx(), 1)
        })
      }

      return this.parent().transaction((children) => {
        children.splice(this.idx(), 1)
        return children
      }).then(() => {
        this.ignore()
        this.deselect()
        if (this.layoutPath.length > 3 && fromChild) {
          this.refreshLayout(this.layoutPath.slice(0, -2))
        } else {
          this.refreshLayout()
        }
        if (this.isGridBuilder && this.parent().name === 'GridItemWrapper') {
          this.parent().remove()
        }
      })
    },

    removeAndValidate (opts = {}) {
      if (this.canBeDeleted()) {
        return this.remove()
      }

      Notification.warning({
        duration: 6000,
        message: this.cantDeleteMsg(),
        position: 'bottom-right',
        ...opts
      })
    },

    duplicate () {
      const newNode = assignIdsRecursive(_.omit(this.myPath, ['.key', '.path']))
      return this.addElementAbove(newNode)
    },
    wrapInGridItemWrapper (vnode, options) {
      let gridItem = new GridItemWrapper(options)
      gridItem.children.push(vnode)
      return gridItem
    },
    calculatedDuplicateElementPos (x, y) {
      let lastRow = this.$parent.$parent.getGrid().getRow()
      return [0, 0]
    },
    duplicateAndValidate (opts = {}) {
      if (this.canBeDuplicated()) {
        this.refreshLayout()

        if (this.parent().$parent.$parent.name === 'GridBoxContainer') {
          const nameToElement = {
            'Draft': 'text_element',
            'CButton': 'button_element',
            'CImage': 'image_element',
            'Divider': 'divider_element',
            'CForm': 'form_element',
            'Icon': 'icon_element',
            'CVideo': 'video_element',
            'BrandLogo': 'logo_element',
            'CountdownTimer': 'countdown_timer_element',
            'Checkout': 'checkout_element',
          }
          this.parent().$parent.$parent.addItem(nameToElement[this.name])
          return
        }
        if (this.parent().name === 'GridItemWrapper') {
          let dup = assignIdsRecursive(_.omit(this.myPath, ['.key', '.path']))
          // let newCondinate = this.calculatedDuplicateElementPos(this.parent().dataX, this.parent().dataY)
          const parentOptionsClone = {
            desktop: Object.assign({}, this.parent().desktop),
            phone: Object.assign({}, this.parent().phone)
          }
          parentOptionsClone.desktop.dataY += 5
          parentOptionsClone.phone.dataY += 5

          const gridComponent = this.parent().parent()
          if (gridComponent.addGridItem) {
            gridComponent.addGridItem(dup, parentOptionsClone)
          }
          return
        }
        return this.duplicate()
      }
      Notification.warning({
        duration: 6000,
        message: this.cantBeDuplicatedMsg(),
        position: 'bottom-right',
        ...opts
      })
    },

    canBeDuplicated () {
      return true
    },

    cantBeDuplicatedMsg () {
      return 'This element cannot be duplicated'
    },

    addElement (vNode, method = 'push') {
      if (!vNode) throw new Error('no vNode provided')
      return this[method](vNode)
    },

    addElementAbove (vNode) {
      return this.addElement(vNode, 'before')
    },

    addElementBelow (vNode) {
      return this.addElement(vNode, 'after')
    },

    addElementToLeftInNewColumn (vNode) {
      return this.myColumn().addElementToLeft(vNode)
    },

    addElementToRightInNewColumn (vNode) {
      return this.myColumn().addElementToRight(vNode)
    },

    myColumn () {
      return this.findParent(p => p.name === 'Column')
    },

    updateProp (key, value) {
      return this.self().child(`data/props/${key}`).set(value)
    },

    applyColor (colorId) {
      return this.updateProp('themeColorId', colorId)
    },

    canBeDeleted () {
      return true
    },

    cantDeleteMsg () {
      return 'This element could not be deleted'
    },

    parents (list = []) {
      if (list.length === 0) {
        list.unshift(this)
      }

      let parent = Object.prototype.hasOwnProperty.call(this, 'parent') && this.parent()

      if (parent.$el.classList.contains('vue-grid-item', 'vue-grid-layout')) {
        parent = parent.$parent.$parent
      }

      if (_.isFunction(parent.parents) && parent.name !== 'PageEngine') {
        list.unshift(parent)
        return parent.parents(list, parent)
      }
      return list
    },

    internalThemeColors () {
      let colorIds = _.chain(this.myPath.children)
          .findAllRecurs(n => n.data.props.themeColorId)
          .map(n => n.data.props.themeColorId)
          .value()

      colorIds.unshift(this.themeColorId)
      return _.chain(this.themeColors).pick(colorIds).mapValues(c => ({
        a: c.alpha(),
        hex: c.hex().toLowerCase()
      })).value()
    },

    findParent (predicate) {
      let parent = Object.prototype.hasOwnProperty.call(this, 'parent') && this.parent()
      if (!parent || !Object.prototype.hasOwnProperty.call(parent, 'findParent')) return
      return predicate(parent) ? parent : parent.findParent(predicate)
    },

    findChild (predicate) {
      return this.children().filter(predicate).pop()
    },

    findAllDescendants () {
      return findAllDescendants(this)
    },

    dragstart () {
      let data = this.myPath
      if (this.isGridBuilder && (this.parent().name === 'GridItemWrapper' || this.parent().$el.classList.contains('vue-grid-item'))) {
        return false
      }

      const node = function () {
        return data
      }

      setTimeout(_ => {
        this.activateDropzones({ node })
            .then(() => {
              this.$nextTick(_ => this.remove())
            })
      }, 0)
    },

    dragend () {
      setTimeout(_ => this.disableDropzones(), 0)
    },

    addElementToSide ({ side, data }) {
      switch (side) {
        case 'top':
          return this.addElementAbove(new data.node()).then(this.dropzoneDropped)
        case 'bottom':
          return this.addElementBelow(new data.node()).then(this.dropzoneDropped)
        case 'left':
          return this.addElementToLeftInNewColumn(new data.node()).then(this.dropzoneDropped)
        case 'right':
          return this.addElementToRightInNewColumn(new data.node()).then(this.dropzoneDropped)
        case 'all':
          return this.push(new data.node()).then(this.dropzoneDropped)
      }
    },

    resize (fitContent=false) {
      if (this.isGridBuilder && this.parent().name !== 'GridItemWrapper') {
        return
      }

      const gridItem = this.parent()
      const gridContainer = this.parent().parent()

      this.$nextTick(() => {
        if (gridContainer.getGrid && typeof gridContainer.getGrid === 'function') {
          let ofHeight = Math.max(this.$el.offsetHeight, ...Array(...this.$el.children).map(a => a.offsetHeight))
          if (fitContent) {
            ofHeight += + (gridContainer.getGrid().opts.cellHeight)
          }
          const height = Math.round(
              (ofHeight + (gridContainer.getGrid().opts.verticalMargin || 0)) / ((gridContainer.getGrid().getCellHeight() + (gridContainer.getGrid().opts.verticalMargin || 0)) || 1)
          )
          if (!gridContainer.itemResizing) {
            gridContainer.getGrid().update(
                gridItem.$el, {w: gridItem.dataWidth, h: fitContent? height: Math.max(height, gridItem.dataHeight)}
            )
          }
        }
      })
    },
    getAlternatedViewMode() {
      // returns viewMode that is not active
      return this.alternatedViewMode[this.viewMode]
    },
    updateGenerativeContent(content)  {}
  }
}

export default Vue.extend({
  mixins: [FbVNodeMixin]
})
</script>

<style lang="scss">
  @import "@/scss/utils";

  .empty-text {
    color: $empty-text-color;
    text-align: center;
  }

.vnode--targeted {
  position: relative;
  cursor: pointer;

  .oldbuilder &{
    outline: 2px solid $node-target;
  }

  transition: all 0.15s ease;

    > .vnode__label, .vnode__drag-handle  {
      background-color: $node-target;
    }

    > .vnode__move {
      background-color: $node-target;
    }

    > .vnode__btn__group {
      background-color: $node-target;
    }

    > .vnode__btn {
      background-color: $node-target;
    }
  }

.vnode--selected {
  position: relative;
  cursor: pointer;

  .oldbuilder & {
    outline: 2px solid $node-target-selected;
  }

  transition: all 0.15s ease;

    > .vnode__label, .vnode__drag-handle  {
      background-color: $node-target-selected;
    }

    > .vnode__move {
      background-color: $node-target-selected;
    }

    > .vnode__btn__group {
      background-color: $node-target-selected;
    }

    > .vnode__btn {
      background-color: $node-target-selected;
    }
  }
</style>
